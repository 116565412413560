import React, { useEffect, useState } from "react";
import {
    Modal,
    Button,
    Row,
    Col,
    Typography,
    Divider,
    Spin,
    message,
} from "antd";
import {
    LockOutlined,
    FileOutlined,
    CheckCircleOutlined,
    ShoppingCartOutlined,
    PlusCircleOutlined,
    CopyOutlined,
} from "@ant-design/icons";
import { APARTMENT_LOW_RISE_PATH } from "constant/appPath";
import { getApartmentLowRiseDetail } from "api/app/project.api";
import { getListAllStatusColor } from "api/app/master.api";

import classNames from "classnames/bind";
import styles from "./ApartmentLowRiseInfoModal.module.scss";
const cx = classNames.bind(styles);

const { Title, Text } = Typography;

const ApartmentLowRiseInfoModal = ({ visible, apartmentId, onOk, onCancel }) => {
    const [project, setProject] = useState({});
    const [apartmentDetail, setApartmentDetail] = useState(null);
    const [statusNames, setStatusNames] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible && apartmentId) {
            setLoading(true);
            getApartmentLowRiseDetail(apartmentId)
                .then((response) => {
                    console.log("check thông tin chi tiết căn hộ: ", response);
                    setProject(response.project);
                    setApartmentDetail(response.apartment_detail);
                })
                .catch((error) => {
                    console.error("Failed to fetch apartment details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [visible, apartmentId]);

    useEffect(() => {
        // Fetch status names from the API
        getListAllStatusColor()
            .then((response) => {
                const statusData = {};
                response.forEach((status) => {
                    statusData[status.id] = status.name; // Assuming response is an array of {id, name} objects
                });
                setStatusNames(statusData);
            })
            .catch((error) => {
                console.error("Failed to fetch status names:", error);
            });
    }, []);

    if (!apartmentDetail) {
        return null;
    }

    const openApartmentDetail = () => {
        const urlDetail = `${APARTMENT_LOW_RISE_PATH}/${apartmentDetail.id}`;
        window.open(urlDetail, "_blank");
    };

    const copyLinkToClipboard = () => {
        navigator.clipboard
            .writeText(apartmentDetail.sales_policy_applied_monthly_url)
            .then(() => {
                message.success("Link copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy link.");
            });
    };

    return (
        <Modal
            title=""
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
            width={400}
            centered={false}
            mask={false}
            footer={null}
            closable={false}
        >
            <Spin spinning={loading}>
                <div className={cx("modal-header")}>
                    {project.project_name} - {apartmentDetail.code}
                </div>
                <div className={cx("modal-header-price")}>
                    <div className={cx("price-title")}>
                        <ShoppingCartOutlined /> {apartmentDetail.price_view}đ
                    </div>
                    <div className={cx("sub-text-1")}>Giá đã bao gồm VAT & KPBT</div>
                    <div className={cx("sub-text-2")}>
                        Chính sách bán hàng áp dụng:{" "}
                        {apartmentDetail.sales_policy_applied_monthly}
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <Row gutter={[16, 16]} justify="center" className={cx("price-other")}>
                        <Col span={6}>
                            <div className={cx("detail-text")}>Giá TTS</div>
                            <div level={4} className={cx("detail-title")}>
                                {apartmentDetail.price_tts_view}
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={cx("detail-text")}>Giá TTĐD</div>
                            <div level={4} className={cx("detail-title")}>
                                {apartmentDetail.price_tttd_view}
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={cx("detail-text")}>Vay 80%</div>
                            <div level={4} className={cx("detail-title")}>
                                {apartmentDetail.price_loan_80_view}
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={cx("detail-text")}>Vay 50%</div>
                            <div level={4} className={cx("detail-title")}>
                                {apartmentDetail.price_loan_50_view}
                            </div>
                        </Col>
                    </Row>
                    <Row
                        gutter={[16, 16]}
                        justify="center"
                        className={cx("csbh-content")}
                    >
                        <Col span={24} style={{ textAlign: "center" }}>
                            {apartmentDetail.sales_policy_applied_monthly_url && (
                                <div className={cx("csbh-wrapper")}>
                                    <Text className={cx("sub-text")}>
                                        CSBH áp dụng {apartmentDetail.sales_policy_applied_monthly}
                                    </Text>
                                    <Button
                                        icon={<CopyOutlined />}
                                        className={cx("copy-button")}
                                        onClick={copyLinkToClipboard}
                                        type="link"
                                    />
                                </div>
                            )}
                            <Title level={5} className={cx("ptg-chuan")}>
                                PTG CHUẨN
                            </Title>
                            <Text className={cx("warning-text")}>
                                Giá & Phiếu tính giá có thể bị lệch so với thực tế. Vui lòng
                                check lại admin khi tư vấn.
                            </Text>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col
                            span={24}
                            style={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                            <Button
                                icon={<FileOutlined />}
                                className={cx("custom-button")}
                                style={{ marginRight: "10px" }}
                                onClick={openApartmentDetail}
                            >
                                Xem chi tiết
                            </Button>
                            <Button
                                icon={<LockOutlined />}
                                className={cx("custom-button")}
                                onClick={() =>
                                    window.open(apartmentDetail.lock_apartment, "_blank")
                                }
                                disabled={!apartmentDetail.lock_apartment}
                            >
                                Lock căn
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row gutter={[16, 16]} className={cx("apartment-info")}>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Loại căn
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            {apartmentDetail.type === 'biet_thu' && 'Biệt thự'}
                            {apartmentDetail.type === 'lien_ke' && 'Liền kề'}
                            {apartmentDetail.type === 'shophouse' && 'Shop house'}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Hướng
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            {apartmentDetail.direction}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Diện tích đất
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            {apartmentDetail.land_area} m2
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className={cx("apartment-info")}>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Tình trạng
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            {statusNames[apartmentDetail.status]}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Loại hình
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            Mới CĐT
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={cx("apartment-info-text")}>
                            <PlusCircleOutlined /> Giá/m2
                        </div>
                        <div level={5} className={cx("apartment-info-value")}>
                            {apartmentDetail.price_per_sqm_view}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row justify="center">
                    <Col span={24} style={{ textAlign: "center" }}>
                        <Text className={cx("sub-text")}>
                            <CheckCircleOutlined /> Giá, chính sách chỉ mang tính chất tham
                            khảo
                        </Text>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};

export default ApartmentLowRiseInfoModal;
