import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
import { Row, Col, Select, Form } from "antd";
import { getListAllBuilding, getHomeBuilding } from "api/app/project.api"; // No need to import getArticles here
import ProjectTable from "components/ProjectTable";
import { PROJECT_ROW_CT_PATH, PROJECT_ROW_TT_PATH } from "constant/appPath";
import ArticleCarousel from "components/ArticleCarousel"; // Import the new component

const cx = classNames.bind(styles);
const { Option } = Select;

function Home() {
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuildings = async () => {
      setBuildings([]); // Clear buildings state
      setSelectedBuilding(null); // Reset selected building
      try {
        const [buildingList, homeBuilding] = await Promise.all([
          getListAllBuilding(),
          getHomeBuilding(),
        ]);
        if (Array.isArray(buildingList)) {
          setBuildings(buildingList);
          if (homeBuilding && homeBuilding.length > 0) {
            setSelectedBuilding(homeBuilding[0].id); // Assuming getHomeBuilding returns an array of buildings
          }
        } else {
          console.error("Unexpected response for buildings:", buildingList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBuildings();
  }, []);

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    navigate(PROJECT_ROW_CT_PATH, {
      state: {
        building: value,
      },
    });
  };

  return (
    <>
      <div className={cx("home-container")}>
        <Row>
          <Col span={16} className={cx("home-container-title")}>
            Bảng Hàng dự án
          </Col>
          <Col span={8} className={cx("home-container-pulldown")}>
            <Form.Item label="Chọn tòa nhà">
              <Select
                value={selectedBuilding} // Set the value prop to the current state
                placeholder="Chọn tòa nhà"
                onChange={handleBuildingChange}
                style={{ width: 300, textAlign: "left" }}
              >
                {buildings.map((building) => (
                  <Option key={building.id} value={building.id}>
                    {building.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col style={{ width: "100%" }}>
            {selectedBuilding ? (
              <ProjectTable building={selectedBuilding} />
            ) : (
              <div>Không tồn tại dự án!</div>
            )}
          </Col>
        </Row>
      </div>
      <div className={cx("home-container")}>
        <Row>
          <Col span={24}>
            <ArticleCarousel />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
