import axiosAdmin from "api/config/axiosAdmin";

// Flow register account
const register = (
    first_name,
    last_name,
    phone_number,
    email,
    password,
    password_confirmation,
    role
) => {
    const url = "auth/register";
    return axiosAdmin.post(url, {
        first_name,
        last_name,
        phone_number,
        email,
        password,
        password_confirmation,
        role,
    });
};

// Flow login
const login = (email, password) => {
    const url = "auth/login";
    return axiosAdmin.post(url, { email, password });
};

const profile = () => {
    const url = "auth/profile";
    return axiosAdmin.get(url);
};

// Flow change password

export { register, login, profile };
