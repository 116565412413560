import axiosAdmin from "api/config/axiosAdmin";

// 1.1: list
const getListAllSalesPolicy = (perPage, page, classification) => {
    const url = "admin/sales-policy";
    return axiosAdmin.get(url, {
        params: {
            perPage,
            page,
            classification,
        },
    });
};

// 1.2: Add new
const addNewSalesPolicy = (newPolicy) => {
    const url = "admin/sales-policy";
    return axiosAdmin.post(url, {
        sales_policy_name: newPolicy.sales_policy_name,
        classification: newPolicy.classification,
        document_link: newPolicy.document_link,
        description: newPolicy.description
    });
};

// 1.3: Delete
const deleteSalesPolicy = (id) => {
    const url = `admin/sales-policy/${id}`;
    return axiosAdmin.delete(url);
};

// 1.4: get Detail status color
const getSalesPolicyById = (id) => {
    const url = `admin/sales-policy/${id}`;
    return axiosAdmin.get(url);
};

// 1.5: Update
const updateSalesPolicyById = (id, updatedPolicy) => {
    const url = `admin/sales-policy/${id}`;
    return axiosAdmin.put(url, {
        sales_policy_name: updatedPolicy.sales_policy_name,
        classification: updatedPolicy.classification,
        document_link: updatedPolicy.document_link,
        description: updatedPolicy.description
    });
};

export {
    getListAllSalesPolicy,
    addNewSalesPolicy,
    deleteSalesPolicy,
    getSalesPolicyById,
    updateSalesPolicyById
};