// authentication router
const AUTH_PATH = "/auth";
const SIGN_IN_PATH = "login";
const SIGN_UP_PATH = "register";

const PAGE_404_PATH = "/error";

const HOMEPAGE = "/";
const TOOL_PATH = "/tra-cuu-can-ho";
const PROJECT_ROW_CT_PATH = "/bang-hang-du-an-cao-tang";
const PROJECT_ROW_TT_PATH = "/bang-hang-du-an-thap-tang";
const PROJECT_NEW_PATH = "/thong-tin-du-an";
const NEW_PATH = "/tin-tuc";
const NEW_CATEGORY_PATH = "/tin-tuc/:categorySlug";
const NEW_POST_PATH = "/new/:postSlug";
const SEARCH_PATH = "/search";

const APARTMENT_PATH = "/apartment";
const APARTMENT_DETAIL_PATH = "/apartment/:apartmentId";

const APARTMENT_LOW_RISE_PATH = "/apartment-low-rise";
const APARTMENT_LOW_RISE_DETAIL_PATH = "/apartment-low-rise/:apartmentId";

// path đăng ký căn hộ cho thuê
const RENT_POST_PATH = "/cho-thue";
const RENT_POST_ADD_PATH = "/cho-thue/dang-ky";

export {
  AUTH_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  HOMEPAGE,
  PAGE_404_PATH,
  TOOL_PATH,
  PROJECT_ROW_CT_PATH,
  PROJECT_ROW_TT_PATH,
  PROJECT_NEW_PATH,
  NEW_PATH,
  NEW_POST_PATH,
  NEW_CATEGORY_PATH,
  SEARCH_PATH,
  APARTMENT_DETAIL_PATH,
  APARTMENT_PATH,
  APARTMENT_LOW_RISE_PATH,
  APARTMENT_LOW_RISE_DETAIL_PATH,
  RENT_POST_ADD_PATH,
  RENT_POST_PATH,
};
