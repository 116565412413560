// Config Tùy chọn che
export const CODE_MASKING_OPTION = [
    {
        value: 1,
        label: 'Không che'
    },
    {
        value: 2,
        label: 'Che tầng'
    },
    {
        value: 3,
        label: 'Che trục căn'
    },
    {
        value: 4,
        label: 'Che cả 2'
    },
];

// config mục "Phí dịch vụ"
export const SERVICE_FEE = [
    {
        value: 1,
        label: 'Không bao phí'
    },
    {
        value: 2,
        label: 'Bao phí dịch vụ'
    },
];

// config mục "Nội thất"
export const FURNITURE_OPTIONS = [
    {
        value: 1,
        label: 'Full đồ'
    },
    {
        value: 2,
        label: 'Cơ bản'
    },
    {
        value: 3,
        label: 'Nguyên bản'
    },
    {
        value: 4,
        label: 'Không đồ'
    },
];

// config mục "Đồ cơ bản"
export const BASIC_CONDITION = [
    {
        value: 1,
        label: 'Cố định'
    },
    {
        value: 2,
        label: 'Thương lượng'
    },
];


// config mục hợp đồng
export const CONTRACT_DURATION = [
    {
        value: '1_month',
        label: '1 tháng'
    },
    {
        value: '3_months',
        label: '3 tháng'
    },
    {
        value: '6_months',
        label: '6 tháng'
    },
    {
        value: '1_year',
        label: '1 năm'
    },
    {
        value: '2_years',
        label: '2 năm'
    },
];