import axiosAdmin from "api/config/axiosAdmin";

const getListAllSettings = (perPage, page) => {
    const url = "admin/general-settings";
    return axiosAdmin.get(url, {
        perPage,
        page,
    });
};

const addNewSetting = (key, value, description) => {
    const url = "admin/general-settings";
    return axiosAdmin.post(url, {
        key, value, description
    });
};


const deleteSetting = (id) => {
    const url = `/admin/general-settings/${id}`;
    return axiosAdmin.delete(url);
};

const getSettingById = (id) => {
    const url = `/admin/general-settings/${id}`;
    return axiosAdmin.get(url);
};

const updateSettingById = (id, key, value, description) => {
    const url = `/admin/general-settings/${id}`;
    return axiosAdmin.put(url, { key, value, description });
};




export { getListAllSettings, addNewSetting, deleteSetting, getSettingById, updateSettingById }
