// src/common/MyCustomUploadAdapter.js
import axios from 'axios';

class MyCustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then((file) =>
            new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('upload', file);

                const apiUrl = process.env.REACT_APP_API_ENDPOINT;

                axios.post(`${apiUrl}upload-image`, formData)
                    .then((response) => {
                        if (response.data && response.data.url) {
                            resolve({
                                default: response.data.url,
                            });
                        } else {
                            reject(response.data.error || 'Upload failed');
                        }
                    })
                    .catch((error) => {
                        console.error("API error response:", error.response?.data || error.message);
                    });
            })
        );
    }

    abort() {
        // Handle abort if necessary
    }
}

export default MyCustomUploadAdapter;
