import axiosAdmin from "api/config/axiosAdmin";

/**
 * Call API lấy danh sách căn hộ cho thuê
 *
 * @author DungPT
 * @param {object} params
 * @returns
 */
const getRentPosts = (params) => {
  const url = "admin/project/rent-post";

  // Build query params, only include non-null/undefined values
  const queryParams = {
    per_page: params.perPage || null,
    page: params.page || null,
    project_id: params.projectId || null,
    building_id: params.buildingId || null,
    floor_id: params.floorId || null,
    balcony_direction: params.balconyDirection || null,
    price_min: params.priceMin || null,
    price_max: params.priceMax || null,
  };

  return axiosAdmin.get(url, {
    params: queryParams,
  });
};

const approveRentPost = (id) => {
  const url = `admin/project/approve-rent-post/${id}`;
  return axiosAdmin.put(url);
};

export { getRentPosts, approveRentPost };
