import React, { useState, useEffect } from "react";
import {
    Table,
    Button,
    Popconfirm,
    message,
    Layout,
    Row,
    Col,
    Spin,
    Form,
    Input,
    ColorPicker,
} from "antd";
import {
    getListAllStatusColor,
    deleteStatusColor,
    updateStatusColorById
} from "api/admin/master.api";
import ModalAddMStatusColor from "components/Modal/ModalAddMStatusColor";
import moment from "moment";

const { Content } = Layout;

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        if (dataIndex === 'color') {
            childNode = editing ? (
                <Form form={form} style={{ margin: 0 }}>
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: `${title} is required.` }]}
                    >
                        <ColorPicker
                            value={form.getFieldValue(dataIndex)}
                            onChange={(value) => {
                                form.setFieldsValue({ [dataIndex]: value.toHexString() });
                                save();
                            }}
                            onBlur={save}
                            showText
                        />
                    </Form.Item>
                </Form>
            ) : (
                <div onClick={toggleEdit} style={{ paddingRight: 24 }}>
                    <div style={{ backgroundColor: record[dataIndex], padding: '5px', color: '#fff' }}>
                        {children}
                    </div>
                </div>
            );
        } else {
            childNode = editing ? (
                <Form form={form} style={{ margin: 0 }}>
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: `${title} is required.` }]}
                    >
                        <Input onPressEnter={save} onBlur={save} />
                    </Form.Item>
                </Form>
            ) : (
                <div onClick={toggleEdit} style={{ paddingRight: 24 }}>
                    {children}
                </div>
            );
        }
    }

    return <td {...restProps}>{childNode}</td>;
};

const AdminMStatusColor = () => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await getListAllStatusColor();
            setCategories(response);
        } catch (error) {
            console.error("Lỗi khi lấy danh sách:", error);
        }
        setIsLoading(false);
    };

    const handleDelete = async (id) => {
        try {
            await deleteStatusColor(id);
            message.success('Xóa thành công');
            fetchData();
        } catch (error) {
            message.error('Lỗi khi xóa: ' + error.message);
        }
    };

    const handleSave = async (row) => {
        try {
            console.log("Check row update: ", row);
            await updateStatusColorById(row.id, row.name, row.color);
            message.success('Cập nhật thành công');
            fetchData();
        } catch (error) {
            message.error('Lỗi khi cập nhật data: ' + error.message);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            editable: false,
        },
        {
            title: 'Tên trạng thái',
            dataIndex: 'name',
            key: 'name',
            editable: true,
        },
        {
            title: 'Mã màu',
            dataIndex: 'color',
            key: 'color',
            editable: true,
            render: (text) => (
                <div style={{ backgroundColor: text, width: '100%', height: '100%', padding: '5px', color: '#fff' }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Ngày cập nhật',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text) => moment(text).format('DD/MM/YYYY H:s'),
        },
        {
            title: 'Option',
            key: 'option',
            render: (text, record) => (
                <span>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">Xóa</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        };
    });

    return (
        <Layout>
            <Content style={{ padding: "0 50px" }}>
                <Row gutter={16} style={{ marginBottom: 16 }}>
                    <Col span={12}>
                        <h2>Danh sách trạng thái</h2>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                            type="primary"
                            size="large"
                            onClick={showModal}
                            style={{ marginTop: 15 }}
                        >
                            Thêm mới
                        </Button>
                    </Col>
                </Row>

                <Spin spinning={isLoading}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        columns={mergedColumns}
                        dataSource={categories}
                        rowKey="id"
                    />
                </Spin>

                <ModalAddMStatusColor
                    isModalVisible={isModalVisible}
                    handleModalCancel={handleModalCancel}
                    handleAdded={fetchData}
                />
            </Content>
        </Layout>
    );
};

export default AdminMStatusColor;
