import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Row, Col, Select, Form } from 'antd';
import { getListAllBuilding, getHomeBuilding } from 'api/app/project.api';
import ProjectTable from 'components/ProjectTable';
import BuildingInfo from 'components/BuildingInfo';
import ProjectBreadcrumb from 'components/ProjectBreadcrumb';

import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
const cx = classNames.bind(styles);

const { Option } = Select;

const ProjectRow = () => {
    const location = useLocation();
    const { building } = location.state || {};
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(building || null);
    const [selectedBuildingName, setSelectedBuildingName] = useState(building ? building.name : '');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [descriptionBuilding, setDescriptionBuilding] = useState('');

    useEffect(() => {
        const fetchBuildings = async () => {
            setBuildings([]);
            setSelectedBuilding(null);
            setLoading(true);
            setError(false);

            try {
                const response = await getListAllBuilding();
                console.log(" check thông tin chi tiết tòa nhà: ", response);
                if (Array.isArray(response)) {
                    setBuildings(response);
                    if (!building || !response.find(b => b.id === building.id)) {
                        const homeBuilding = await getHomeBuilding();
                        if (homeBuilding && homeBuilding.length > 0) {
                            setSelectedBuilding(homeBuilding[0].id);
                            setSelectedBuildingName(homeBuilding[0].name);
                            setDescriptionBuilding(homeBuilding[0].description)
                        } else {
                            setError(true);
                        }
                    } else {
                        setSelectedBuilding(building.id);
                        setSelectedBuildingName(building.name);
                        setDescriptionBuilding(building.description)
                    }
                } else {
                    console.error('Unexpected response for buildings:', response);
                    setError(true);
                }
            } catch (error) {
                console.error('Error fetching buildings:', error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchBuildings();
    }, [building]);

    const handleBuildingChange = (value, option) => {
        setSelectedBuilding(value);
        setSelectedBuildingName(option.children);
        setDescriptionBuilding(option.descriptionBuilding);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Không tồn tại dự án!</div>;
    }

    return (
        <>
            <div className={cx("home-container")}>
                <ProjectBreadcrumb
                    building_id={selectedBuilding}
                    building_name={selectedBuildingName}
                />
                <Row style={{ marginBottom: '20px' }}>
                    <BuildingInfo building_id={selectedBuilding} />
                </Row>
                <Row >
                    <Col span={16} className={cx("home-container-title")}>Bảng Hàng dự án</Col>
                    <Col span={8} className={cx("home-container-pulldown")}>
                        <Form.Item label="Chọn tòa nhà">
                            <Select
                                value={selectedBuilding}
                                placeholder="Chọn tòa nhà"
                                onChange={handleBuildingChange}
                                style={{ width: 200, textAlign: "left" }}
                            >
                                {buildings.map(building => (
                                    <Option key={building.id} value={building.id} descriptionBuilding={building.description}>
                                        {building.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Col style={{ width: '100%' }}>
                        {selectedBuilding ? (
                            <ProjectTable building={selectedBuilding} />
                        ) : (
                            <div>Không tồn tại dự án!</div>
                        )}
                    </Col>
                </Row>
                {descriptionBuilding !== '' && (
                    <Row style={{ width: '100%', marginTop: '20px' }}>
                        <Col style={{ width: '100%' }}>
                            <div className={cx("home-container-title")}>
                                Thông tin chi tiết tòa nhà:
                            </div>
                            <div className={cx("home-container-content")} dangerouslySetInnerHTML={{ __html: descriptionBuilding }} />
                        </Col>
                    </Row>
                )}
            </div >
        </>
    );
}

export default ProjectRow;
