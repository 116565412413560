// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBj96aI0ljJ6OI4E7NhKADtGUngFYKhEHY",
    authDomain: "web-login-fbdcc.firebaseapp.com",
    projectId: "web-login-fbdcc",
    storageBucket: "web-login-fbdcc.appspot.com",
    messagingSenderId: "175972051317",
    appId: "1:175972051317:web:543fd8e752060f7445cb3c",
    measurementId: "G-XSD4HPW7YQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);