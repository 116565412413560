import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  message,
  Spin,
  Tooltip,
  Input,
  Select,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  addNewProject,
  getListAllProject,
  deleteProject,
  updateProjectById,
} from "api/admin/project.api";
import { PAGE_NUMBER, PAGE_SIZE_PROJECT } from "constant/common";
import moment from "moment";
import ModalAddProject from "components/Modal/ModalAddProject";
import ModalSubdivision from "components/Modal/ModalSubdivision";

const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

const AdminProject = () => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [perPage, setPerPage] = useState(PAGE_SIZE_PROJECT);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [subdivisionVisible, setSubdivisionVisible] = useState(false);
  const [selectedSubdivisionProject, setSelectedSubdivisionProject] =
    useState(null);

  const [searchName, setSearchName] = useState("");
  const [searchType, setSearchType] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getListAllProject(
        perPage,
        page,
        searchName,
        searchType
      );
      console.log("Data dự án: ", response);
      setListData(response.data);
      setTotalData(response.total);
    } catch (error) {
      message.error("Failed to fetch projects: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, searchName, searchType]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDelete = (id) => {
    confirm({
      title: "Confirm deletion",
      content: "Are you sure you want to delete?",
      onOk: async () => {
        try {
          await deleteProject(id);
          message.success("Thực hiện thành công");
          setListData(listData.filter((item) => item.id !== id));
        } catch (error) {
          message.error("Thực hiện xóa thất bại và có lỗi: " + error.message);
        }
      },
      onCancel() {
        setItemToDelete(null);
      },
    });
  };

  const handleEdit = (project) => {
    setSelectedProject(project);
    setVisible(true);
  };

  const handleCreate = async (values) => {
    try {
      if (values.id) {
        const resUpdate = await updateProjectById(values.id, values);
        console.log("Updated project thành công: ", resUpdate);
        message.success("Chỉnh sửa dự án thành công");
      } else {
        await addNewProject(values);
        message.success("Thêm dự án thành công");
      }
      fetchData(); // Reload the project list after creation or update
      setVisible(false);
    } catch (error) {
      message.error("Thêm dự án thất bại: " + error.message);
      console.error("Thêm dự án thất bại: ", error.message);
    }
  };

  const handleSubdivision = (project) => {
    setSelectedSubdivisionProject(project);
    setSubdivisionVisible(true);
  };

  const columns = [
    {
      title: "Mã dự án",
      dataIndex: "project_code",
      key: "project_code",
    },
    {
      title: "Tên dự án",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Loại hình",
      dataIndex: "project_type",
      key: "project_type",
    },
    {
      title: "Địa điểm",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Danh sách phân khu",
      key: "subdivisions",
      render: (text, record) => (
        <div>
          <div>
            {record.subdivisions && record.subdivisions.length > 0 ? (
              record.subdivisions.map((subdivision, index) => (
                <div key={index}>- {subdivision.name}</div>
              ))
            ) : (
              <div>Không có phân khu</div>
            )}
          </div>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleSubdivision(record)}
          />
        </div>
      ),
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Option",
      key: "option",
      render: (text, record) => (
        <>
          <Tooltip title="Delete">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record.id)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="primary"
              icon={<EditOutlined />}
              style={{ marginLeft: 8 }}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <h2>Danh sách dự án</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              size="large"
              onClick={() => setVisible(true)}
              style={{ marginTop: 15 }}
            >
              Tạo mới dự án
            </Button>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: "10px" }}>
          <Col span={8}>
            <Input
              placeholder="Tìm kiếm theo tên dự án"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              value={searchType}
              onChange={(value) => setSearchType(value)}
            >
              <Option value="">Tìm kiếm theo Loại hình</Option>
              <Option value="thap_tang">Thấp tầng</Option>
              <Option value="cao_tang">Cao tầng</Option>
            </Select>
          </Col>
          <Col span={6} style={{ textAlign: "left" }}>
            <Button
              icon={<ReloadOutlined />}
              onClick={fetchData} // Reload the data on click
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={listData}
            pagination={false}
            rowKey="id"
          />
          <Pagination
            current={page}
            pageSize={perPage}
            total={totalData}
            onChange={handlePageChange}
            style={{ marginTop: 16, textAlign: "center" }}
          />
        </Spin>
        <ModalAddProject
          visible={visible}
          onCreate={handleCreate}
          onCancel={() => {
            setVisible(false);
            setSelectedProject(null);
          }}
          project={selectedProject}
        />
        <ModalSubdivision
          visible={subdivisionVisible}
          onCancel={() => {
            setSubdivisionVisible(false);
            fetchData(); // Reload the project list after closing the subdivision modal
          }}
          project={selectedSubdivisionProject}
        />
      </Content>
    </Layout>
  );
};

export default AdminProject;
