import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Typography, Spin } from "antd"; // Import Spin component
import { getPostDetail } from "api/app/category.api";
import moment from "moment";
import classNames from "classnames/bind";
import styles from "page/Application/NewPost/NewPost.module.scss";
const cx = classNames.bind(styles);

const { Title, Text } = Typography;
const { Meta } = Card;

const NewPost = () => {
    const { categorySlug, postSlug } = useParams();
    const [categoryName, setCategoryName] = useState("");
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading status

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await getPostDetail(postSlug);
                console.log("check list data: ", response);
                setCategoryName(response.category_name);
                setPosts(response);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                setLoading(false); // Set loading to false when data fetching is complete
            }
        };

        fetchPosts();
    }, [categorySlug, postSlug]);

    return (
        <div>

            {loading ? ( // Show spinner if loading is true
                <Spin size="large" />
            ) : (
                <>
                    <Card hoverable cover={<img alt="example" src={posts.image_url} />}>
                        <Meta title={posts.title} />
                        <Text style={{ marginTop: "20px" }} type="secondary">
                            Cập nhật: {moment(posts.created_at).format("DD [Tháng] MM, YYYY")}
                        </Text>
                        <div className={cx("post-content")} dangerouslySetInnerHTML={{ __html: posts.content }} />
                    </Card>
                </>
            )}
        </div>
    );
};

export default NewPost;
