import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { List, Card, Typography, Spin } from "antd"; // Import Spin component
import { getPostsByCategoryId } from "api/app/category.api";
import { FolderOpenOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;
const { Meta } = Card;

const NewCategory = () => {
  const { categorySlug } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading status

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getPostsByCategoryId(categorySlug);
        console.log("check list data: ", response);
        setCategoryName(response.category_name);
        setPosts(response.list_post);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };

    fetchPosts();
  }, [categorySlug]);

  return (
    <div>

      {loading ? ( // Show spinner if loading is true
        <Spin size="large" />
      ) : (
        <>
          <Title level={3} style={{ color: '#27292B' }}><FolderOpenOutlined /> Chuyên mục: {categoryName}</Title>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={posts}
            renderItem={(item) => (
              <List.Item>
                <Link to={`/new/${item.id}`}>
                  <Card hoverable cover={<img alt="example" src={item.image_url} />}>
                    <Meta title={item.title} style={{ marginBottom: "10px" }} />
                    <Text style={{ marginTop: "20px" }} type="secondary">
                      Cập nhật: {moment(item.created_at).format("DD [Tháng] MM, YYYY")}
                    </Text>
                    <Link to={`/new/${item.id}`}> Đọc thêm</Link>
                  </Card>
                </Link>
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
};

export default NewCategory;
