import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { menuItems } from "constant/menuAdmin";
import { useNavigate } from "react-router-dom";
import logo from "assets/logo/Logo-MDLand-trang.png";
import { ADMIN_PATH } from "constant/authPath";

const { Sider } = Layout;

const LeftSidebarMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const activeMenuKey = localStorage.getItem("activeMenuKey");
    if (activeMenuKey) {
      setSelectedKey(activeMenuKey);
    }
  }, []);

  const findMenuItem = (key, items) => {
    for (const item of items) {
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        const found = findMenuItem(key, item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const onClick = (e) => {
    const item = findMenuItem(e.key, menuItems);
    if (item && item.path) {
      navigate(item.path);
      localStorage.setItem("activeMenuKey", e.key);
      setSelectedKey(e.key);
    }
  };

  const logoOnclick = () => {
    navigate(ADMIN_PATH);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div
        style={{ padding: "16px", textAlign: "center", background: "#001529" }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "100px", maxHeight: "100px", width: "100%" }}
          onClick={logoOnclick}
        />
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={[selectedKey]}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={menuItems}
        onClick={onClick}
      />
    </Sider>
  );
};

export default LeftSidebarMenu;
