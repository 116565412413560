import React, { useState } from "react";
import { Modal, Form, Input, message, Select } from "antd";
import { addNewCategory } from "api/admin/post.api";

const { Option } = Select;

const ModalAddCategory = ({ isModalVisible, handleModalCancel, handleCategoryAdded }) => {
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryDescription, setNewCategoryDescription] = useState("");
    const [newCategoryType, setNewCategoryType] = useState(1);

    const handleModalOk = async () => {
        try {
            const response = await addNewCategory(newCategoryName, newCategoryDescription, 1, newCategoryType);
            const newCategory = response.category; // Giả sử API trả về đối tượng danh mục mới

            setNewCategoryName("");
            setNewCategoryDescription("");
            setNewCategoryType(1);
            message.success('Thêm danh mục mới thành công');
            handleCategoryAdded(newCategory); // Truyền đối tượng danh mục mới tới component cha
        } catch (error) {
            message.error('Lỗi khi thêm danh mục mới: ' + error.message);
        }
    };

    return (
        <Modal
            title="Thêm mới danh mục"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
        >
            <Form layout="vertical">
                <Form.Item label="Tên danh mục" required>
                    <Input value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} />
                </Form.Item>
                <Form.Item label="Mô tả" required>
                    <Input.TextArea value={newCategoryDescription} onChange={(e) => setNewCategoryDescription(e.target.value)} />
                </Form.Item>
                <Form.Item label="Loại danh mục" required>
                    <Select value={newCategoryType} onChange={(value) => setNewCategoryType(value)}>
                        <Option value={1}>Tin tức</Option>
                        <Option value={2}>Rao vặt</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddCategory;
