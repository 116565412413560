import axiosClient from "api/config/axiosClient";

const uploadFileImage = (file) => {
    const formData = new FormData();
    formData.append("upload", file);
    const url = "upload-image";
    return axiosClient.post(url, formData);
};

const deleteFileImage = (path) => {
    console.log("check path: ", path);
    const url = "upload-image";
    return axiosClient.delete(url, { file_path: path });
}

export { uploadFileImage, deleteFileImage };