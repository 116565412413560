import React from "react";
import { Modal, Carousel, Divider, Row, Col, Button, Avatar } from "antd";
import { PhoneOutlined, CloseSquareOutlined, } from "@ant-design/icons";
import ZaloIcon from "assets/logo/icons8-zalo-64.png";
import { BASIC_CONDITION, FURNITURE_OPTIONS } from "constant/rentPost";

import classNames from "classnames/bind";
import styles from "./RentPostDetailsModal.module.scss";
const cx = classNames.bind(styles);

// Hàm tìm label từ value
const getLabel = (value, arrayData) => {
    const foundOption = arrayData.find((opt) => {
        return String(opt.value) === String(value);
    });
    if (foundOption) {
        return foundOption.label;
    }
    return "Không xác định";
};

const RentPostDetailsModal = ({ visible, onClose, rentPost }) => {

    const openZaloChat = (phoneNumber) => {
        window.open(`https://zalo.me/${phoneNumber}`, "_blank");
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={600}
            closable={false}
            bodyStyle={{ paddingBottom: "50px" }} // Đảm bảo có không gian cho button close
        >
            {rentPost && (
                <div style={{ margin: '10px', position: 'relative' }}>
                    <Row gutter={16}>
                        <Col span={16} style={{ color: 'rgb(159,34,58)', fontSize: '14pt', lineHeight: '1.53' }}>{rentPost?.code}</Col>
                        <Col span={8} style={{ textAlign: 'right' }}>8tr/1 tháng</Col>
                    </Row>
                    <Row style={{ backgroundColor: 'rgba(235, 235, 235, 1)', borderRadius: '10px' }}>
                        <Col>
                            <Row gutter={[16, 16]} justify="center" className={cx("price-other")}>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Nội thất</div>
                                    <div level={4} className={cx("detail-title")}>
                                        {getLabel(rentPost.furniture, FURNITURE_OPTIONS)}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Ban công</div>
                                    <div level={4} className={cx("detail-title")}>
                                        {rentPost.direction}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Thanh toán</div>
                                    <div level={4} className={cx("detail-title")}>
                                        {rentPost.payment_plan}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Thông thủy</div>
                                    <div level={4} className={cx("detail-title")}>
                                        {rentPost.land_area} m²
                                    </div>
                                </Col>
                            </Row>
                            <Carousel autoplay>
                                {rentPost.rent_image?.map((img, index) => {
                                    const youtubeEmbedUrl = img.is_youtube === 1
                                        ? img.image_path.replace("watch?v=", "embed/")
                                        : img.image_path;

                                    return (
                                        <div key={index}>
                                            {img.is_youtube === 1 ? (
                                                <iframe
                                                    width="100%"
                                                    height="400px"
                                                    src={youtubeEmbedUrl}
                                                    allow="autoplay; encrypted-media"
                                                    allowFullScreen
                                                />
                                            ) : (
                                                <img
                                                    src={img.image_path}
                                                    alt={`Slide ${index + 1}`}
                                                    style={{ width: "100%", height: "400px", objectFit: "cover" }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </Carousel>
                            <Row gutter={[16, 16]} justify="center" className={cx("price-other")}>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Phân khu</div>
                                    <div level={4} className={cx("detail-title")}>

                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Tòa nhà</div>
                                    <div level={4} className={cx("detail-title")}>

                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Khoảng tầng</div>
                                    <div level={4} className={cx("detail-title")}>

                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cx("detail-text")}>Thời gian thuê</div>
                                    <div level={4} className={cx("detail-title")}>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={cx("price-other")}>
                        <Col style={{ textAlign: 'left' }}><span className={cx("detail-text")}>Đồ cơ bản: </span><span className={cx("detail-title")}>{getLabel(rentPost.basic_condition, BASIC_CONDITION)}</span></Col>
                    </Row>

                    <Divider />

                    {/* Row chứa các button */}
                    <Row
                        gutter={5}
                        justify="center"
                        style={{
                            position: 'absolute',
                            top: '100%',
                            bottom: '0px',
                            left: '50%',
                            transform: 'translateX(-50%)', // Căn giữa theo chiều ngang
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Col span={10}>
                            <Button type="default" onClick={() => window.open(`tel:${rentPost.contact_phone}`, '_self')} style={{ width: '100%' }}>
                                <PhoneOutlined /> {rentPost.contact_phone}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type="default" onClick={() => openZaloChat(rentPost.contact_phone)} style={{ width: '100%' }}>
                                <Avatar shape="square" size={24} src={<img src={ZaloIcon} alt="avatar" />} /> {rentPost.contact_name}
                            </Button>
                        </Col>
                        <Col span={2} style={{ textAlign: 'right', width: '100%' }}>
                            <Button type="default" onClick={onClose}>
                                <CloseSquareOutlined />
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </Modal>
    );
};

export default RentPostDetailsModal;
