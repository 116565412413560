import React from 'react';
import { Modal, Button } from 'antd';

const ModalSetHomeFlag = ({ visible, onConfirm, onCancel, building }) => {
    return (
        <Modal
            visible={visible}
            title=''
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Không
                </Button>,
                <Button key="submit" type="primary" onClick={onConfirm}>
                    Có
                </Button>,
            ]}
        >
            <p>Bạn có muốn hiển thị tòa nhà {building?.name} ra ngoài trang chủ không?</p>
        </Modal>
    );
};

export default ModalSetHomeFlag;
