import React, { createContext, useState, useEffect } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { login } from "api/auth/authen.api";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const userData = localStorage.getItem("userData");
      setUser(JSON.parse(userData));
    }
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    setUser(user);
    localStorage.setItem("userToken", user.accessToken);
    localStorage.setItem("userData", JSON.stringify(user));
  };

  const loginWithEmail = async (userData, token) => {
    setUser(userData);
    localStorage.setItem("userToken", token);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("userData");
  };

  return (
    <UserContext.Provider value={{ user, loginWithGoogle, loginWithEmail, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
