import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Tag,
  Button,
  Table,
  message,
  Row,
  Col,
  Divider,
  Carousel,
} from "antd";
import {
  UnorderedListOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { getListRentPost } from "api/app/rentPost.api"; // Ensure this imports correctly
import FilterFormRentPost from "components/FilterForm/FilterFormRentPost";
import { formatDate, formatCurrency } from "utils/utils";
import RentPostDetailsModal from "components/Modal/RentPostDetailsModal";

import classNames from "classnames/bind";
import styles from "page/Application/Tool/Tool.module.scss";

const cx = classNames.bind(styles);

const RentPostList = () => {
  const [rentPosts, setRentPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    // project_id: null,
    // subdivision_id: null,
    room_type: null,
    balcony_direction: null,
    // floorRange: null,
    priceRange: null,
    areaRange: null,
    status: true,
  });
  const [viewMode, setViewMode] = useState("kanban"); // 'table' or 'kanban'
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRentPost, setSelectedRentPost] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false); // Track login status

  // Check login status
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setLoggedIn(true); // User is logged in
    }
  }, []);

  // Fetch data with current filters
  const fetchRentPosts = async () => {
    setLoading(true);
    try {
      const response = await getListRentPost(filters); // Pass the filters object
      console.log("check data list: ", response.data);
      setRentPosts(response.data); // Assuming API response has a data field with the list
    } catch (error) {
      message.error("Failed to load rent posts.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchRentPosts();
  }, [filters]);

  // Handlers for setting different filters
  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  // Toggle between Table and Kanban views
  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "table" ? "kanban" : "table"));
  };

  const showRentPostDetails = (rentPost) => {
    setSelectedRentPost(rentPost);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRentPost(null);
  };

  // Table columns configuration for Ant Design Table
  const columns = [
    {
      title: "Mã căn",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (price) => <Tag color="red">{price}</Tag>,
    },
    {
      title: "Diện tích",
      dataIndex: "land_area",
      key: "land_area",
    },
    {
      title: "Loại phòng",
      dataIndex: "room_type",
      key: "room_type",
    },
    {
      title: "Hướng BC",
      dataIndex: "direction",
      key: "direction",
    },
    {
      title: "Người liên hệ",
      dataIndex: "contact_name",
      key: "contact_name",
    },
    {
      title: "Điện thoại",
      dataIndex: "contact_phone",
      key: "contact_phone",
    },
  ];

  return (
    <div className={cx("home-container")}>
      <Row gutter={16}>
        <Col span={8} className={cx("home-container-title")}>
          <div className={cx("tool-title")}>
            Thuê và cho thuê căn hộ tại xxx
          </div>
          <div className={cx("tool-text-total")}>
            Hiện có <strong>xxx</strong> căn hộ đang cho thuê.
          </div>
        </Col>
        <Col span={16} className={cx("home-container-pulldown")}>
          {loggedIn && (
            <Button
              type="primary"
              style={{ marginRight: "10px" }}
            // onClick={() => navigate("/manage-rent-posts")}
            >
              Quản lý tin cho thuê
            </Button>
          )}
          {loggedIn && (
            <Button type="primary" onClick={() => navigate("dang-ky")}>
              Đăng tin cho thuê
            </Button>
          )}
        </Col>
      </Row>
      <FilterFormRentPost
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16} style={{ marginBottom: "15px" }}>
            <Col span={12}>
              <Button onClick={toggleViewMode}>
                {viewMode === "table" ? (
                  <AppstoreOutlined />
                ) : (
                  <UnorderedListOutlined />
                )}
              </Button>
            </Col>
            {/* <Col span={12} style={{ textAlign: "right" }}>
              Sort
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              {viewMode === "table" ? (
                <Table
                  columns={columns}
                  dataSource={rentPosts}
                  rowKey={(item) => item.title}
                  loading={loading}
                  onRow={(record) => ({
                    onClick: () => showRentPostDetails(record),
                  })}
                />
              ) : (
                <div
                  className="kanban-view"
                  style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
                >
                  {rentPosts.map((item) => (
                    <div
                      key={item.title}
                      style={{ width: "250px", height: "400px" }}
                    >
                      <Card
                        hoverable
                        onClick={() => showRentPostDetails(item)}
                        cover={
                          <Carousel autoplay>
                            {item.rent_image?.map((img, index) => {
                              // Nếu img.is_youtube = 1 thì tạo URL nhúng cho YouTube
                              const youtubeEmbedUrl =
                                img.is_youtube === 1
                                  ? img.image_path.replace("watch?v=", "embed/")
                                  : img.image_path;

                              return (
                                <div key={index}>
                                  {img.is_youtube === 1 ? (
                                    <iframe
                                      width="250"
                                      height="200"
                                      src={youtubeEmbedUrl} // Đường dẫn nhúng YouTube hợp lệ
                                      title={`YouTube video ${index + 1}`}
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      style={{
                                        width: "250px",
                                        height: "200px",
                                      }}
                                    ></iframe>
                                  ) : (
                                    <img
                                      alt={`Slide ${index + 1}`}
                                      src={img.image_path}
                                      style={{
                                        width: "250px",
                                        height: "200px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </Carousel>
                        }
                      // actions={[<HeartOutlined key="like" />]}
                      >
                        <Card.Meta
                          title={item.title}
                          description={
                            <>
                              <Tag color="red">{formatCurrency(item.price)}</Tag>
                              <div>Diện tích: {item.land_area} m²</div>
                              <div>Loại phòng: {item.room_type}</div>
                              <div>Ngày vào ở: {formatDate(item.move_in_date)}</div>
                            </>
                          }
                        />
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col span={8} style={{ paddingLeft: "15px", textAlign: "center" }}>
          Quảng cáo
        </Col> */}
      </Row>
      {selectedRentPost && (
        <RentPostDetailsModal
          visible={isModalVisible}
          onClose={handleModalClose}
          rentPost={selectedRentPost}
        />
      )}
    </div>
  );
};

export default RentPostList;
