import React, { useEffect, useState } from "react";
import { Modal, Table, Spin, message, Form, Input, Button, Popconfirm } from "antd";
import { getSubdivisions, addSubdivision, deleteSubdivision, updateSubdivision } from "api/admin/project.api"; // Ensure these functions are implemented

const ModalSubdivision = ({ visible, onCancel, project }) => {
    const [subdivisions, setSubdivisions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [addForm] = Form.useForm();
    const [editForm] = Form.useForm();

    useEffect(() => {
        if (project) {
            fetchSubdivisions();
        }
    }, [project]);

    const fetchSubdivisions = async () => {
        setLoading(true);
        try {
            const response = await getSubdivisions(project.id);
            console.log("List phân khu: ", response);
            setSubdivisions(response);
        } catch (error) {
            message.error("Failed to fetch subdivisions: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleAddSubdivision = async (values) => {
        setLoading(true);
        try {
            const response = await addSubdivision(values.name, project.id, values.description);
            setSubdivisions([...subdivisions, response]);
            addForm.resetFields();
            message.success("Phân khu mới đã được thêm");
        } catch (error) {
            message.error("Failed to add subdivision: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await deleteSubdivision(id);
            setSubdivisions(subdivisions.filter((item) => item.id !== id));
            message.success("Phân khu đã được xóa");
        } catch (error) {
            message.error("Failed to delete subdivision: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (id, values) => {
        setLoading(true);
        try {
            await updateSubdivision(id, values.name, values.description);
            setSubdivisions(subdivisions.map((item) => (item.id === id ? { ...item, ...values } : item)));
            setEditingKey('');
            message.success("Phân khu " + values.name + " đã được cập nhật");
        } catch (error) {
            message.error("Failed to update subdivision: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        editForm.setFieldsValue({ name: '', description: '', ...record });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id) => {
        try {
            const values = await editForm.validateFields();
            handleSave(id, values);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Tên phân khu',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Form.Item
                        name="name"
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: 'Vui lòng nhập tên phân khu!' }]}
                    >
                        <Input onBlur={() => save(record.id)} />
                    </Form.Item>
                ) : (
                    <div onClick={() => edit(record)}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            editable: true,
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Form.Item
                        name="description"
                        style={{ margin: 0 }}
                    >
                        <Input onBlur={() => save(record.id)} />
                    </Form.Item>
                ) : (
                    <div onClick={() => edit(record)}>
                        {text}
                    </div>
                );
            },
        },
        {
            title: 'Option',
            key: 'option',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <Button>Cancel</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button danger onClick={() => handleDelete(record.id)}>Delete</Button>
                );
            },
        },
    ];

    return (
        <Modal
            visible={visible}
            title={`Danh sách phân khu cho dự án: ${project?.project_name}`}
            onCancel={onCancel}
            footer={null}
            width={800} // Increase the width of the modal
        >
            <Spin spinning={loading}>
                <Form form={addForm} layout="inline" onFinish={handleAddSubdivision} style={{ marginBottom: 16 }}>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Vui lòng nhập tên phân khu!' }]}
                    >
                        <Input placeholder="Tên phân khu" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}
                    >
                        <Input placeholder="Mô tả" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Thêm phân khu
                        </Button>
                    </Form.Item>
                </Form>
                <Form form={editForm} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={subdivisions}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Spin>
        </Modal>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editable ? (
                <Form.Item
                    name={title}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Vui lòng nhập ${title.toLowerCase()}!` }]}
                >
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default ModalSubdivision;
