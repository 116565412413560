import React from "react";
import { Layout, Card, Row, Col, Typography, Progress } from "antd";
import { UserOutlined, FileDoneOutlined, LineChartOutlined, MessageOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;

const Dashboard = () => {
  return (
    <Content style={{ margin: "15px 15px" }}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Title level={3} style={{ color: "#1890ff" }}>278</Title>
            <Text>New Projects</Text>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={3} style={{ color: "#52c41a" }}>156</Title>
            <Text>New Clients</Text>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={3} style={{ color: "#faad14" }}>64.89 %</Title>
            <Text>Conversion Rate</Text>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Title level={3} style={{ color: "#13c2c2" }}>423</Title>
            <Text>Support Tickets</Text>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col span={18}>
          <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Title level={5}>Remaining</Title>
                <Progress percent={50} strokeColor="#faad14" />
              </div>
              <div>
                <Title level={5}>Completed</Title>
                <Progress percent={50} strokeColor="#52c41a" />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
              <div>
                <Title level={5}>Total Projects</Title>
                <Text>18</Text>
              </div>
              <div>
                <Title level={5}>Total Tasks</Title>
                <Text>125</Text>
              </div>
              <div>
                <Title level={5}>Completed Tasks</Title>
                <Text>242</Text>
              </div>
              <div>
                <Title level={5}>Total Revenue</Title>
                <Text>$11,582</Text>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <div style={{ textAlign: "center" }}>
              <Title level={2} style={{ color: "#1890ff" }}>84%</Title>
              <Text>Employees Satisfied</Text>
              <div style={{ marginTop: "16px" }}>
                <LineChartOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
                <Text> Statistics for the last year</Text>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default Dashboard;
