import axiosClient from "api/config/axiosClient";

const getStatusByType = (type) => {
    const url = "app/statuses";
    return axiosClient.get(url, {
        params: {
            type,
        },
    });
};

const getListAllStatusColor = () => {
    const url = "app/status-colors";
    return axiosClient.get(url);
};


export {
    getStatusByType,
    getListAllStatusColor
};