import React, { useContext } from 'react';
import { Form, Input, Button, message } from 'antd';
import { AdminContext } from 'context/AdminContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { login as adminLogin } from "api/auth/authenAdmin.api";
import { ADMIN_PATH, ADMIN_PATH_LOGIN } from 'constant/authPath';

const AdminLogin = () => {
    const { login } = useContext(AdminContext);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        try {
            const response = await adminLogin(values.email, values.password);
            console.log("Check response login admin: ", response);

            if (response.user.role === "admin") {
                login(response.user, response.access_token);
                message.success('Đăng nhập thành công!');
                navigate(ADMIN_PATH);
            } else {
                message.error("Tài khoản của bạn không có quyền admin!")
                navigate(ADMIN_PATH_LOGIN);
            }
        } catch (error) {
            console.log("Check error login admin: ", error);
            // message.error('Đăng nhập thất bại: ' + error.message);
        }
    };

    if (localStorage.getItem("adminToken")) {
        return <Navigate to={ADMIN_PATH} />;
    }

    return (
        <div>
            <h1 style={{ textAlign: "center" }}>Admin Login</h1>
            <Form
                name="admin_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ maxWidth: 300, margin: '0 auto' }}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                        Login with Email
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AdminLogin;