import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Select, Row, Col, Spin } from 'antd';
import classNames from "classnames/bind";
import styles from "components/Modal/ModalSetFloor/ModalSetFloor.module.scss";
import { getListAllFloorByBuildingId } from 'api/admin/building.api';

const cx = classNames.bind(styles);
const { Option } = Select;

const ModalSetFloor = ({ visible, onSetFloor, onCancel, building }) => {
    const [form] = Form.useForm();
    const [floors, setFloors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchFloors = async () => {
            setLoading(true);
            console.log("check data building truyền lên modal: ", building);
            try {
                const response = await getListAllFloorByBuildingId(building.id);
                if (response && response.length > 0) {
                    setFloors(response);
                } else {
                    initializeFloors();
                }
            } catch (error) {
                console.error('Failed to fetch floors:', error);
                initializeFloors();
            } finally {
                setLoading(false);
            }
        };

        const initializeFloors = () => {
            if (building) {
                const initialFloors = Array.from({ length: building.floors }, (_, i) => ({
                    floor: i + 1,
                    floor_type: 1,
                    floor_description: '',
                    apartment_number: 0,
                }));
                setFloors(initialFloors);
            }
        };

        if (visible && building) {
            fetchFloors();
        }
    }, [visible, building]);

    const handleFloorChange = (index, field, value) => {
        const newFloors = [...floors];
        newFloors[index][field] = value;
        setFloors(newFloors);
    };

    const handleSubmit = () => {
        form
            .validateFields()
            .then(() => {
                onSetFloor(floors);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title={`Thiết lập các tầng cho tòa nhà ${building ? building.name : ''}`}
            okText="Lưu"
            cancelText="Hủy"
            width={800}
            onCancel={onCancel}
            onOk={handleSubmit}
            className={cx("modal")}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className={cx("modal-header")}>
                        <Row gutter={16} style={{ width: '100%' }}>
                            <Col span={6}>Tầng</Col>
                            <Col span={6}>Loại tầng</Col>
                            <Col span={6}>Mô tả</Col>
                            <Col span={6}>Số căn hộ</Col>
                        </Row>
                    </div>
                    <div className={cx("modal-body")}>
                        {floors.map((floor, index) => (
                            <Row gutter={16} key={index}>
                                <Col span={6}>
                                    <Form.Item>
                                        <InputNumber
                                            value={floor.floor}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Select
                                            value={floor.floor_type}
                                            onChange={(value) => handleFloorChange(index, 'floor_type', value)}
                                        >
                                            <Option value={1}>Căn hộ</Option>
                                            <Option value={2}>Căn hộ kết hợp</Option>
                                            <Option value={3}>Khác</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <Input
                                            value={floor.floor_description}
                                            onChange={(e) => handleFloorChange(index, 'floor_description', e.target.value)}
                                            disabled={floor.floor_type === 1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        <InputNumber
                                            min={0}
                                            value={floor.apartment_number}
                                            onChange={(value) => handleFloorChange(index, 'apartment_number', value)}
                                            disabled={floor.floor_type !== 2}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default ModalSetFloor;
