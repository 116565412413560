import React from "react";

import classNames from "classnames/bind";
import styles from "page/Application/ProjectNew/ProjectNew.module.scss";
const cx = classNames.bind(styles);

const ProjectNew = () => {
    return (
        <>Thông tin dự án</>
    );
}

export default ProjectNew;