import axiosAdmin from "api/config/axiosAdmin";

// API cho phần bài viết
const getListAllPost = (perPage, page, key_word) => {
  const url = "admin/posts";
  return axiosAdmin.get(url, {
    perPage,
    page,
    params: {
      key_word
    },
  });
};

const addNewPost = (formData) => {
  const url = "admin/posts";
  return axiosAdmin.post(url, formData);
};

const deletePost = (id) => {
  const url = `/admin/posts/${id}`;
  return axiosAdmin.delete(url);
};

const getPostById = (id) => {
  const url = `/admin/posts/${id}`;
  return axiosAdmin.get(url);
};

const updatePostById = (id, formData) => {
  const url = `/admin/posts/${id}`;
  return axiosAdmin.post(url, formData);
};


// API cho phần danh mục
const getListAllCategory = (perPage, page) => {
  const url = "/admin/category";
  return axiosAdmin.get(url, {
    perPage,
    page,
  });
};

// API cho phần danh mục
const getListAllCategoryPost = () => {
  const url = "/admin/category-by-type";
  return axiosAdmin.get(url, {
    params: {
      category_type: 1,
    },
  });
};


const addNewCategory = (name, description, status, category_type) => {
  const url = "admin/category";
  return axiosAdmin.post(url, {
    name, description, status, category_type
  });
};

const deleteCategory = (id) => {
  const url = `/admin/category/${id}`;
  return axiosAdmin.delete(url);
};

const getCategoryById = (id) => {
  const url = `/admin/category/${id}`;
  return axiosAdmin.get(url);
};

const updateCategoryById = (id, name, description, status, category_type) => {
  const url = `/admin/category/${id}`;
  return axiosAdmin.put(url, { name, description, status, category_type });
};

const setHomeCategory = (id) => {
  const url = `/admin/set-home-category/${id}`;
  return axiosAdmin.put(url);
};




export {
  deletePost,
  getPostById,
  updatePostById,
  getListAllPost,
  addNewPost,
  getListAllCategory,
  addNewCategory,
  deleteCategory,
  getCategoryById,
  updateCategoryById,
  getListAllCategoryPost,
  setHomeCategory
};
