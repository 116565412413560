import axiosClient from "api/config/axiosClient";

/**
 * Get ALL tỉnh thành
 * 
 * @author: DungPT
 */
export const getProvinces = async () => {
    const url = "app/provinces";
    return axiosClient.get(url);
};

/**
 * Get list quận/huyện theo ID tỉnh thành
 * 
 * @author: DungPT
 * @param {string} province_code 
 * @returns 
 */
export const getDistricts = async (province_code) => {
    const url = `app/provinces/${province_code}/districts`;
    return axiosClient.get(url);
};

/**
 * Get list làng/xã/phường theo ID quận/huyện
 * 
 * @author: DungPT
 * @param {string} district_code 
 * @returns 
 */
export const getWards = async (district_code) => {
    const url = `app/districts/${district_code}/wards`;
    return axiosClient.get(url);
};


/**
 * Get chi tiết 1 đơn vị hành chính
 * 
 * @author: DungPT
 * @param {string} id 
 * @returns 
 */
export const getLocationDetails = async (id, type) => {
    const url = `app/administrative-unit/${id}/detail/${type}`;
    return axiosClient.get(url);
};