import {
  HOMEPAGE,
  TOOL_PATH,
  PROJECT_ROW_CT_PATH,
  PROJECT_ROW_TT_PATH,
  PROJECT_NEW_PATH,
  NEW_PATH,
  RENT_POST_PATH,
} from "./appPath";
import {
  HomeOutlined,
  SearchOutlined,
  ProjectOutlined,
  BankOutlined,
  BlockOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

const menuItemApp = [
  {
    key: "home",
    icon: <HomeOutlined />,
    label: "Trang chủ",
    path: `${HOMEPAGE}`,
  },
  // {
  //   key: "tool",
  //   label: "Tra cứu căn hộ",
  //   icon: <SearchOutlined />,
  //   path: `${TOOL_PATH}`,
  // },
  {
    key: "project_row",
    label: "Bảng hàng dự án",
    icon: <ProjectOutlined />,
    children: [
      {
        key: "project_row_CT",
        label: "Bảng hàng cao tầng",
        path: `${PROJECT_ROW_CT_PATH}`,
      },
      {
        key: "project_row_TT",
        label: "Bảng hàng thấp tầng",
        path: `${PROJECT_ROW_TT_PATH}`,
      },
    ],
  },
  {
    key: "rent_post",
    label: "Cho thuê",
    icon: <ShoppingOutlined />,
    path: `${RENT_POST_PATH}`,
  },
  // {
  //   key: "new",
  //   label: "Tin tức",
  //   icon: <BlockOutlined />,
  //   path: `${NEW_PATH}`,
  // },
];
export { menuItemApp };
