import React from "react";
import classNames from "classnames/bind";
import styles from "layouts/components/Footer/Footer.module.scss";
import { Col, Container, Row } from "react-bootstrap";

const cx = classNames.bind(styles);

function Footer() {
  return (
    <div className={cx("footer-container")}>
      <div className={cx("content-align--center")}>
        <div className={cx("footer-item")}>
          <div className={cx("w-container")}>
            <Container fluid>
              <Row>
                Footer
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
