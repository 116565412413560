import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Input,
    Button,
    Radio,
    Upload,
    Select,
    Row,
    Col,
    Typography,
    Divider,
    message,
    DatePicker,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { addRentPost, getListRentPayment } from "api/app/rentPost.api";
import { uploadFileImage } from "api/common/upload.api";
import {
    CODE_MASKING_OPTION,
    SERVICE_FEE,
    CONTRACT_DURATION,
    FURNITURE_OPTIONS,
    BASIC_CONDITION,
} from "constant/rentPost";

import { getListAllProjectByType, getListBuildingsByProjectId, getListFloorsByBuildingId } from "api/app/project.api";
import { searchApartmentLowRiseCode, searchApartmentCode } from "api/app/search.api";
import { RENT_POST_PATH } from "constant/appPath";

const { Title, Text } = Typography;

const RentPostAdd = () => {
    const [form] = Form.useForm();
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [image, setImage] = useState([]); // State for layout image
    const [selectedType, setSelectedType] = useState(null); // "Cao tầng" hoặc "Thấp tầng"
    const [projects, setProjects] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [floors, setFloors] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedFloor, setSelectedFloor] = useState(null);
    const [rentCodeOptions, setRentCodeOptions] = useState([]);
    const navigate = useNavigate();

    const { id } = useParams();
    const isEditMode = !!id;

    // Fetch payment options from API when component is mounted
    useEffect(() => {
        const fetchPaymentOptions = async () => {
            try {
                const response = await getListRentPayment();
                if (response) {
                    setPaymentOptions(response);
                } else {
                    // Cung cấp giá trị mặc định nếu API trả về null hoặc không có dữ liệu
                    setPaymentOptions([]);
                    message.warning("Không có tùy chọn thanh toán nào được tìm thấy");
                }
            } catch (error) {
                message.error("Error fetching payment options");
                console.error("Error:", error);
            }
        };

        fetchPaymentOptions();
    }, []);

    const onFinish = async (values) => {
        const payload = {
            ...values,
            rent_images: image, // Thêm danh sách URL ảnh vào giá trị form
        };
        try {
            if (isEditMode) {
                //   const response = await updatePostById(id, payload);
                //   console.log("Bài viết Update: ", response);
                //   message.success('Cập nhật thành công bài viết');
            } else {
                await addRentPost(payload);
                message.success("Tạo mới thành công bài viết");
            }

            window.location.href = RENT_POST_PATH;
        } catch (error) {
            console.log("Lỗi insert/update bài viết: ", error);
            message.error("Lỗi khi tạo bài viết: " + error.message);
        }
    };

    const handleImageUpload = async ({ file }) => {
        try {
            const response = await uploadFileImage(file);
            if (response && response.url) {
                setImage((prevImages) => [...prevImages, response.url]); // Add the new image URL to the list
                message.success("Ảnh đã được tải lên thành công.");
            } else {
                message.error("Tải ảnh lên không thành công.");
            }
        } catch (error) {
            message.error("Lỗi khi tải ảnh lên.");
        }
    };

    const handleImageDelete = (url) => {
        // Loại bỏ ảnh khỏi danh sách khi nhấn nút xóa
        setImage((prevImages) => prevImages.filter((imageUrl) => imageUrl !== url));
        message.success("Ảnh đã được xóa.");
    };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getListAllProjectByType(selectedType);
                setProjects(response);
            } catch (error) {
                message.error("Lỗi khi tải danh sách dự án.");
            }
        };
        if (selectedType) {
            fetchProjects();
        } else {
            setProjects([]);
        }
        // Reset dependent fields
        setSelectedProject(null);
        setBuildings([]);
        setSelectedBuilding(null);
        setFloors([]);
        setSelectedFloor(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType]);

    useEffect(() => {
        const fetchBuildings = async () => {
            try {
                const response = await getListBuildingsByProjectId(selectedProject);
                setBuildings(response);
            } catch (error) {
                message.error("Lỗi khi tải danh sách tòa.");
            }
        };

        if (selectedProject && selectedType === "cao_tang") {
            fetchBuildings();
        } else {
            setBuildings([]);
        }
        // Reset dependent fields
        setSelectedBuilding(null);
        setFloors([]);
        setSelectedFloor(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject, selectedType]);

    useEffect(() => {
        const fetchFloors = async () => {
            try {
                const response = await getListFloorsByBuildingId(selectedBuilding);
                setFloors(response);
            } catch (error) {
                message.error("Lỗi khi tải danh sách tầng.");
            }
        };

        if (selectedBuilding) {
            fetchFloors();
        } else {
            setFloors([]);
        }
        // Reset dependent fields
        setSelectedFloor(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBuilding]);

    const handleSelectType = (value) => {
        setSelectedType(value);
        form.setFieldsValue({
            project: null,
            building: null,
            floor: null,
            rent_code: null,
        });
        setRentCodeOptions([]);
    };

    const handleSelectProject = (value) => {
        setSelectedProject(value);
        form.setFieldsValue({
            building: null,
            floor: null,
        });
    };

    const handleSelectBuilding = (value) => {
        setSelectedBuilding(value);
        form.setFieldsValue({
            floor: null,
        });
    };

    const handleSelectFloor = (value) => {
        setSelectedFloor(value);
    };

    const handleSearchRentCode = async (value) => {
        if (!value) return;

        try {
            let result;

            if (selectedType === "cao_tang" && selectedBuilding && selectedFloor) {
                // Call API searchApartmentCode for cao_tang
                result = await searchApartmentCode({
                    building_id: selectedBuilding,
                    floor_id: selectedFloor,
                    text: value,
                });
            } else if (selectedType === "thap_tang" && selectedProject) {
                // Call API searchApartmentLowRiseCode for thap_tang
                result = await searchApartmentLowRiseCode({
                    text: value,
                    project_id: selectedProject,
                });
            }

            // Set rent code options if result is valid
            setRentCodeOptions(Array.isArray(result?.data) ? result.data : []);
        } catch (error) {
            console.error("Error searching rent code:", error);
        }
    };

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={24}>
                    {/* Cột bên trái */}
                    <Col span={16}>
                        <div
                            style={{
                                backgroundColor: "#fff",
                                padding: "16px",
                                borderRadius: "8px",
                                marginBottom: "16px",
                            }}
                        >
                            <Title level={3}>Đăng cho thuê</Title>
                            <Text type="secondary">Thông tin có dấu (*) là bắt buộc.</Text>
                            <Divider />
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="type"
                                        rules={[{ required: true, message: "Vui lòng chọn loại hình" }]}
                                    >
                                        <Select
                                            placeholder="Chọn loại hình"
                                            onChange={handleSelectType}
                                            allowClear
                                        >
                                            <Select.Option value="cao_tang">Cao tầng</Select.Option>
                                            <Select.Option value="thap_tang">Thấp tầng</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="project"
                                        rules={[{ required: true, message: "Vui lòng chọn dự án" }]}
                                    >
                                        <Select
                                            placeholder="Chọn dự án"
                                            onChange={handleSelectProject}
                                            allowClear
                                            disabled={!selectedType}
                                        >
                                            {projects.map((project) => (
                                                <Select.Option key={project.id} value={project.id}>
                                                    {project.project_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {selectedType === "cao_tang" && (
                                    <Col span={6}>
                                        <Form.Item
                                            name="building"
                                            rules={[{ required: true, message: "Vui lòng chọn tòa" }]}
                                        >
                                            <Select
                                                placeholder="Chọn tòa"
                                                onChange={handleSelectBuilding}
                                                allowClear
                                                disabled={!selectedProject}
                                            >
                                                {buildings.map((building) => (
                                                    <Select.Option key={building.id} value={building.id}>
                                                        {building.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                {selectedBuilding && (
                                    <Col span={6}>
                                        <Form.Item
                                            name="floor"
                                            rules={[{ required: true, message: "Vui lòng chọn tầng" }]}
                                        >
                                            <Select
                                                placeholder="Chọn tầng"
                                                onChange={handleSelectFloor}
                                                allowClear
                                                disabled={!selectedBuilding}
                                            >
                                                {floors.map((floor) => (
                                                    <Select.Option key={floor.floor} value={floor.floor}>
                                                        Tầng {floor.floor}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        label="Cho thuê mã căn"
                                        name="rent_code"
                                        rules={[{ required: true, message: "Vui lòng nhập mã căn" }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={
                                                selectedType === "cao_tang" && !selectedFloor
                                                    ? "Vui lòng chọn tầng trước"
                                                    : selectedType === "thap_tang" && !selectedProject
                                                        ? "Vui lòng chọn dự án trước"
                                                        : "Nhập mã căn"
                                            }
                                            onSearch={handleSearchRentCode}
                                            filterOption={false} // Disable built-in filtering since we're fetching options from API
                                            disabled={
                                                (selectedType === "cao_tang" && !selectedFloor) ||
                                                (selectedType === "thap_tang" && !selectedProject)
                                            }
                                            options={rentCodeOptions.map((code) => ({
                                                label: code, // Assuming each code is a string
                                                value: code
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        label="Tiêu đề"
                                        name="title"
                                        rules={[{ required: true, message: "Vui lòng nhập tiêu đề" }]}
                                    >
                                        <Input placeholder="Nhập tiêu đề" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Giá thuê"
                                name="rent_price"
                                rules={[{ required: true, message: "Vui lòng nhập giá thuê" }]}
                            >
                                <Input placeholder="3.000.000" />
                            </Form.Item>
                            <Form.Item
                                label="Điểm nổi bật"
                                name="highlight"
                                rules={[{ max: 300, message: "Tối đa 300 ký tự" }]}
                            >
                                <Input.TextArea
                                    placeholder="Nhập điểm nổi bật"
                                    maxLength={300}
                                />
                            </Form.Item>

                            {/* Hình ảnh tin đăng */}
                            <Form.Item label="Upload Ảnh">
                                <Upload
                                    customRequest={handleImageUpload}
                                    listType="picture"
                                    accept="image/*"
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />}>Tải lên ảnh</Button>
                                </Upload>
                            </Form.Item>

                            {image.length > 0 && (
                                <div style={{ marginTop: "10px" }}>
                                    <h4>Danh sách ảnh đã tải lên:</h4>
                                    <Row gutter={[16, 16]}>
                                        {image.map((url, index) => (
                                            <Col key={index} span={6}>
                                                <div style={{ position: "relative" }}>
                                                    <img
                                                        src={url}
                                                        alt={`Uploaded ${index + 1}`}
                                                        style={{ width: "100%", height: "auto" }}
                                                    />
                                                    <Button
                                                        icon={<DeleteOutlined />}
                                                        style={{
                                                            position: "absolute",
                                                            top: "5px",
                                                            right: "5px",
                                                        }}
                                                        danger
                                                        onClick={() => handleImageDelete(url)}
                                                    >
                                                        Xóa
                                                    </Button>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                            <Form.Item label="Link video youtube" name="url_youtube">
                                <Input placeholder="Link video youtube" />
                            </Form.Item>

                            {/* <Form.Item>
                <Checkbox>Thêm video từ Youtube</Checkbox>
              </Form.Item> */}

                            {/* <Form.Item>
                <Button icon={<UploadOutlined />}>Tải video của bạn</Button>
              </Form.Item> */}
                        </div>
                        <Row>
                            <Col span={12}>
                                <Button onClick={() => navigate(-1)}>
                                    Quay lại
                                </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Đăng tin
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>

                    {/* Cột bên phải */}
                    <Col span={8}>
                        <div
                            style={{
                                backgroundColor: "#fff",
                                padding: "16px",
                                borderRadius: "8px",
                                marginBottom: "16px",
                            }}
                        >
                            <Form.Item label="Tùy chọn che mã" name="code_masking_option">
                                <Radio.Group>
                                    {CODE_MASKING_OPTION.map((option) => (
                                        <Radio key={option.value} value={option.value}>
                                            {option.label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Phí dịch vụ" name="service_fee">
                                        <Select>
                                            {SERVICE_FEE.map((option) => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Nội thất" name="furniture">
                                        <Select>
                                            {FURNITURE_OPTIONS.map((option) => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Đồ cơ bản" name="basic_condition">
                                        <Select>
                                            {BASIC_CONDITION.map((option) => (
                                                <Select.Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Thời gian vào được" name="move_in_date">
                                        <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* Hợp đồng - chỉ được chọn 1 option */}
                            <Form.Item label="Hợp đồng" name="contract_duration">
                                <Radio.Group>
                                    {CONTRACT_DURATION.map((option) => (
                                        <Radio key={option.value} value={option.value}>
                                            {option.label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>

                            {/* Thanh toán - dynamic options from API */}
                            <Form.Item label="Thanh toán" name="payment">
                                <Radio.Group>
                                    {paymentOptions.length > 0 ? (
                                        paymentOptions.map((option) => (
                                            <Radio key={option.id} value={option.id}>
                                                {option.payment_plan}
                                            </Radio>
                                        ))
                                    ) : (
                                        <Text>Đang tải các phương thức thanh toán...</Text>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        {/* Thông tin liên hệ */}
                        <div
                            style={{
                                backgroundColor: "#fff",
                                padding: "16px",
                                borderRadius: "8px",
                            }}
                        >
                            <Form.Item label="Bạn là" name="is_broker">
                                <Radio.Group>
                                    <Radio value="1">Môi giới</Radio>
                                    <Radio value="0">Chính chủ</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Họ tên"
                                name="contact_name"
                                rules={[{ required: true, message: "Vui lòng nhập họ tên" }]}
                            >
                                <Input placeholder="Nhập họ tên" />
                            </Form.Item>

                            <Form.Item
                                label="Điện thoại"
                                name="contact_phone"
                                rules={[
                                    { required: true, message: "Vui lòng nhập số điện thoại" },
                                ]}
                            >
                                <Input placeholder="Nhập số điện thoại" />
                            </Form.Item>

                            <Form.Item label="Họ tên chủ nhà" name="owner_name">
                                <Input placeholder="Nhập họ tên chủ nhà" />
                            </Form.Item>

                            <Form.Item label="Điện thoại chủ nhà" name="owner_phone">
                                <Input placeholder="Nhập số điện thoại chủ nhà" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default RentPostAdd;
