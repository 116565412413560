import React, { useState } from "react";
import { Table, Spin } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { convertToBillion } from "api/common/utils";
import ApartmentLowRiseInfoModal from "components/Modal/ApartmentLowRiseInfoModal";

const DataTableApartmentLowRise = ({ data, total, loadMoreData, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Items per page
  const [isModalVisible, setIsModalVisible] = useState(false); // For modal visibility
  const [selectedApartmentId, setSelectedApartmentId] = useState(null); // To store selected apartment ID

  const columns = [
    {
      title: "PHÂN KHU",
      dataIndex: "subdivision_name",
      key: "subdivision_name",
    },
    {
      title: "LOẠI HÌNH",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        switch (type) {
          case "biet_thu":
            return "Biệt thự";
          case "lien_ke":
            return "Liền kề";
          case "shophouse":
            return "Shop house";
          default:
            return type;
        }
      },
    },
    {
      title: "Mã căn",
      dataIndex: "code",
      key: "code",
      render: (code, record) => (
        <a onClick={() => handleOpenModal(record.id)}>{code}</a> // Clickable apartment code
      ),
    },
    {
      title: "DT ĐẤT",
      dataIndex: "land_area",
      key: "land_area",
      render: (land_area) => `${land_area} m²`,
    },
    {
      title: "DT XD",
      dataIndex: "construction_area",
      key: "construction_area",
      render: (construction_area) => `${construction_area} m²`,
    },
    {
      title: "Hướng",
      dataIndex: "direction",
      key: "direction",
    },
    {
      title: "TCBG",
      dataIndex: "construction_status",
      key: "construction_status",
    },
    {
      title: "Giá VAT&KPBT",
      dataIndex: "price",
      key: "price",
      render: (price) => `${convertToBillion(price)}`,
    },
    {
      title: "CSBH",
      dataIndex: "document_link",
      key: "document_link",
      render: (value) => {
        if (value) {
          return (
            <a href={value} target="_blank" rel="noopener noreferrer">
              <ExportOutlined /> Xem
            </a>
          );
        }
        return "N/A";
      },
    },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current); // Update current page
    loadMoreData(pagination.current); // Fetch new data for the current page
  };

  const handleOpenModal = (apartmentId) => {
    setSelectedApartmentId(apartmentId); // Set the selected apartment ID
    setIsModalVisible(true); // Show the modal
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); // Hide the modal
    setSelectedApartmentId(null); // Reset the selected apartment ID
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total, // Total records
          showSizeChanger: false, // No option to change page size
        }}
        onChange={handleTableChange}
      />
      {loading && (
        <div style={{ textAlign: "center", padding: 16 }}>
          <Spin />
        </div>
      )}
      <ApartmentLowRiseInfoModal
        visible={isModalVisible}
        apartmentId={selectedApartmentId}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
      />
    </div>
  );
};

export default DataTableApartmentLowRise;
