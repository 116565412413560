// ModalAddSalesPolicy.jsx
import React from 'react';
import { Modal, Input, Select, message, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const ModalAddSalesPolicy = ({ visible, onClose, onAdd }) => {
    const [newPolicy, setNewPolicy] = React.useState({
        sales_policy_name: '',
        description: '',
        classification: 1,
        document_link: ''
    });

    const handleSave = async () => {
        try {
            await onAdd(newPolicy);
            setNewPolicy({ sales_policy_name: '', description: '', classification: 1, document_link: '' });
            onClose();
        } catch (error) {
            console.error('Error adding new sales policy:', error);
            message.error('Có lỗi xảy ra khi thêm chính sách.');
        }
    };

    return (
        <Modal
            title="Thêm Chính Sách Mới"
            visible={visible}
            onOk={handleSave}
            onCancel={onClose}
        >
            <div style={{ marginBottom: '1rem' }}>
                <Text strong>Phân loại</Text>
                <Select
                    placeholder="Chọn phân loại"
                    value={newPolicy.classification}
                    onChange={(value) => setNewPolicy({ ...newPolicy, classification: value })}
                    style={{ width: '100%' }}
                >
                    <Option value={1}>Dự án</Option>
                    <Option value={2}>Tòa nhà</Option>
                    <Option value={3}>Căn hộ</Option>
                </Select>
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <Text strong>Tên chính sách</Text>
                <Input
                    placeholder="Tên chính sách"
                    value={newPolicy.sales_policy_name}
                    onChange={(e) => setNewPolicy({ ...newPolicy, sales_policy_name: e.target.value })}
                />
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <Text strong>Mô tả</Text>
                <Input
                    placeholder="Mô tả"
                    value={newPolicy.description}
                    onChange={(e) => setNewPolicy({ ...newPolicy, description: e.target.value })}
                />
            </div>
            <div style={{ marginBottom: '1rem' }}>
                <Text strong>Link tài liệu</Text>
                <Input
                    placeholder="Link tài liệu"
                    value={newPolicy.document_link}
                    onChange={(e) => setNewPolicy({ ...newPolicy, document_link: e.target.value })}
                />
            </div>
        </Modal>
    );
};

export default ModalAddSalesPolicy;
