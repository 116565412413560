// Loại phòng
export const ROOM_TYPE = [
    'Studio',
    '1PN',
    '1PN+1',
    '2PN1WC',
    '2PN',
    '2PN+1',
    '3PN',
    '3PN+1',
    'Duplex',
    '4PN'
];

// Hướng ban công
export const BALCONY_DIRECTION = [
    'ĐB',
    'TB',
    'ĐN',
    'TN',
    'ĐB-TB',
    'ĐB-ĐN',
    'ĐN-TN',
    'TB-TN',
    'TN-ĐB',
    'TB-ĐN'
];