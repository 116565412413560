import axiosClient from "api/config/axiosClient";

const searchApartment = (filters) => {
    const url = "auth/search/apartments";
    return axiosClient.get(url, { params: filters });
};

const searchApartmentLowRise = (filters) => {
    const url = "auth/search/apartments-low-rise";
    return axiosClient.get(url, { params: filters });
};



export {
    searchApartment,
    searchApartmentLowRise
};