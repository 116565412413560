// AdminSalesPolicy.jsx
import React, { useState, useEffect } from 'react';
import { Layout, Table, Button, Input, Popconfirm, Select, message, Spin, Typography, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalAddSalesPolicy from 'components/Modal/ModalAddPolicy/ModalAddSalesPolicy';
import {
    addNewSalesPolicy,
    getListAllSalesPolicy,
    updateSalesPolicyById,
    deleteSalesPolicy
} from 'api/admin/salesPolicy.api';

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const AdminSalesPolicy = () => {
    const [dataSource, setDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPolicies = async () => {
            setLoading(true);
            try {
                const response = await getListAllSalesPolicy();
                setDataSource(response.map(item => ({ key: item.id.toString(), ...item })));
            } catch (error) {
                console.error('Error fetching sales policies:', error);
                message.error('Có lỗi xảy ra khi tải danh sách chính sách.');
            } finally {
                setLoading(false);
            }
        };

        fetchPolicies();
    }, []);

    const handleAdd = () => {
        setIsModalVisible(true);
    };

    const handleSave = async (newPolicy) => {
        try {
            const response = await addNewSalesPolicy(newPolicy);
            setDataSource([...dataSource, { key: response.id.toString(), ...response }]);
            message.success('Thêm chính sách thành công!');
        } catch (error) {
            console.error('Error adding new sales policy:', error);
            message.error('Có lỗi xảy ra khi thêm chính sách.');
        }
    };

    const handleDelete = async (key) => {
        setLoading(true);
        try {
            const response = await deleteSalesPolicy(key);
            if (response && response.success) {
                setDataSource(dataSource.filter((item) => item.key !== key));
                message.success('Xóa chính sách thành công!');
            } else {
                message.error('Có lỗi xảy ra, vui lòng thử lại.');
            }
        } catch (error) {
            console.error('Error deleting sales policy:', error);
            message.error('Có lỗi xảy ra khi xóa chính sách.');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (key, field, value) => {
        const updatedDataSource = dataSource.map((item) =>
            item.key === key ? { ...item, [field]: value } : item
        );
        setDataSource(updatedDataSource);

        try {
            const updatedPolicy = updatedDataSource.find((item) => item.key === key);
            await updateSalesPolicyById(key, updatedPolicy);
            message.success('Cập nhật chính sách thành công.');
        } catch (error) {
            console.error('Error updating sales policy:', error);
            message.error('Có lỗi xảy ra khi cập nhật chính sách.');
        }
    };

    const columns = [
        {
            title: 'Tên chính sách',
            dataIndex: 'sales_policy_name',
            editable: true,
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleEdit(record.key, 'sales_policy_name', e.target.value)}
                />
            ),
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            editable: true,
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleEdit(record.key, 'description', e.target.value)}
                />
            ),
        },
        {
            title: 'Phân loại',
            dataIndex: 'classification',
            render: (text, record) => (
                <Select
                    value={text}
                    onChange={(value) => handleEdit(record.key, 'classification', value)}
                >
                    <Option value={1}>Dự án</Option>
                    <Option value={2}>Tòa nhà</Option>
                    <Option value={3}>Căn hộ</Option>
                </Select>
            ),
        },
        {
            title: 'Link tài liệu',
            dataIndex: 'document_link',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleEdit(record.key, 'document_link', e.target.value)}
                />
            ),
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <Button type="link" danger>
                            Xóa
                        </Button>
                    </Popconfirm>
                ) : null,
        },
    ];

    return (
        <Layout>
            <Content style={{ padding: "0 50px" }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
                    <Col>
                        <Title level={3}>Danh sách chính sách bán hàng</Title>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
                            Thêm mới
                        </Button>
                    </Col>
                </Row>
                <Spin spinning={loading}>
                    <Table
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Spin>
                {/* Modal for adding new sales policy */}
                <ModalAddSalesPolicy
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onAdd={handleSave}
                />
            </Content>
        </Layout>
    );
};

export default AdminSalesPolicy;
