import React from "react";
import { RouterProvider } from "react-router-dom";
import appRouter from "routes/appRouter";
import adminRouter from "routes/AdminRouter";
import { createBrowserRouter } from "react-router-dom";
import { UserProvider } from "context/UserContext";
import { AdminProvider } from "context/AdminContext";

const routers = createBrowserRouter([...appRouter, ...adminRouter]);

function App() {
  return (
    <UserProvider>
      <AdminProvider>
        <RouterProvider router={routers} />
      </AdminProvider>
    </UserProvider>
  );
}

export default App;
