import React from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import queryString from 'query-string';
import classNames from "classnames/bind";
import styles from "page/Application/SearchResultsPage/SearchResultsPage.module.scss";
const cx = classNames.bind(styles);

const { Content } = Layout;

const SearchResultsPage = () => {
    const location = useLocation();
    const { query } = queryString.parse(location.search);

    // Giả lập kết quả tìm kiếm
    const searchResults = [
        { id: 1, title: "Kết quả 1", description: "Mô tả kết quả 1" },
        { id: 2, title: "Kết quả 2", description: "Mô tả kết quả 2" },
        // Thêm kết quả khác nếu có
    ];

    return (
        <Content>
            <h1>Kết quả tìm kiếm cho: "{query}"</h1>
            {searchResults.map(result => (
                <div key={result.id} className={styles.result}>
                    <h2>{result.title}</h2>
                    <p>{result.description}</p>
                </div>
            ))}
        </Content>
    );
};

export default SearchResultsPage;
