// src/components/DataTable.js
import React from 'react';
import { Table } from 'antd';

const DataTable = ({ data }) => {
    const columns = [
        {
            title: 'Mã căn',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Giá Full VAT',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Giá/m2',
            dataIndex: 'price_per_sqm',
            key: 'price_per_sqm',
        },
        {
            title: 'DT_TT(m2)',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Loại căn',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Hướng BC',
            dataIndex: 'orientation',
            key: 'orientation',
        },
        {
            title: 'PTG',
            dataIndex: 'ptgLink',
            key: 'ptgLink',
            render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">Mở link</a>,
        },
        {
            title: 'CSBH',
            dataIndex: 'csbLink',
            key: 'csbLink',
            render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">Mở link</a>,
        },
    ];

    return <Table columns={columns} dataSource={data} rowKey="code" />;
};

export default DataTable;
