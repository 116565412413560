import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Upload,
  Button,
  message,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { getSubdivisions } from "api/admin/project.api";
import { uploadFileImage, deleteFileImage } from "api/common/upload.api";
import MyCustomUploadAdapter from "common/MyCustomUploadAdapter";
import { getListAllSalesPolicy } from "api/admin/salesPolicy.api";

const { Option } = Select;

const ModalAddEditBuilding = ({
  visible,
  onCreate,
  onCancel,
  building,
  projects,
}) => {
  const [form] = Form.useForm();
  const [zones, setZones] = useState([]);
  const [layoutImage, setLayoutImage] = useState(null); // State for layout image
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [description, setDescription] = useState("");
  const [salesPolicies, setSalesPolicies] = useState([]); // State for sales policies

  useEffect(() => {
    if (visible) {
      setEditorLoaded(true); // Trì hoãn khởi tạo CKEditor
      fetchSalesPolicies(); // Fetch sales policies when modal becomes visible
    } else {
      setEditorLoaded(false);
    }
    if (visible && building) {
      form.setFieldsValue(building);
      if (building.project_id) {
        loadZones(building.project_id);
      }
      if (building.layout_image) {
        setLayoutImage(building.layout_image);
      }
      if (building.description) {
        setDescription(building.description);
      }
    } else {
      form.resetFields();
      setZones([]);
      setLayoutImage(null);
    }
  }, [visible, building]);

  // Function to fetch sales policies from the API
  const fetchSalesPolicies = async () => {
    try {
      const response = await getListAllSalesPolicy();
      setSalesPolicies(response); // Update state with the fetched sales policies
    } catch (error) {
      console.error("Failed to load sales policies:", error);
    }
  };

  const loadZones = async (projectId) => {
    try {
      const response = await getSubdivisions(projectId);
      setZones(response);
    } catch (error) {
      console.error("Failed to load zones:", error);
    }
  };

  const handleProjectChange = (projectId) => {
    form.setFieldsValue({ zone_id: undefined });
    loadZones(projectId);
  };

  const handleImageUpload = async ({ file }) => {
    try {
      const response = await uploadFileImage(file);
      if (response && response.url) {
        setLayoutImage(response.url); // Set the layout image with the returned URL
        message.success("Ảnh đã được tải lên thành công.");
      } else {
        message.error("Tải ảnh lên không thành công.");
      }
    } catch (error) {
      message.error("Lỗi khi tải ảnh lên.");
    }
  };

  const handleImageDelete = async () => {
    try {
      if (layoutImage) {
        console.log("Check file: ", layoutImage);
        const response = await deleteFileImage(layoutImage); // Call API to delete image
        if (response && response.success) {
          setLayoutImage(null); // Reset the layout image state
          message.success("Ảnh đã được xóa thành công.");
        } else {
          message.error("Xóa ảnh không thành công.");
        }
      } else {
        message.error("Không có ảnh để xóa.");
      }
    } catch (error) {
      message.error("Lỗi khi xóa ảnh.");
    }
  };

  const handleEditorInit = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyCustomUploadAdapter(loader); // Use 'new' keyword here
    };
  };

  return (
    <Modal
      visible={visible}
      title={building ? "Chỉnh sửa tòa nhà" : "Thêm tòa nhà"}
      okText={building ? "Lưu" : "Thêm"}
      cancelText="Hủy"
      width={800}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.layout_image = layoutImage;
            values.description = description;
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={building || {}}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="project_id"
              label="Dự án"
              rules={[{ required: true, message: "Vui lòng chọn dự án!" }]}
            >
              <Select placeholder="Chọn dự án" onChange={handleProjectChange}>
                {projects.map((project) => (
                  <Option key={project.id} value={project.id}>
                    {project.project_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="zone_id"
              label="Phân khu"
              rules={[{ required: true, message: "Vui lòng chọn phân khu!" }]}
            >
              <Select placeholder="Chọn phân khu">
                {zones.map((zone) => (
                  <Option key={zone.id} value={zone.id}>
                    {zone.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              name="code"
              label="Mã tòa nhà"
              rules={[{ required: true, message: "Vui lòng nhập mã tòa nhà" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="name"
              label="Tên tòa nhà"
              rules={[
                { required: true, message: "Vui lòng nhập tên tòa nhà!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="layout" label="Layout tòa nhà">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="amenities"
              label="Tiện ích"
              rules={[
                { required: true, message: "Vui lòng nhập các tiện ích!" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="total_apartments"
              label="Tổng số căn hộ"
              rules={[
                { required: true, message: "Vui lòng nhập tổng số căn hộ!" },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="floors"
              label="Số tầng"
              rules={[{ required: true, message: "Vui lòng nhập số tầng!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="apartment_density"
              label="Mật độ căn hộ"
              rules={[
                { required: true, message: "Vui lòng nhập mật độ căn hộ!" },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="basement" label="Số tầng hầm">
              <InputNumber min={0} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="elevators" label="Số lượng thang máy">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="cargo_elevators"
              label="Số lượng thang máy chở hàng"
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="stairs" label="Số cầu thang bộ">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="sales_policy_id"
              label="Chính sách bán hàng"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn chính sách bán hàng",
                },
              ]}
            >
              <Select placeholder="Chọn chính sách bán hàng">
                {salesPolicies.map((policy) => (
                  <Option key={policy.id} value={policy.id}>
                    {policy.sales_policy_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ảnh thiết kế">
              <Upload
                customRequest={handleImageUpload}
                listType="picture"
                maxCount={1}
                accept="image/*"
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
              </Upload>
              {layoutImage && (
                <>
                  <img
                    src={layoutImage}
                    alt="Layout Image"
                    style={{ marginTop: "10px", maxWidth: "100%" }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={handleImageDelete}
                    style={{ marginTop: "10px", color: "red" }}
                  >
                    Xóa ảnh
                  </Button>
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Mô tả chi tiết tòa nhà">
              {editorLoaded ? (
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={handleEditorInit}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              ) : (
                <Input.TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddEditBuilding;
