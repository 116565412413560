import axiosClient from "api/config/axiosClient";

const getListAllBuilding = () => {
  const url = "app/project/buildings";
  return axiosClient.get(url);
};

const getListAllApartmentDetailByProjectId = (building_id) => {
  const url = "app/project/building/apartment-detail-all-for-floor";
  return axiosClient.get(url, {
    params: {
      building_id,
    },
  });
};

const getListAllApartmentByProjectId = (building_id) => {
  const url = "app/project/building/apartment-all-for-floor";
  return axiosClient.get(url, {
    params: {
      building_id,
    },
  });
};

const getBuildingDetail = (building_id) => {
  const url = `app/project/buildings/${building_id}`;
  return axiosClient.get(url);
};

const getApartmentDetail = (id) => {
  const url = `app/project/buildings/apartment-detail/${id}`;
  return axiosClient.get(url);
};

const getApartmentLowRiseDetail = (id) => {
  const url = `app/project/apartments-detail-low-rise/${id}`;
  return axiosClient.get(url);
};

const getHomeBuilding = () => {
  const url = "app/project/building-home";
  return axiosClient.get(url);
};

const getNameProjectByBuildingId = (building_id) => {
  const url = `app/project/buildings/${building_id}/project-name`;
  return axiosClient.get(url);
};

/**
 * Get list all project by project_type
 *
 * @author DungPT
 * @param {string} project_type
 * @returns
 */
const getListAllProjectByType = (project_type) => {
  const url = "app/project/list-project-by-type";
  return axiosClient.get(url, {
    params: {
      project_type,
    },
  });
};

const getSubdivisionsByProjectId = (project_id) => {
  const url = "app/project/list-subdivisions";
  return axiosClient.get(url, {
    params: {
      project_id,
    },
  });
};

const getListBuildingsByProjectId = (project_id) => {
  const url = `app/project/list-building-by-project-id/${project_id}`;
  return axiosClient.get(url);
};

const getListFloorsByBuildingId = (building_id) => {
  const url = `app/project/list-floor-by-building-id/${building_id}`;
  return axiosClient.get(url);
};

export {
  getListAllBuilding,
  getListAllApartmentDetailByProjectId,
  getListAllApartmentByProjectId,
  getBuildingDetail,
  getApartmentDetail,
  getHomeBuilding,
  getNameProjectByBuildingId,
  getListAllProjectByType,
  getSubdivisionsByProjectId,
  getApartmentLowRiseDetail,
  getListBuildingsByProjectId,
  getListFloorsByBuildingId
};
