// src/components/ProjectLowRise.js
import React, { useState, useEffect, useCallback } from "react";
import { Spin, Button, Row, Col } from "antd";
import FilterForm from "components/FilterForm";
import DataTableApartmentLowRise from "components/DataTableApartmentLowRise";
import { searchApartmentLowRise } from "api/app/search.api";
import { getListAllProjectByType } from "api/app/project.api";
import classNames from "classnames/bind";
import styles from "page/Application/Tool/Tool.module.scss";

const cx = classNames.bind(styles);

const ProjectLowRise = () => {
  const [filters, setFilters] = useState({
    subdivision_id: "",
    type: "",
    direction: "",
    landAreaRange: [1000],
    priceRange: [1000],
  });

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await getListAllProjectByType("thap_tang");
        if (projects && projects.length > 0) {
          setProjectList(projects);
          setSelectedProject(projects[0].id);
          setProjectName(projects[0].project_name);
        }
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // useCallback to memoize fetchData function
  const fetchData = useCallback(
    async (appliedFilters, pageNum = 1) => {
      setLoading(true);
      const response = await searchApartmentLowRise({
        ...appliedFilters,
        page: pageNum,
        project_id: selectedProject,
      });

      if (response && response.data) {
        setData(response.data); // Set data for the current page
        setTotalCount(response.total); // Set total count
        setPage(pageNum); // Update current page
      } else {
        setData([]);
      }

      setLoading(false);
    },
    [selectedProject]
  );

  // useEffect to fetch data when selectedProject or filters change
  useEffect(() => {
    if (selectedProject && selectedProject !== "") {
      fetchData(filters, 1);
    }
  }, [selectedProject, filters, fetchData]);

  const handlePageChange = (pageNum) => {
    fetchData(filters, pageNum); // Fetch data for the selected page
  };

  const handleFilterChange = (e) => {
    if (!e) return; // Kiểm tra nếu e là null
    const { name, value } = e.target || e;
    if (!name) return; // Kiểm tra nếu name là undefined
    setFilters({
      ...filters,
      [name]: value || "", // Đảm bảo value không phải undefined
    });
    console.log("check filters: ", filters);
  };

  // Only update the selected project in this function
  const handleProjectChange = (project) => {
    setSelectedProject(project.id);
    setProjectName(project.project_name);
    setPage(1); // Reset lại trang về 1 khi thay đổi dự án
  };

  return (
    <>
      <div className={cx("home-container")}>
        <Row>
          <Col span={8} className={cx("home-container-title")}>
            <div className={cx("tool-title")}>Bảng hàng {projectName}</div>
            <div className={cx("tool-text-total")}>
              Hiện có <strong>{totalCount}</strong> kết quả
            </div>
          </Col>
          <Col span={16} className={cx("home-container-pulldown")}>
            {projectList.length > 0 ? (
              projectList.map((project) => (
                <Button
                  key={project.id}
                  className={cx({
                    "project-button": true,
                    active: selectedProject === project.id,
                  })}
                  onClick={() => handleProjectChange(project)}
                >
                  {project.project_name}
                </Button>
              ))
            ) : (
              <Spin />
            )}
          </Col>
        </Row>
        <FilterForm
          filters={filters}
          handleFilterChange={handleFilterChange}
          selectedProject={selectedProject}
        />
        <Row style={{ width: "100%" }}>
          <Col style={{ width: "100%" }}>
            {loading && page === 1 ? (
              <Spin />
            ) : (
              <DataTableApartmentLowRise
                data={data}
                total={totalCount}
                loading={loading}
                currentPage={page}
                onPageChange={handlePageChange}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProjectLowRise;
