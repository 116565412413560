import React from "react";
import { Outlet } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Row, Col } from 'antd';

const cx = classNames.bind(styles);

const Auth = () => {
  return (
    <div className={cx("auth-main-wrapper")}>
      <Row>
        <Col span={9}></Col>
        <Col span={6} className={cx("auth-content")}><Outlet /></Col>
        <Col span={9}></Col>
      </Row>
    </div>
  );
};

export default Auth;