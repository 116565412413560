import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  message,
  Select,
  Tooltip,
  Spin,
} from "antd";
import { getRentPosts, approveRentPost } from "api/admin/rentPost.api"; // Import approve API
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import moment from "moment";
import { HomeOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

const AdminRentPost = () => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getRentPosts({
        perPage: PAGE_SIZE,
        page: page,
        projectId: selectedProject,
      });
      setListData(response.data);
    } catch (error) {
      message.error("Lấy dữ liệu căn hộ thất bại: " + error.message);
    } finally {
      setLoading(false);
    }
  }, [page, PAGE_SIZE, selectedProject]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getListData = () => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return listData.slice(startIndex, endIndex);
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    setPage(PAGE_NUMBER); // Reset page when selecting a new project
  };

  const handleApprove = async (record) => {
    confirm({
      title: "Xác nhận phê duyệt",
      content: `Bạn có chắc muốn phê duyệt bài viết mã căn hộ ${record.code}?`,
      okText: "Phê duyệt",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          await approveRentPost(record.id); // Call the API to approve the rent post
          message.success("Phê duyệt thành công");
          fetchData(); // Refresh data after approval
        } catch (error) {
          message.error("Phê duyệt thất bại: " + error.message);
        }
      },
      onCancel() {
        message.info("Hủy phê duyệt");
      },
    });
  };

  const columns = [
    {
      title: "Mã căn hộ",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Giá thuê",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <span>
          {status === 0 ? (
            <Button type="primary" onClick={() => handleApprove(record)}>
              Phê duyệt
            </Button>
          ) : (
            "Công khai"
          )}
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <h2>Danh sách căn hộ cho thuê</h2>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={getListData()}
            pagination={false}
            rowKey="id"
          />
        </Spin>
        <Pagination
          current={page}
          pageSize={PAGE_SIZE}
          total={listData.length}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: "center" }}
        />
      </Content>
    </Layout>
  );
};

export default AdminRentPost;
