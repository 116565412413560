import React, { useState, useEffect, useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Form, Input, Select, Row, Col, message, Layout, Upload, Checkbox } from "antd";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { addNewPost, getPostById, updatePostById, getListAllCategoryPost } from "api/admin/post.api";
import { ADMIN_PATH, ADMIN_POST_PATH } from "constant/authPath";
import { useParams } from "react-router-dom";
import ModalAddCategory from "components/Modal/ModalAddCategory/ModalAddCategory";
import MyCustomUploadAdapter from "common/MyCustomUploadAdapter";

const { Option } = Select;
const { Content } = Layout;

const AdminPostNew = () => {
  const [form] = Form.useForm();
  const [content1, setContent1] = useState("");
  const [categories, setCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newlyAddedCategoryId, setNewlyAddedCategoryId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { id } = useParams();
  const isEditMode = !!id;

  const fetchCategories = useCallback(async () => {
    try {
      const response = await getListAllCategoryPost();
      setCategories(Array.isArray(response) ? response : []);
      if (!isEditMode && response && response.length > 0) {
        form.setFieldsValue({ category: response[0].id });
      }
    } catch (error) {
      console.error("Lỗi khi lấy danh sách danh mục:", error);
    }
  }, [form, isEditMode]);

  const fetchData = useCallback(async () => {
    try {
      const response = await getPostById(id);
      console.log("Chi tiết bài viết: ", response);
      if (response) {
        form.setFieldsValue({
          title: response.title,
          category: response.category,
          status: response.status ? response.status.toString() : "0",
          content: response.content,
          showOnHomepage: response.show_on_homepage || false,
        });
        setContent1(response.content);
        setImageUrl(response.image_url);
        setFileList(response.image_url ? [{ url: response.image_url, name: 'image', status: 'done' }] : []);
      }
      setLoading(false);
    } catch (error) {
      message.error('Lỗi khi lấy dữ liệu mục chỉnh sửa: ' + error.message);
      setLoading(false);
    }
  }, [form, id]);

  useEffect(() => {
    fetchCategories();
    if (isEditMode) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [isEditMode, fetchCategories, fetchData]);

  useEffect(() => {
    if (newlyAddedCategoryId) {
      form.setFieldsValue({ category: newlyAddedCategoryId });
    }
  }, [newlyAddedCategoryId, form]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setContent1(data);
    form.setFieldsValue({ content: data });
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0 && newFileList[0].status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(newFileList[0].originFileObj);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields(); // Lấy giá trị từ form
      console.log("Update: ", values);
      const content = content1;
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("content", content);
      formData.append("category", values.category);
      formData.append("status", values.status);
      formData.append("show_on_homepage", values.showOnHomepage ? 1 : 0);
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("image", fileList[0].originFileObj);
      }
      if (isEditMode) {
        const response = await updatePostById(id, formData);
        console.log("Bài viết Update: ", response);
        message.success('Cập nhật thành công bài viết');
      } else {
        await addNewPost(formData);
        message.success('Tạo mới thành công bài viết');
      }

      window.location.href = ADMIN_PATH + "/" + ADMIN_POST_PATH;
    } catch (error) {
      console.log("Lỗi insert/update bài viết: ", error);
      message.error('Lỗi khi tạo bài viết: ' + error.message);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCategoryAdded = (category) => {
    setCategories([...categories, category]);
    setNewlyAddedCategoryId(category.id);
    form.setFieldsValue({ category: category.id });
    setIsModalVisible(false);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleEditorInit = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyCustomUploadAdapter(loader); // Use 'new' keyword here
    };
  };

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row>
          <Col span={16}>
            <h2>{isEditMode ? "Chỉnh sửa bài viết" : "Thêm mới bài viết"}</h2>
          </Col>
        </Row>
        {!loading ? (
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{ title: "", category: isEditMode ? null : undefined, status: "", content: content1, showOnHomepage: false }}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Tiêu đề"
                  name="title"
                  rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
                >
                  <Input placeholder="Nhập tiêu đề bài viết" />
                </Form.Item>

                <Form.Item
                  label="Trạng thái"
                  name="status"
                  rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}
                >
                  <Select placeholder="Chọn trạng thái">
                    <Option value="0">Nháp</Option>
                    <Option value="1">Công khai</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Danh mục"
                  name="category"
                  rules={[{ required: true, message: 'Vui lòng chọn danh mục' }]}
                >
                  <Select placeholder="Chọn danh mục">
                    {categories.length > 0 ? (
                      categories.map((category) => (
                        <Option key={category.id} value={category.id}>
                          {category.name}
                        </Option>
                      ))
                    ) : (
                      <Option value="">Không có danh mục nào</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={showModal}>
                    Thêm mới danh mục
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={10}>
                    <Form.Item
                      label="Ảnh đại diện"
                      name="image"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                      rules={[{ required: true, message: 'Vui lòng upload ảnh đại diện' }]}
                    >
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleUploadChange}
                        beforeUpload={() => false}
                        maxCount={1}
                      >
                        {uploadButton}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', paddingRight: '20px' }} /> : ''}
                  </Col>
                </Row>

                <Form.Item
                  name="showOnHomepage"
                  valuePropName="checked"
                >
                  <Checkbox>Hiển thị ra trang chủ</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Nội dung"
                  name="content"
                  rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleEditorChange}
                    onReady={handleEditorInit}
                    data={content1}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {isEditMode ? "Cập nhật" : "Thêm mới"}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div>Loading...</div>
        )}
      </Content>
      <ModalAddCategory
        isModalVisible={isModalVisible}
        handleModalCancel={handleModalCancel}
        handleCategoryAdded={handleCategoryAdded}
      />
    </Layout>
  );
};

export default AdminPostNew;
