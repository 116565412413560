import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  message,
  Spin,
  Input
} from "antd";
import { getListAllPost, deletePost } from "api/admin/post.api";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import moment from "moment";
import { SearchOutlined, CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ADMIN_PATH } from "constant/authPath";

const { Content } = Layout;
const { confirm } = Modal;

const AdminProject = () => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [perPage, setPerPage] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [, setItemToDelete] = useState(null);
  const [keyWord, setKeyWord] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page, perPage, keyWord]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getListAllPost(perPage, page, keyWord);
      console.log("Check response list pos: ", response);
      setListData(response);
    } catch (error) {
      message.error("Failed to fetch projects: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setKeyWord(e.target.value);
  };

  const getListData = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return listData.slice(startIndex, endIndex);
  };

  const handleDelete = (id) => {
    confirm({
      title: "Confirm deletion",
      content: "Are you sure you want to delete?",
      onOk: async () => {
        try {
          await deletePost(id);
          message.success("Thực hiện xóa record id " + id + " thành công");
          setListData(listData.filter((item) => item.id !== id));
        } catch (error) {
          message.error("Thực hiện xóa thất bại và có lỗi: " + error.message);
        }
      },
      onCancel() {
        setItemToDelete(null);
      },
    });
  };

  const handleEdit = (record) => {
    navigate(`${ADMIN_PATH}/post-edit/${record.id}`);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Ảnh đại diện",
      dataIndex: "image_url",
      key: "image_url",
      render: (image_url) => (
        <img src={image_url} alt="avatar" style={{ width: '100px', height: 'auto' }} />
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tác giả",
      dataIndex: "author_name",
      key: "author_name",
    },
    {
      title: "Danh mục",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Updated at",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      title: "Hiển thị trang chủ",
      dataIndex: "show_on_homepage",
      key: "show_on_homepage",
      render: (showOnHomepage) => (
        showOnHomepage ? <CheckSquareOutlined style={{ color: 'green' }} /> : <CloseSquareOutlined style={{ color: 'red' }} />
      ),
    },
    {
      title: "Option",
      key: "option",
      render: (text, record) => (
        <>
          <Button type="primary" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "0 25px" }}>
        <Row gutter={16}>
          <Col span={12}>
            <h2>Danh sách bài viết</h2>
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '10px' }}>
          <Col>
            <Input
              placeholder="nhập từ khóa cần tìm kiếm"
              value={keyWord}
              onChange={handleSearchChange}
              style={{ width: 300, marginRight: 8 }}
              suffix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={getListData()}
            pagination={false}
            rowKey="id"
          />
          <Pagination
            current={page}
            pageSize={perPage}
            total={listData.length}
            onChange={handlePageChange}
            style={{ marginTop: 16, textAlign: "center" }}
          />
        </Spin>
      </Content>
    </Layout>
  );
};

export default AdminProject;
