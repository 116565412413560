import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Button, Row, Col, Typography, Divider, Spin, Image } from 'antd';
import {
    LockOutlined,
    ShoppingCartOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import { getApartmentDetail } from 'api/app/project.api';
import { getListAllStatusColor } from "api/app/master.api";

import classNames from "classnames/bind";
import styles from "./ApartmentDetail.module.scss";
const cx = classNames.bind(styles);

const { Title, Text } = Typography;

const ApartmentDetail = () => {
    const [apartment, setApartment] = useState(null);
    const [project, setProject] = useState({});
    const [building, setBuilding] = useState({});
    const [apartmentDetail, setApartmentDetail] = useState(null);
    const { apartmentId } = useParams();
    const [statusNames, setStatusNames] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (apartmentId) {
            setLoading(true);
            getApartmentDetail(apartmentId)
                .then(response => {
                    console.log("check thông tin chi tiết căn hộ: ", response);
                    setApartment(response.apartment);
                    setProject(response.project);
                    setBuilding(response.building);
                    setApartmentDetail(response.apartment_detail);

                })
                .catch(error => {
                    console.error("Failed to fetch apartment details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [apartmentId]);

    useEffect(() => {
        // Fetch status names from the API
        getListAllStatusColor()
            .then((response) => {
                const statusData = {};
                response.forEach((status) => {
                    statusData[status.id] = status.name; // Assuming response is an array of {id, name} objects
                });
                setStatusNames(statusData);
            })
            .catch((error) => {
                console.error("Failed to fetch status names:", error);
            });
    }, []);

    if (!apartmentDetail) {
        return null;
    }

    return (
        <Spin spinning={loading}>
            <Row>
                <Col span={4}>
                </Col>
                <Col span={16}>
                    <div className={cx("modal-header")}>{project.project_name} {building.name}.{apartmentDetail.apartment_name}</div>
                    <div className={cx("modal-header-price")}>
                        <div className={cx("price-title")}><ShoppingCartOutlined /> {apartmentDetail.price_view}đ</div>
                        <div className={cx("sub-text-1")}>Giá đã bao gồm VAT & KPBT</div>
                        <div className={cx("sub-text-2")}>Chính sách bán hàng áp dụng: {apartmentDetail.sales_policy_applied_monthly}</div>
                        <Divider style={{ margin: 0 }} />
                        <Row gutter={[16, 16]} justify="center" className={cx("price-other")}>
                            <Col span={6}>
                                <div className={cx("detail-text")}>Giá TTS</div>
                                <div level={4} className={cx("detail-title")}>{apartmentDetail.price_tts_view}</div>
                            </Col>
                            <Col span={6}>
                                <div className={cx("detail-text")}>Giá TTĐD</div>
                                <div level={4} className={cx("detail-title")}>{apartmentDetail.price_tttd_view}</div>
                            </Col>
                            <Col span={6}>
                                <div className={cx("detail-text")}>Vay 80%</div>
                                <div level={4} className={cx("detail-title")}>{apartmentDetail.price_loan_80_view}</div>
                            </Col>
                            <Col span={6}>
                                <div className={cx("detail-text")}>Vay 50%</div>
                                <div level={4} className={cx("detail-title")}>{apartmentDetail.price_loan_50_view}</div>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} justify="center" className={cx("csbh-content")}>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <div className={cx("csbh-wrapper")}>
                                    <Text className={cx("sub-text")}>CSBH áp dụng T6/2024</Text>
                                </div>
                                <Title level={5} className={cx("ptg-chuan")}>PTG CHUẨN</Title>
                                <Text className={cx("warning-text")}>Giá & Phiếu tính giá có thể bị lệch so với thực tế. Vui lòng check lại admin khi tư vấn.</Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={24} style={{ textAlign: 'center', paddingBottom: '10px' }}>
                                <Button
                                    icon={<LockOutlined />}
                                    className={cx("custom-button")}
                                    onClick={() => window.open(apartmentDetail.lock_apartment, '_blank')}
                                >
                                    Lock căn
                                </Button>

                            </Col>
                        </Row>
                    </div>
                    <Row gutter={[16, 16]} className={cx("apartment-info")}>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Loại căn:</div>
                            <div level={5} className={cx("apartment-info-value")}>{apartment.type}</div>
                        </Col>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Hướng</div>
                            <div level={5} className={cx("apartment-info-value")}>{apartment.orientation}</div>
                        </Col>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Thông thủy</div>
                            <div level={5} className={cx("apartment-info-value")}>{apartment.area_net} m2</div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className={cx("apartment-info")}>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Tình trạng</div>
                            <div level={5} className={cx("apartment-info-value")}>{statusNames[apartmentDetail.status]}</div>
                        </Col>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Loại hình</div>
                            <div level={5} className={cx("apartment-info-value")}>Mới CĐT</div>
                        </Col>
                        <Col span={8}>
                            <div className={cx("apartment-info-text")}><PlusCircleOutlined /> Giá/m2</div>
                            <div level={5} className={cx("apartment-info-value")}>{apartmentDetail.price_per_sqm_view}</div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className={cx("apartment-detail-layout")}>
                        <Row>
                            <Col className={cx("title")} span={24}>Layout chi tiết căn hộ</Col>
                        </Row>
                        <Row>
                            <Col span={24} className={cx("content")}>
                                <Image src={apartmentDetail.layout_image} />
                            </Col>
                        </Row>
                    </div>

                    <div className={cx("apartment-detail-layout")}>
                        <Row>
                            <Col className={cx("title")} span={24}>Giới thiệu chi tiết căn hộ</Col>
                        </Row>
                        <Row>
                            <Col span={24} className={cx("content")}>
                                <div className={cx("apartment-description")} dangerouslySetInnerHTML={{ __html: apartmentDetail.description }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={4}>
                </Col>
            </Row>

        </Spin>
    );
};

export default ApartmentDetail;
