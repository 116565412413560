import {
  ADMIN_PATH,
  ADMIN_USER_PATH,
  ADMIN_USER_NEW_PATH,
  ADMIN_SETTING_PATH,
  ADMIN_PROJECT_PATH,
  ADMIN_BUILDING_PATH,
  ADMIN_APARTMENT_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_NEW_PATH,
  ADMIN_POST_CATEGORY_PATH,
  ADMIN_M_STATUR_COLOR_PATH,
  ADMIN_SALES_POLICY_PATH,
  ADMIN_RENT_POST,
} from "./authPath";
import {
  PieChartOutlined,
  UserOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  ProjectOutlined,
  HomeOutlined,
  ReadOutlined,
  BorderOutlined,
  DollarOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";

const menuItems = [
  {
    key: "dashboard",
    icon: <PieChartOutlined />,
    label: "Dashboard",
    path: `${ADMIN_PATH}`,
  },
  {
    key: "project",
    label: "Dự án",
    icon: <ProjectOutlined />,
    children: [
      {
        key: "all_project",
        label: "Danh sách dự án",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_PROJECT_PATH}`,
      },
      {
        key: "all_building",
        label: "Danh sách tòa",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_BUILDING_PATH}`,
      },
      {
        key: "all_apartment",
        label: "Căn hộ",
        icon: <HomeOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_APARTMENT_PATH}`,
      },
      {
        key: "all_rent_post",
        label: "Cho thuê",
        icon: <ShoppingOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_RENT_POST}`,
      },
      {
        key: "all_sales_policy",
        label: "CS bán hàng",
        icon: <DollarOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_SALES_POLICY_PATH}`,
      },
    ],
  },
  {
    key: "post",
    label: "Tin tức",
    icon: <ReadOutlined />,
    children: [
      {
        key: "all_post",
        label: "D/S tin tức",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_POST_PATH}`,
      },
      {
        key: "new_post",
        label: "Tạo mới tin tức",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_POST_NEW_PATH}`,
      },
    ],
  },
  {
    key: "all_category_post",
    label: "Danh mục",
    icon: <BorderOutlined />,
    path: `${ADMIN_PATH}/${ADMIN_POST_CATEGORY_PATH}`,
  },
  {
    key: "user_manager",
    label: "User Manager",
    icon: <UserOutlined />,
    children: [
      {
        key: "all_user",
        label: "All User",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_USER_PATH}`,
      },
      {
        key: "add_new_user",
        label: "Add new user",
        icon: <UserAddOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_USER_NEW_PATH}/`,
      },
    ],
  },
  {
    key: "setting_config",
    label: "Setup & Configurations",
    icon: <SettingOutlined />,
    children: [
      {
        key: "general_configuration",
        label: "General configuration",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_SETTING_PATH}`,
      },
    ],
  },
  {
    key: "setting_config_data_master",
    label: "Data master",
    icon: <SettingOutlined />,
    children: [
      {
        key: "general_m_status_color",
        label: "Trạng thái",
        icon: <UnorderedListOutlined />,
        path: `${ADMIN_PATH}/${ADMIN_M_STATUR_COLOR_PATH}`,
      },
    ],
  },
];
export { menuItems };
