import React, { useState, useEffect } from "react";
import { Button, Layout, Row, Col, Table, Pagination, Modal, message } from "antd";
import { getListAllSettings, deleteSetting } from "api/admin/setting.api";
import { useNavigate } from "react-router-dom";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import moment from "moment";
import { ADMIN_PATH, ADMIN_SETTING_NEW_PATH, ADMIN_SETTING_EDIT_URL } from "constant/authPath";

const { Content } = Layout;
const { confirm } = Modal;

const AdminSettings = () => {
    const [listData, setListData] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [perPage, setPerPage] = useState(PAGE_SIZE);
    const [itemToDelete, setItemToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getListAllSettings(perPage, page);
            setListData(response);
        };

        fetchData();
    }, [page, perPage]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const getListData = () => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        return listData.slice(startIndex, endIndex);
    };

    const handleDelete = (id) => {
        confirm({
            title: 'Confirm deletion',
            content: 'Are you sure you want to delete?',
            onOk: async () => {
                try {
                    await deleteSetting(id);
                    message.success('Thực hiện thành công');
                    setListData(listData.filter(item => item.id !== id));
                } catch (error) {
                    message.error('Thực hiện xóa thất bại và có lỗi: ' + error.message);
                }
            },
            onCancel() {
                setItemToDelete(null); // Đặt lại itemToDelete về null
            },
        });
    };

    const handleEdit = (id) => {
        navigate(`${ADMIN_PATH}/${ADMIN_SETTING_EDIT_URL}/${id}`);
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Setting key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Set value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'), // Định dạng ngày tháng
        },
        {
            title: 'Option',
            key: 'option',
            render: (text, record) => (
                <>
                    <Button type="primary" danger onClick={() => handleDelete(record.id)}>Delete</Button>
                    <Button type="primary" style={{ marginLeft: 8 }} onClick={() => handleEdit(record.id)}>Edit</Button>
                </>
            ),
        },
    ];

    return (
        <Layout>
            <Content style={{ padding: '0 50px' }}>
                <Row gutter={16} style={{ marginBottom: 16 }}>
                    <Col span={12}>
                        <h2>List of website settings</h2>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button type="primary" size="large" onClick={() => navigate(`${ADMIN_PATH}/${ADMIN_SETTING_NEW_PATH}`)} style={{ marginTop: 15 }}>
                            Add new
                        </Button>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={getListData()}
                    pagination={false}
                    rowKey="id"
                />
                <Pagination
                    current={page}
                    pageSize={perPage}
                    total={listData.length}
                    onChange={handlePageChange}
                    style={{ marginTop: 16, textAlign: 'center' }}
                />
            </Content>
        </Layout>
    );
};

export default AdminSettings;
