import React from "react";
import { Outlet } from "react-router-dom";
import GlobalAppStyle from "components/GlobalStyles";
import Header from "layouts/components/AppHeader";
import Footer from "layouts/components/Footer";
import classNames from "classnames/bind";
import styles from "layouts/DefaultLayout/DefaultLayout.module.scss";
import MenuAccountLeft from "./MenuAccountLeft";
const cx = classNames.bind(styles);

const User = () => {
  return (
    <div>
      <GlobalAppStyle>
        <Header />
        <section className={cx("signup", "page_customer_account", "section")}>
          <div className={cx("container", "card", "p-3")}>
            <div className={cx("row")}>
              <div
                className={cx(
                  "col-xs-12",
                  "col-sm-12",
                  "col-lg-3",
                  "col-left-ac"
                )}
              >
                <MenuAccountLeft />
              </div>
              <div
                className={cx(
                  "col-xs-12",
                  "col-sm-12",
                  "col-lg-9",
                  "col-right-ac"
                )}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </GlobalAppStyle>
    </div>
  );
};

export default User;
