import React, { useEffect, useState } from "react";
import { Table, Spin, message, Button, Layout, Row, Col, Modal } from "antd";
import {
  getListAllApartmentLowRiseByProjectId,
  addApartmentLowRise,
  updateApartmentLowRise,
  deleteApartmentLowRise,
} from "api/admin/apartmentLowRise.api";
import { getListAllStatusColor } from "api/admin/master.api";
import ModalAddLowRiseApartment from "components/Modal/ModalAddLowRiseApartment";

const { Content } = Layout;

const LowRiseApartmentList = ({ projectId }) => {
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState(null); // New state for editing
  const [statusLabels, setStatusLabels] = useState({}); // State for status labels

  const fetchApartments = async () => {
    if (projectId) {
      setLoading(true);
      try {
        const response = await getListAllApartmentLowRiseByProjectId(
          projectId,
          null
        );
        if (Array.isArray(response)) {
          setApartments(response);
        } else {
          console.error("Unexpected response for apartments:", response);
        }
      } catch (error) {
        message.error("Error fetching apartments: " + error.message);
      }
      setLoading(false);
    }
  };

  const fetchStatusLabels = async () => {
    try {
      const response = await getListAllStatusColor();
      if (Array.isArray(response)) {
        const statusMapping = response.reduce((map, status) => {
          map[status.id] = status.name; // Map status id to its name
          return map;
        }, {});
        setStatusLabels(statusMapping);
      } else {
        console.error("Unexpected response for status colors:", response);
      }
    } catch (error) {
      message.error("Error fetching status labels: " + error.message);
    }
  };

  useEffect(() => {
    fetchApartments();
    fetchStatusLabels();
  }, [projectId]);

  const handleDelete = (apartmentId) => {
    Modal.confirm({
      title: "Xác nhận xóa",
      content: "Bạn có chắc chắn muốn xóa căn hộ này?",
      okText: "Xóa",
      cancelText: "Hủy",
      onOk: async () => {
        try {
          await deleteApartmentLowRise(apartmentId); // Call API to delete the apartment
          message.success("Xóa căn hộ thành công!");
          fetchApartments(); // Reload the list after deletion
        } catch (error) {
          message.error("Failed to delete apartment: " + error.message);
        }
      },
    });
  };

  const columns = [
    {
      title: "Mã căn hộ",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Loại hình",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        const typeLabels = {
          lien_ke: "Liền kề",
          biet_thu: "Biệt thự",
          shophouse: "Shop house",
        };
        return typeLabels[type] || "N/A"; // Return the corresponding label or "N/A"
      },
    },
    {
      title: "Diện tích đất (m²)",
      dataIndex: "land_area",
      key: "land_area",
    },
    {
      title: "Giá (VNĐ)",
      dataIndex: "price",
      key: "price",
      render: (price) => new Intl.NumberFormat().format(price),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status) => statusLabels[status] || "N/A", // Use the fetched status labels
    },
    {
      title: "Ảnh layout",
      dataIndex: "layout_image",
      key: "layout_image",
      render: (layout_image) =>
        layout_image ? (
          <img src={layout_image} alt="Layout" style={{ width: 100 }} />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Options",
      key: "options",
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const showModal = () => {
    setSelectedApartment(null); // Reset selected apartment when adding new
    setIsModalVisible(true);
  };

  const handleEdit = (apartment) => {
    setSelectedApartment(apartment); // Set the selected apartment for editing
    setIsModalVisible(true);
  };

  const handleOk = async (values) => {
    setSubmitting(true);
    try {
      if (values.apartmentId) {
        // Update apartment if apartmentId is not null
        await updateApartmentLowRise(values.apartmentId, values);
        message.success("Cập nhật căn hộ thành công!");
      } else {
        // Add new apartment if apartmentId is null
        await addApartmentLowRise(values);
        message.success("Tạo mới căn hộ thành công!");
      }
      setIsModalVisible(false);
      fetchApartments(); // Reload the list after adding/editing an apartment
    } catch (error) {
      console.log("check error: ", error.message);
      message.error("Failed to add/update apartment: " + error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <h2>Danh sách biệt thự / căn hộ liền kề</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              size="large"
              style={{ marginTop: 15 }}
              onClick={showModal}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Spin spinning={loading || submitting}>
          <Table
            columns={columns}
            dataSource={apartments}
            rowKey="id"
            pagination={{ pageSize: 10 }}
          />
        </Spin>

        <ModalAddLowRiseApartment
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={submitting}
          projectId={projectId}
          apartmentId={selectedApartment?.id} // Pass selected apartment for editing
        />
      </Content>
    </Layout>
  );
};

export default LowRiseApartmentList;
