import axiosAdmin from "api/config/axiosAdmin";

/**
 * CALL API tạo mới căn hộ biệt thự/ liền kề/ shophouse
 *
 * @author DungPT
 * @param {Object} payload
 * @returns
 */
const addApartmentLowRise = (payload) => {
  const url = "/admin/project/apartment-low-rise";
  return axiosAdmin.post(url, {
    project_id: payload.projectId,
    subdivision_id: payload.subdivision_id,
    code: payload.code,
    type: payload.type,
    land_area: payload.land_area,
    construction_area: payload.construction_area,
    direction: payload.direction,
    construction_status: payload.construction_status,
    price: payload.price,
    status: payload.status,
    owner: payload.owner,
    price_per_sqm: payload.price_per_sqm,
    price_tts: payload.price_tts,
    price_tttd: payload.price_tttd,
    price_loan_80: payload.price_loan_80,
    price_loan_50: payload.price_loan_50,
    description: payload.description,
    sales_policy_applied_monthly: payload.sales_policy_applied_monthly,
    sales_policy_applied_monthly_url: payload.sales_policy_applied_monthly_url,
    lock_apartment: payload.lock_apartment,
    layout_image: payload.layout_image,
    sales_policy_id: payload.sales_policy_id,
  });
};

/**
 * Update Apartment Low Rise
 *
 * @author DungPT
 * @param {object} payload
 * @returns
 */
const updateApartmentLowRise = (id, payload) => {
  const url = `/admin/project/apartment-low-rise/${id}`;
  return axiosAdmin.put(url, {
    project_id: payload.projectId,
    subdivision_id: payload.subdivision_id,
    code: payload.code,
    type: payload.type,
    land_area: payload.land_area,
    construction_area: payload.construction_area,
    direction: payload.direction,
    construction_status: payload.construction_status,
    price: payload.price,
    status: payload.status,
    owner: payload.owner,
    price_per_sqm: payload.price_per_sqm,
    price_tts: payload.price_tts,
    price_tttd: payload.price_tttd,
    price_loan_80: payload.price_loan_80,
    price_loan_50: payload.price_loan_50,
    description: payload.description,
    sales_policy_applied_monthly: payload.sales_policy_applied_monthly,
    sales_policy_applied_monthly_url: payload.sales_policy_applied_monthly_url,
    lock_apartment: payload.lock_apartment,
    layout_image: payload.layout_image,
    sales_policy_id: payload.sales_policy_id,
  });
};

const deleteApartmentLowRise = (id) => {
  const url = `/admin/project/apartment-low-rise/${id}`;
  return axiosAdmin.delete(url);
};

const getListAllApartmentLowRiseByProjectId = (project_id, subdivision_id) => {
  const url = "/admin/project/apartment-low-rise";
  return axiosAdmin.get(url, {
    params: {
      project_id,
      subdivision_id,
    },
  });
};

const getApartmentLowRiseDetailById = (id) => {
  const url = `/admin/project/apartment-low-rise/${id}`;
  return axiosAdmin.get(url);
};

export {
  addApartmentLowRise,
  getListAllApartmentLowRiseByProjectId,
  getApartmentLowRiseDetailById,
  updateApartmentLowRise,
  deleteApartmentLowRise,
};
