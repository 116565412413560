import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { getNameProjectByBuildingId } from 'api/app/project.api'; // Import your API function

const ProjectBreadcrumb = ({ building_id, building_name }) => {
    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        const fetchProjectName = async () => {
            try {
                const response = await getNameProjectByBuildingId(building_id);
                setProjectName(response.projectName);
            } catch (error) {
                console.error('Error fetching project name:', error);
            }
        };

        if (building_id) {
            fetchProjectName();
        }
    }, [building_id]);

    return (
        <Breadcrumb
            items={[
                {
                    title: projectName || 'Loading...',
                },
                {
                    title: building_name,
                },
            ]}
            style={{ marginBottom: '20px', marginTop: '20px', fontSize: '1.075rem', fontWeight: 600 }}
        />
    );
};

export default ProjectBreadcrumb;
