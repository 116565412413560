import React, { useState, useContext } from "react";
import classNames from "classnames/bind";
import styles from "page/Auth/Auth.module.scss";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { login } from "api/auth/authen.api";
import { useRecoilState } from "recoil";
import { authenticationToken } from "recoil/atom/auth";
import { SIGN_UP_PATH, HOMEPAGE, AUTH_PATH } from "constant/appPath";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { UserContext } from "context/UserContext";
import {
  Layout,
  Input,
  Button,
  Space,
  Typography,
  Divider,
  Image,
  Row,
  Col,
  message,
} from "antd";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import logo from "assets/logo/logo192.png";

const cx = classNames.bind(styles);

const { Content } = Layout;

const urlSignUp = `${AUTH_PATH}/${SIGN_UP_PATH}`;

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setToken] = useRecoilState(authenticationToken);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const { loginWithEmail } = useContext(UserContext);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      try {
        const response = await login(email, password);
        console.log("checklog in email: ", response);
        if (response.status_code === "LOGIN_OK") {
          // Xử lý thành công
          loginWithEmail(response.user, response.access_token)
          message.success("Đăng nhập thành công!");
          navigate(HOMEPAGE);
        } else {
          message.error("Đăng nhập thất bại, do lỗi: " + response.message);
        }

      } catch (error) {
        message.error(error.response.data.message);
      }
    } else {
      message.error("Please fix the errors in the form");
    }
  };

  const auth = getAuth();

  const handleLoginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    const user = await signInWithPopup(auth, provider);

    console.log("register", { user });
  };

  if (localStorage.getItem("accessToken")) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <div className={cx("header")}>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
            <div className={cx("logo")}>
              <Image src={logo} alt="Logo City" />
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
      <Content className={cx("content")}>
        <div className={cx("hero")}>
          <Typography.Title level={5}>
            Hệ thống hỗ trợ dành cho Môi giới bất động sản MDLand
          </Typography.Title>
          <Space direction="vertical" size="small" align="start">
            <Button type="text" icon={<CheckOutlined />}>
              Tìm kiếm căn hộ
            </Button>
            <Button type="text" icon={<CheckOutlined />}>
              Cập nhật tình trạng căn hộ
            </Button>
            <Button type="text" icon={<CheckOutlined />}>
              Tài nguyên thông tin layout, mặt bằng...
            </Button>
          </Space>
        </div>
        <Divider>Đăng nhập với tài khoản</Divider>
        {/* <Button
          type="primary"
          danger
          size="large"
          icon={<GoogleOutlined />}
          onClick={handleLoginWithGoogle}
        >
          Đăng nhập bằng tài khoản Google+
        </Button>
        <Divider>Hoặc</Divider> */}
        <div className={cx("login")}>
          <div>
            <Typography.Title level={5}>Email</Typography.Title>
            <Input
              placeholder="Nhập Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              size="middle"
            />
            {errors.email && (
              <Typography.Text type="danger">{errors.email}</Typography.Text>
            )}
          </div>
          <div>
            <Typography.Title level={5}>Password</Typography.Title>
            <Input.Password
              placeholder="Nhập Mật khẩu"
              size="middle"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {errors.password && (
              <Typography.Text type="danger">{errors.password}</Typography.Text>
            )}
          </div>
          <div>
            <Button type="primary" size="large" onClick={handleSubmit} style={{ marginTop: '10px', marginBottom: '10px' }}>
              Đăng nhập
            </Button>
          </div>
        </div>
        <Typography.Paragraph>
          Bạn chưa có tài khoản? <Link to={urlSignUp}>Đăng ký</Link>
        </Typography.Paragraph>
      </Content>
    </Layout>
  );
};

export default SignIn;
