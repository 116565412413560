import React, { useState, useEffect, useCallback } from "react";
import { Table, Spin, Button } from "antd";
import { UndoOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import {
  getListAllApartmentDetailByProjectId,
  getListAllApartmentByProjectId,
  getBuildingDetail,
} from "api/app/project.api";
import { getListAllStatusColor } from "api/app/master.api";
import classNames from "classnames/bind";
import styles from "./ProjectTable.module.scss";
import ApartmentInfoModal from "components/Modal/ApartmentInfoModal";

const cx = classNames.bind(styles);

const ProjectTable = ({ building }) => {
  const [data, setData] = useState([]);
  const [dataApartment, setDataApartment] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [buildingDetail, setBuildingDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentApartment, setCurrentApartment] = useState(null);
  const [statusColors, setStatusColors] = useState({});
  const [status, setStatus] = useState([]);
  const [salesPolicyName, setSalesPolicyName] = useState(null);
  const [salesPolicyUrl, setSalesPolicyUrl] = useState(null);

  const fetchData = useCallback(async () => {
    if (building) {
      setLoadingTable(true);
      try {
        const responseBuilding = await getBuildingDetail(building);
        if (responseBuilding.sales_policy) {
          setSalesPolicyName(responseBuilding.sales_policy.sales_policy_name);
          setSalesPolicyUrl(responseBuilding.sales_policy.document_link);
        }
        setBuildingDetail(responseBuilding);

        const responseApartment =
          await getListAllApartmentByProjectId(building);
        setDataApartment(responseApartment || []);

        const response = await getListAllApartmentDetailByProjectId(building);
        if (Array.isArray(response)) {
          const floors = response.filter(
            (floor) => floor.apartments && floor.apartments.length > 0
          );
          setData(floors || []);
        } else {
          console.error(
            "Phản hồi không mong đợi từ danh sách căn hộ:",
            response
          );
        }
        setLoadingTable(false);
      } catch (error) {
        console.error("Lỗi khi tải dữ liệu:", error);
        setLoadingTable(false);
      }
    } else {
      setData([]);
    }
  }, [building]);

  const fetchStatusColors = useCallback(async () => {
    try {
      const response = await getListAllStatusColor();
      const colors = response.reduce((acc, color) => {
        acc[color.id] = color.color;
        return acc;
      }, {});
      console.log("check màu: ", colors);
      console.log("check status: ", response);
      setStatusColors(colors);
      setStatus(response);
    } catch (error) {
      console.error("Error fetching status colors:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchStatusColors();
  }, [fetchData, fetchStatusColors]);

  const showModal = (apartment) => {
    setCurrentApartment(apartment);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const numApartments = data[0]?.apartments?.length || 1;
  const columnWidth = `${(100 - 5) / (numApartments + 1)}%`;

  const columns = [
    {
      title: "Trục căn",
      dataIndex: "floor",
      key: "floor",
      render: (floor, record) => (
        <div className={cx("first-column-cell", record.customClass)}>
          <div className={cx("first-column-cell-text")}>{floor}</div>
        </div>
      ),
      fixed: "left",
      className: cx("first-column"),
    },
    ...(data[0]?.apartments?.map(
      (apartment) =>
        apartment && {
          title: `${apartment.apartment_number}`,
          dataIndex: `apartment_${apartment.apartment_number}`,
          key: `apartment_${apartment.apartment_number}`,
          width: columnWidth,
          render: (_, record) => {
            const apartmentData = record.apartments?.find(
              (a) => a && a.apartment_number === apartment.apartment_number
            );
            const status = apartmentData ? apartmentData.status : null;
            let color = statusColors[status];
            if (apartmentData.flg) {
              color = apartmentData.color;
            }
            let text = "";
            if (apartmentData && apartmentData.flg && apartmentData.name) {
              text = apartmentData.name;
            } else if (
              apartmentData &&
              apartmentData.flg &&
              apartmentData.area_gross
            ) {
              text = apartmentData.area_gross;
            } else if (
              apartmentData &&
              apartmentData.flg &&
              apartmentData.area_net
            ) {
              text = apartmentData.area_net;
            } else if (
              apartmentData &&
              apartmentData.flg &&
              apartmentData.orientation
            ) {
              text = apartmentData.orientation;
            } else if (
              apartmentData &&
              apartmentData.flg &&
              apartmentData.type
            ) {
              text = apartmentData.type;
            } else if (
              apartmentData &&
              apartmentData.flg &&
              apartmentData.view
            ) {
              text = apartmentData.view;
            } else {
              text =
                apartmentData &&
                apartmentData.price &&
                status !== 1 &&
                status !== 5
                  ? (apartmentData.price / 1000000).toLocaleString()
                  : "-";
            }

            return apartmentData ? (
              <div
                className={cx("cell-content")}
                onClick={
                  status === 4 ? () => showModal(apartmentData) : undefined
                }
                style={{
                  background: color,
                  cursor: status === 4 ? "pointer" : "default",
                }}
              >
                {text}
              </div>
            ) : (
              <></>
            );
          },
        }
    ) || []),
  ];

  const tableData = [
    ...dataApartment.map((floor) => {
      const row = {
        key: `apartment_${floor.floor_id}`,
        floor: `${floor.floor_number}`,
        apartments: floor.apartments || [],
        customClass: floor.customClass,
      };
      (floor.apartments || []).forEach((apartment) => {
        if (apartment) {
          row[`apartment_${apartment.apartment_number}`] = apartment;
        }
      });
      return row;
    }),
    ...data.map((floor) => {
      const row = {
        key: floor.floor_id,
        floor: `${floor.floor_number}`,
        apartments: floor.apartments || [],
        customClass: floor.customClass,
      };
      (floor.apartments || []).forEach((apartment) => {
        if (apartment) {
          row[`apartment_${apartment.apartment_number}`] = apartment;
        }
      });
      return row;
    }),
  ];

  const columnsHeader = [
    {
      title: "",
      dataIndex: "unit",
      key: "unit",
      width: "5%",
      align: "center",
      render: (unit) => (
        <div
          style={{
            textAlign: "center",
            color: "#566a7f",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {unit}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "65%",
      render: (text) => (
        <div
          style={{
            textAlign: "center",
            color: "#566a7f",
            fontSize: 11,
            fontWeight: 600,
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "text",
      dataIndex: "text",
      key: "text",
      width: "30%",
      render: (text) => {
        return (
          <div
            style={{
              textAlign: "center",
              color: "#566a7f",
              fontSize: 11,
              fontWeight: 600,
            }}
          >
            {text}
          </div>
        );
      },
    },
  ];

  const dataHeader = [
    {
      key: "legend",
      unit: buildingDetail?.code || "",
      status: (
        <div>
          {status.map((value) => (
            <span
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginRight: 8,
              }}
              key={value.id}
            >
              <span
                style={{
                  display: "inline-block",
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: value.color,
                  marginRight: 4,
                }}
              ></span>
              {value.name.toUpperCase()}
            </span>
          ))}
        </div>
      ),
      text: "NOTE: GIÁ ĐÃ BAO GỒM VAT & KPBT",
    },
  ];

  return (
    <div className={cx("table-container")}>
      {loadingTable ? (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {data.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              {`Tòa ${buildingDetail?.name} chưa có thông tin căn hộ`}
            </div>
          ) : (
            <>
              <Table
                columns={columnsHeader}
                dataSource={dataHeader}
                pagination={false}
                bordered
                showHeader={false}
                rowClassName={(record, index) => cx("first-row")}
              />
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: "max-content" }}
                bordered
                showHeader={false}
                rowClassName={(record, index) => cx("first-row")}
              />
              {currentApartment && (
                <ApartmentInfoModal
                  visible={isModalVisible}
                  apartmentId={currentApartment.detail_id}
                  onOk={handleOk}
                  onCancel={handleCancel}
                />
              )}
            </>
          )}
          <div className={cx("building-button")}>
            <Button
              className={cx("building-button-item")}
              onClick={() => window.open(salesPolicyUrl, "_blank")}
            >
              <UndoOutlined />
              Xem sa bàn ảo 360
            </Button>
            <Button onClick={() => window.open(salesPolicyUrl, "_blank")}>
              <SafetyCertificateOutlined />
              {salesPolicyName}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectTable;
