import React from "react";
import { Outlet } from "react-router-dom";
import { AdminProvider } from "context/AdminContext";

const AdminProviderWrapper = () => {
  return (
    <AdminProvider>
      <Outlet />
    </AdminProvider>
  );
};

export default AdminProviderWrapper;
