import React, { useState, useContext, useEffect } from "react";
import { Layout, Avatar, Dropdown, Menu, Button, Typography, Badge } from "antd";
import { UserOutlined, BellOutlined, UserOutlined as UserIcon, LogoutOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { ADMIN_PATH_LOGIN } from "constant/authPath";
import { AdminContext } from "context/AdminContext";

const { Header } = Layout;
const { Text } = Typography;

const AdminHeader = ({ colorBgContainer }) => {
  const { logout } = useContext(AdminContext);
  const [user, setUser] = useState(null);
  const [notifications] = useState([
    "Notification 1",
    "Notification 2",
    "Notification 3",
    "Notification 4",
    "Notification 5",
  ]); // Replace with actual notifications

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');
    const adminData = JSON.parse(localStorage.getItem('adminData'));

    if (adminToken && adminData) {
      setUser(adminData);
    }
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate(ADMIN_PATH_LOGIN);
  };

  const notificationMenu = (
    <Menu>
      {notifications.slice(0, 3).map((notification, index) => (
        <Menu.Item key={index}>{notification}</Menu.Item>
      ))}
      {notifications.length > 3 && (
        <Menu.Item key="viewAll">
          <Button type="text" onClick={() => navigate("/notifications")}>
            View All
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <UserIcon /><Link to="#"> Hồ sơ cá nhân</Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined /><span onClick={handleLogout}> Đăng xuất</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 15 }}>
      <Dropdown overlay={notificationMenu} trigger={['click']}>
        <Badge count={notifications.length} offset={[10, 0]} style={{ marginRight: 20 }}>
          <BellOutlined style={{ fontSize: 24, cursor: 'pointer' }} />
        </Badge>
      </Dropdown>
      <Dropdown overlay={userMenu} trigger={['click']}>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Text style={{ marginLeft: 10, marginRight: 10 }}>Xin chào: {user?.last_name} {user?.first_name}</Text>
          <Avatar
            style={{ cursor: 'pointer' }}
            icon={<UserOutlined />}
            src={user?.avatar} // Assuming userAdmin has an avatar field
          />
        </div>
      </Dropdown>
    </Header>
  );
};

export default AdminHeader;
