import React, { useState } from "react";
import { Modal, Form, Input, message, ColorPicker } from "antd";
import { addNewStatusColor } from "api/admin/master.api";

const ModalAddMStatusColor = ({ isModalVisible, handleModalCancel, handleAdded }) => {
    const [newName, setNewName] = useState("");
    const [newColor, setNewColor] = useState("#ffffff"); // Default to white

    const handleModalOk = async () => {
        try {
            const response = await addNewStatusColor(newName, newColor); // Directly pass the hex string
            const newData = response.data;

            setNewName("");
            setNewColor("#ffffff"); // Reset to default
            message.success('Thêm mới thành công');
            console.log("Check data insert OK: ", newData);
            handleAdded(newData);

            handleModalCancel(); // Close the modal after success
        } catch (error) {
            message.error('Lỗi khi thêm danh mục mới: ' + error.message);
        }
    };

    return (
        <Modal
            title="Thêm mới trạng thái"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
        >
            <Form layout="vertical">
                <Form.Item label="Tên trạng thái" required>
                    <Input value={newName} onChange={(e) => setNewName(e.target.value)} />
                </Form.Item>
                <Form.Item label="Chọn màu" required>
                    <ColorPicker
                        value={newColor}
                        onChange={(value) => setNewColor(value.toHexString())} // Directly store the hex string
                        showText
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddMStatusColor;
