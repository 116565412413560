import React from "react";

import classNames from "classnames/bind";
import styles from "page/Application/New/New.module.scss";
const cx = classNames.bind(styles);

const New = () => {
    return (
        <>Tin tức</>
    );
}

export default New;