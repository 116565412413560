import React, { useEffect, useState } from "react";
import { Form, Slider, Row, Col, Select } from "antd";
import { getListAllProjectByType, getSubdivisionsByProjectId } from "api/app/project.api";
import { ROOM_TYPE, BALCONY_DIRECTION } from "constant/project";
import classNames from "classnames/bind";
import styles from "./FilterFormRentPost.module.scss";

const cx = classNames.bind(styles);
const { Option } = Select;

const FilterFormRentPost = ({ filters, handleFilterChange }) => {
  const [projects, setProjects] = useState([]);  // Initialize projects state
  const [subdivisions, setSubdivisions] = useState([]);  // Initialize subdivisions state
  const [loadingSubdivisions, setLoadingSubdivisions] = useState(false);

  // Fetch projects data on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getListAllProjectByType({ project_type: null });  // Call the API to get projects
        setProjects(response);  // Set the projects in state
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  // Fetch subdivisions based on the selected project
  useEffect(() => {
    if (filters.project_id) {
      const fetchSubdivisions = async () => {
        setLoadingSubdivisions(true);
        try {
          const response = await getSubdivisionsByProjectId(filters.project_id);  // Call the API for subdivisions
          setSubdivisions(response);  // Set the subdivisions in state
        } catch (error) {
          console.error("Error fetching subdivisions:", error);
        } finally {
          setLoadingSubdivisions(false);
        }
      };

      fetchSubdivisions();
    } else {
      setSubdivisions([]);  // Clear subdivisions if no project is selected
    }
  }, [filters.project_id]);

  return (
    <Form layout="vertical" className={cx("filter-form")}>
      <Row gutter={16}>
        {/* Pulldown chọn dự án */}
        {/* <Col span={6}>
          <Form.Item
            label="Chọn dự án"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={filters.project_id || undefined}
              onChange={(value) => handleFilterChange("project_id", value)}
              placeholder="Chọn dự án"
              loading={projects.length === 0}  // Show loading when fetching projects
            >
              {projects.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.project_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}

        {/* Pulldown chọn phân khu */}
        {/* <Col span={6}>
          <Form.Item
            label="Chọn phân khu"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={filters.subdivision_id || undefined}
              onChange={(value) => handleFilterChange("subdivision_id", value)}
              placeholder="Chọn phân khu"
              disabled={!filters.project_id}  // Disable if no project is selected
              loading={loadingSubdivisions}  // Show loading when fetching subdivisions
            >
              {subdivisions.map((subdivision) => (
                <Option key={subdivision.id} value={subdivision.id}>
                  {subdivision.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}

        {/* Pulldown chọn loại phòng */}
        <Col span={6}>
          <Form.Item
            label="Loại phòng"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={filters.room_type || null}
              onChange={(value) => handleFilterChange("room_type", value)}
              placeholder="Chọn loại phòng"
            >
              <Option value={null}>Tất cả</Option>
              {ROOM_TYPE.map((data) => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Pulldown hướng ban công */}
        <Col span={6}>
          <Form.Item
            label="Hướng ban công"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={filters.balcony_direction || null}
              onChange={(value) => handleFilterChange("balcony_direction", value)}
              placeholder="Chọn hướng ban công"
            >
              <Option value={null}>Tất cả</Option>
              {BALCONY_DIRECTION.map((data) => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Pulldown khoảng tầng */}
        {/* <Col span={6}>
          <Form.Item
            label="Khoảng tầng"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              range
              min={1}
              max={50}
              step={1}
              value={filters.floorRange || [1, 50]}
              onChange={(value) => handleFilterChange("floorRange", value)}
              tipFormatter={(value) => `Tầng ${value}`}
            />
          </Form.Item>
        </Col> */}

        {/* Pulldown khoảng giá */}
        <Col span={6}>
          <Form.Item
            label="Khoảng giá (triệu)"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              range
              min={0}
              max={50}
              step={1}
              value={filters.priceRange || [0, 20]}
              onChange={(value) => handleFilterChange("priceRange", value)}
              tipFormatter={(value) => `${value} triệu`}
            />
          </Form.Item>
        </Col>

        {/* Pulldown khoảng diện tích */}
        <Col span={6}>
          <Form.Item
            label="Khoảng diện tích (m²)"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              range
              min={0}
              max={500}
              step={10}
              value={filters.areaRange || [0, 500]}
              onChange={(value) => handleFilterChange("areaRange", value)}
              tipFormatter={(value) => `${value} m²`}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterFormRentPost;
