import React, { useState, useEffect } from "react";
import { Form, Select, Slider, Row, Col } from "antd";
import {
  getListAllBuilding,
  getSubdivisionsByProjectId,
} from "api/app/project.api";
import classNames from "classnames/bind";
import styles from "./FilterForm.module.scss";

const cx = classNames.bind(styles);
const { Option } = Select;

const FilterForm = ({ filters, handleFilterChange, selectedProject }) => {
  const [blockOptions, setBlockOptions] = useState([]);

  // Fetch block options based on selected project
  useEffect(() => {
    const fetchBlockOptions = async () => {
      if (!selectedProject) return;

      try {
        const response = await getSubdivisionsByProjectId(selectedProject);
        if (response && Array.isArray(response)) {
          const options = response.map((block) => ({
            label: block.name,
            value: block.id,
          }));
          setBlockOptions(options);
        } else {
          console.error("Unexpected response format for subdivisions:", response);
        }
      } catch (error) {
        console.error("Failed to fetch block options:", error.message || error);
      }
    };

    fetchBlockOptions();
  }, [selectedProject]);

  const balconyDirectionOptions = [
    { label: "ĐN", value: "ĐN" },
    { label: "TN", value: "TN" },
    { label: "ĐB", value: "ĐB" },
    { label: "TB", value: "TB" },
    { label: "ĐB-TB", value: "ĐB-TB" },
    { label: "ĐN-TN", value: "ĐN-TN" },
  ];

  const typeOptions = [
    { label: "Biệt thự", value: "biet_thu" },
    { label: "Liền kề", value: "lien_ke" },
    { label: "Shop house", value: "shophouse" },
  ];

  return (
    <Form layout="vertical" className={cx("filter-form")}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            label="Phân khu/Block"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              mode="multiple"
              name="subdivision_id"
              value={filters.subdivision_id || []}
              onChange={(value) =>
                handleFilterChange({
                  target: { name: "subdivision_id", value },
                })
              }
              style={{ width: "100%" }}
            >
              {blockOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Loại hình"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              mode="multiple"
              name="type"
              value={filters.type || []}
              onChange={(value) =>
                handleFilterChange({ target: { name: "type", value } })
              }
              style={{ width: "100%" }}
            >
              {typeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Hướng nhà"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              mode="multiple"
              name="direction"
              value={filters.direction || []}
              onChange={(value) =>
                handleFilterChange({ target: { name: "direction", value } })
              }
              style={{ width: "100%" }}
            >
              {balconyDirectionOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Diện tích đất (m²)"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              range
              min={0}
              max={1000} // Khoảng diện tích
              step={10}
              value={filters.landAreaRange || [0, 1000]} // Giá trị mặc định
              onChange={(value) =>
                handleFilterChange({ target: { name: "landAreaRange", value } })
              }
              tipFormatter={(value) => `${value} m²`} // Hiển thị đơn vị m²
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item
            label="Khoảng giá (tỷ)"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Slider
              range
              min={0}
              max={1000} // Khoảng giá
              step={5}
              value={filters.priceRange || [0, 20]} // Giá trị mặc định
              onChange={(value) =>
                handleFilterChange({ target: { name: "priceRange", value } })
              }
              tipFormatter={(value) => `${value} tỷ`} // Hiển thị đơn vị tỷ
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterForm;
