import axiosAdmin from "api/config/axiosAdmin";

const getListAllProject = (perPage, page, searchName, searchType) => {
  const url = "admin/projects";
  return axiosAdmin.get(url, {
    params: {
      perPage,
      page,
      project_name: searchName,
      project_type: searchType,
    },
  });
};

const getListProjectPulldown = () => {
  const url = "admin/projects/all";
  return axiosAdmin.get(url);
};

const addNewProject = (payloadData) => {
  const url = "admin/projects";
  return axiosAdmin.post(url, { payloadData });
};

const deleteProject = (id) => {
  const url = `/admin/projects/${id}`;
  return axiosAdmin.delete(url);
};

const getProjectById = (id) => {
  const url = `/admin/projects/${id}`;
  return axiosAdmin.get(url);
};

const updateProjectById = (id, payloadData) => {
  const url = `/admin/projects/${id}`;
  return axiosAdmin.put(url, { payloadData });
};

const getProjects = (data) => {
  const url = "/admin/projects/list-by-project-type";
  return axiosAdmin.get(url, {
    params: {
      project_type: data.project_type,
    },
  });
};

const getSubdivisions = (project_id) => {
  const url = "admin/subdivision/list-subdivision-by-project";
  return axiosAdmin.get(url, {
    params: {
      project_id,
    },
  });
};

const addSubdivision = (name, project_id, description) => {
  const url = "admin/subdivision";
  return axiosAdmin.post(url, { name, project_id, description });
};

const deleteSubdivision = (id) => {
  const url = `admin/subdivision/${id}`;
  return axiosAdmin.delete(url);
};

const updateSubdivision = (id, name, description) => {
  const url = `admin/subdivision/${id}`;
  return axiosAdmin.put(url, { name, description });
};

export {
  updateProjectById,
  getProjectById,
  deleteProject,
  addNewProject,
  getListAllProject,
  getProjects,
  getSubdivisions,
  addSubdivision,
  deleteSubdivision,
  updateSubdivision,
  getListProjectPulldown,
};
