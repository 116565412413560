import axiosClient from "api/config/axiosClient";

// API cho phần danh mục
const getListAllCategory = () => {
  const url = "app/category";
  return axiosClient.get(url);
};

const getCategoryByType = (category_type) => {
  const url = "app/category/list-category-by-type";
  return axiosClient.get(url, {
    params: {
      category_type: category_type,
    },
  });
};

const getPostsByCategoryId = (category_id) => {
  const url = `app/posts/get-posts-by-category-id/${category_id}`;
  return axiosClient.get(url);
};

const getPostDetail = (post_id) => {
  const url = `app/posts/get-posts-by-id/${post_id}`;
  return axiosClient.get(url);
};

const getArticlesHomePage = () => {
  const url = "app/posts/get-posts-homepage";
  return axiosClient.get(url);
}

export {
  getListAllCategory,
  getCategoryByType,
  getPostsByCategoryId,
  getPostDetail,
  getArticlesHomePage
};
