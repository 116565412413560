// src/components/ArticleCarousel.js
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Carousel, Spin } from 'antd';
import classNames from 'classnames/bind';
import styles from './ArticleCarousel.module.scss'; // Adjust path as needed
import { getArticlesHomePage } from 'api/app/category.api'; // Adjust import path as needed

const cx = classNames.bind(styles);

const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

const ArticleCarousel = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const articleList = await getArticlesHomePage();
                console.log("list bài viết trang chủ: ", articleList);
                setArticles(articleList);
            } catch (error) {
                console.error('Error fetching articles:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);

    // Define how many articles per slide
    const articlesPerSlide = 3;
    const slides = chunkArray(articles, articlesPerSlide);

    return (
        <div>
            {loading ? (
                <Spin size="large" />
            ) : (
                <Carousel autoplay>
                    {slides.map((slide, slideIndex) => (
                        <div key={slideIndex} className={cx('carousel-slide')}>
                            <div className={cx('slide-content')}>
                                {slide.map((article, index) => (
                                    <div key={index} className={cx('article-item')}>
                                        <Link to={`/new/${article.id}`}>
                                            <img src={article.image_url} alt={article.title} className={cx('article-image')} />
                                            <h3>{article.title}</h3>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    );
};

export default ArticleCarousel;
