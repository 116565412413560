import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Upload,
  message,
  Button,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getListAllStatusColor } from "api/admin/master.api";
import { getListAllSalesPolicy } from "api/admin/salesPolicy.api";
import { UploadOutlined } from "@ant-design/icons";
import MyCustomUploadAdapter from "common/MyCustomUploadAdapter";
import { getSubdivisions } from "api/admin/project.api";
import { uploadFileImage } from "api/common/upload.api";
import { getApartmentLowRiseDetailById } from "api/admin/apartmentLowRise.api";

const { Option } = Select;

const ModalAddLowRiseApartment = ({
  visible,
  onOk,
  onCancel,
  confirmLoading,
  projectId,
  apartmentId, // New prop for edit mode
}) => {
  console.log("check ID: ", apartmentId);
  const [form] = Form.useForm();
  const [statusOptions, setStatusOptions] = useState([]); // Initialize as empty array
  const [salesPolicyOptions, setSalesPolicyOptions] = useState([]); // Initialize as empty array
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [layoutImage, setLayoutImage] = useState(null); // State for layout image
  const [subdivisionOptions, setSubdivisionOptions] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const response = await getListAllStatusColor();
        setStatusOptions(response || []); // Default to empty array if data is undefined
      } catch (error) {
        console.error("Failed to fetch status options:", error);
        setStatusOptions([]); // Fallback to empty array on error
      }
    };

    const fetchSalesPolicyOptions = async () => {
      try {
        const response = await getListAllSalesPolicy();
        setSalesPolicyOptions(response || []); // Default to empty array if data is undefined
      } catch (error) {
        console.error("Failed to fetch sales policy options:", error);
        setSalesPolicyOptions([]); // Fallback to empty array on error
      }
    };

    // Fetch subdivision options
    const fetchSubdivisionOptions = async () => {
      try {
        const response = await getSubdivisions(projectId); // Fetch subdivisions based on projectId
        setSubdivisionOptions(response || []);
      } catch (error) {
        console.error("Failed to fetch subdivisions:", error);
        setSubdivisionOptions([]);
      }
    };

    fetchSubdivisionOptions();
    fetchStatusOptions();
    fetchSalesPolicyOptions();

    if (visible) {
      setEditorLoaded(true); // Trì hoãn khởi tạo CKEditor
    } else {
      setEditorLoaded(false);
    }

    if (apartmentId) {
      // If apartmentId exists, we are in edit mode
      fetchApartmentDetails(apartmentId);
    }
  }, [apartmentId]);

  const fetchApartmentDetails = async (id) => {
    try {
      const apartmentData = await getApartmentLowRiseDetailById(id);
      console.log("check detail: ", apartmentData);
      if (apartmentData) {
        setDescription(apartmentData.description || "");
        form.setFieldsValue({
          ...apartmentData,
          description: apartmentData.description || "",
        });
        setLayoutImage(apartmentData.layout_image); // Set layout image if it exists
      }
    } catch (error) {
      console.error("Failed to fetch apartment details:", error);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onOk({
          ...values,
          projectId,
          layoutImage,
          description,
          apartmentId: apartmentId || null, // Pass apartmentId if editing, otherwise it's null
          layout_image: layoutImage, // Add layout image to submitted values
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleImageUpload = async ({ file }) => {
    try {
      const response = await uploadFileImage(file);
      if (response && response.url) {
        setLayoutImage(response.url); // Set the layout image with the returned URL
        message.success("Ảnh đã được tải lên thành công.");
      } else {
        message.error("Tải ảnh lên không thành công.");
      }
    } catch (error) {
      message.error("Lỗi khi tải ảnh lên.");
    }
  };

  const handleEditorInit = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyCustomUploadAdapter(loader); // Use 'new' keyword here
    };
  };

  return (
    <Modal
      title={
        apartmentId
          ? "Chỉnh sửa căn hộ"
          : "Thêm mới căn hộ biệt thự/Liền kề/Shop house"
      }
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      okText={apartmentId ? "Cập nhật" : "Lưu"}
      cancelText="Hủy"
      width={800}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Mã căn hộ"
              name="code"
              rules={[{ required: true, message: "Vui lòng nhập mã căn hộ!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phân khu"
              name="subdivision_id"
              rules={[{ required: true, message: "Vui lòng chọn phân khu!" }]}
            >
              <Select>
                {subdivisionOptions.map((subdivision) => (
                  <Option key={subdivision.id} value={subdivision.id}>
                    {subdivision.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Loại hình"
              name="type"
              rules={[{ required: true, message: "Vui lòng chọn loại hình!" }]}
            >
              <Select>
                <Option value="lien_ke">Liền kề</Option>
                <Option value="biet_thu">Biệt thự</Option>
                <Option value="shophouse">Shop house</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Diện tích đất (m²)"
              name="land_area"
              rules={[
                { required: true, message: "Vui lòng nhập diện tích đất!" },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Diện tích xây dựng (m²)"
              name="construction_area"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập diện tích xây dựng!",
                },
              ]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Hướng"
              name="direction"
              rules={[{ required: true, message: "Vui lòng nhập hướng!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Trạng thái xây dựng"
              name="construction_status"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập trạng thái xây dựng!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Giá (VNĐ)"
              name="price"
              rules={[{ required: true, message: "Vui lòng nhập giá!" }]}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Tình trạng"
              name="status"
              rules={[{ required: true, message: "Vui lòng chọn tình trạng!" }]}
            >
              <Select>
                {statusOptions.map((status) => (
                  <Option key={status.id} value={status.id}>
                    {status.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Chính sách bán hàng"
              name="sales_policy_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn chính sách bán hàng!",
                },
              ]}
            >
              <Select>
                {salesPolicyOptions.map((policy) => (
                  <Option key={policy.id} value={policy.id}>
                    {policy.sales_policy_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Cơ chế lock căn hộ (URL)" name="lock_apartment">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Chủ sở hữu" name="owner">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Giá trên mỗi mét vuông (VNĐ)"
              name="price_per_sqm"
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Giá TTS (VNĐ)" name="price_tts">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Giá TTTĐ (VNĐ)" name="price_tttd">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Giá vay 80% (VNĐ)" name="price_loan_80">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Giá vay 50% (VNĐ)" name="price_loan_50">
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Chọn ảnh layout">
              <Upload
                customRequest={handleImageUpload}
                listType="picture"
                maxCount={1}
                accept="image/*"
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
              </Upload>
              {layoutImage && (
                <img
                  src={layoutImage}
                  alt="Layout Image"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Mô tả" name="description">
          {editorLoaded ? (
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onReady={handleEditorInit}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          ) : (
            <Input.TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddLowRiseApartment;
