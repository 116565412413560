import React, { useState, useEffect } from "react";
import { Layout, Row, Button, Form, Input, Col, message } from "antd";
import classNames from "classnames/bind";
import styles from "page/Admin/Admin.module.scss";
import {
  addNewSetting,
  getSettingById,
  updateSettingById,
} from "api/admin/setting.api";
import { ADMIN_PATH, ADMIN_SETTING_PATH } from "constant/authPath";
import { useParams, useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const { Content } = Layout;

const AdminSettingNew = () => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const { id } = useParams(); // Lấy ID từ URL
  const isEditMode = !!id;
  console.log("Đang ở chế độ chỉnh sửa ID: ", id);

  const [form] = Form.useForm(); // Tạo instance của form

  useEffect(() => {
    if (isEditMode) {
      // Nếu ở chế độ chỉnh sửa, lấy thông tin của mục cần chỉnh sửa và điền vào các trường
      fetchData();
    }
  }, [id, isEditMode]);

  const fetchData = async () => {
    try {
      const response = await getSettingById(id);
      console.log("response: ", response);
      setKey(response.key);
      setValue(response.value);
      setDescription(response.description);
      // Đặt giá trị ban đầu cho form
      form.setFieldsValue({
        key: response.key,
        value: response.value,
        description: response.description,
      });
    } catch (error) {
      message.error(
        "Thực hiện lấy dữ liệu thất bại và có lỗi: " + error.message
      );
      console.error("Lỗi khi lấy dữ liệu mục chỉnh sửa:", error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (isEditMode) {
        await updateSettingById(
          id,
          values.key,
          values.value,
          values.description
        );
        message.success("Cập nhật thành công");
      } else {
        await addNewSetting(values.key, values.value, values.description);
        message.success("Thêm mới thành công");
      }
      navigate(`${ADMIN_PATH}/${ADMIN_SETTING_PATH}`);
    } catch (error) {
      message.error("Thực hiện thất bại và có lỗi: " + error.message);
      console.error("Lỗi khi tạo bài viết:", error);
    }
  };

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <h2>
              {isEditMode ? "Chỉnh sửa thiết định" : "Thêm mới thiết định"}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Form form={form} onFinish={handleSubmit}>
              {!isEditMode && (
                <Form.Item
                  label="Key thiết định"
                  name="key"
                  rules={[
                    { required: true, message: "Vui lòng nhập key thiết định" },
                  ]}
                >
                  <Input
                    placeholder="Nhập key thiết định"
                    onChange={(e) => setKey(e.target.value)}
                  />
                </Form.Item>
              )}

              {isEditMode && (
                <Form.Item label="Key thiết định" name="key">
                  <span>{key}</span>
                </Form.Item>
              )}

              <Form.Item
                label="Giá trị thiết định"
                name="value"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập giá trị thiết định",
                  },
                ]}
              >
                <Input
                  placeholder="Nhập giá trị thiết định"
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Mô tả chi tiết thiết định" name="description">
                <Input
                  placeholder="Nhập mô tả chi tiết thiết định"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Cập nhật" : "Thêm mới"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>Thông tin khác:</Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AdminSettingNew;
