import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { getListAllApartmentByBuildingId } from "api/admin/building.api";
import { ColorPicker } from 'antd';

const ModalSetApartments = ({ visible, onSetApartments, onCancel, building }) => {
    const [form] = Form.useForm();
    const [apartments, setApartments] = useState([]);

    useEffect(() => {
        const fetchApartments = async () => {
            if (building) {
                try {
                    const response = await getListAllApartmentByBuildingId(building.id);
                    const apartmentsData = response;
                    if (apartmentsData && apartmentsData.length > 0) {
                        setApartments(apartmentsData);
                        form.setFieldsValue({ apartments: apartmentsData });
                    } else {
                        const { apartment_density } = building;
                        const initialApartments = Array.from({ length: apartment_density }, (_, index) => ({
                            key: index,
                            apartment_number: `${index + 1}`,
                            name: `${index + 1}`,
                            area_gross: 0.00,
                            area_net: 0.00,
                            orientation: '',
                            type: '',
                            view: '',
                            color: '#FFFFFF'  // Mã màu mặc định
                        }));
                        setApartments(initialApartments);
                        form.setFieldsValue({ apartments: initialApartments });
                    }
                } catch (error) {
                    console.error("Failed to fetch apartments data:", error);
                    const { apartment_density } = building;
                    const initialApartments = Array.from({ length: apartment_density }, (_, index) => ({
                        key: index,
                        apartment_number: `${index + 1}`,
                        name: `${index + 1}`,
                        area_gross: 0.00,
                        area_net: 0.00,
                        orientation: '',
                        type: '',
                        view: '',
                        color: '#FFFFFF'  // Mã màu mặc định
                    }));
                    setApartments(initialApartments);
                    form.setFieldsValue({ apartments: initialApartments });
                }
            }
        };

        fetchApartments();
    }, [building, form]);

    const handleColorChange = (value, index) => {
        // Chuyển đổi giá trị thành mã màu dạng chuỗi HEX
        const hexColor = value.toHexString(); // Chuyển đổi thành mã màu HEX
        const updatedApartments = [...apartments];
        updatedApartments[index].color = hexColor;
        setApartments(updatedApartments);
        form.setFieldsValue({ apartments: updatedApartments });
    };

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                onSetApartments(values.apartments);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            visible={visible}
            title={`Thiết lập căn hộ cho tòa nhà ${building ? building.name : ''}`}
            onCancel={onCancel}
            width={1200}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Hủy bỏ
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Lưu
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" name="form_in_modal">
                <Row gutter={16} style={{ fontWeight: "bold", marginBottom: 10 }}>
                    <Col span={2}>Số căn</Col>
                    <Col span={2}>Tên căn hộ</Col>
                    <Col span={3}>Diện tích tim tường (m2)</Col>
                    <Col span={3}>Diện tích thông thủy (m2)</Col>
                    <Col span={2}>Hướng ban công</Col>
                    <Col span={2}>Loại căn</Col>
                    <Col span={2}>View</Col>
                    <Col span={3}>Mã màu</Col>
                </Row>
                {apartments.map((apartment, index) => (
                    <Row key={index} gutter={16} style={{ marginBottom: 10 }}>
                        <Col span={2}>
                            <Form.Item
                                name={['apartments', index, 'apartment_number']}
                                initialValue={apartment.apartment_number}
                                style={{ marginBottom: 0 }}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={['apartments', index, 'name']}
                                initialValue={apartment.name}
                                rules={[{ required: true, message: 'Vui lòng nhập tên căn hộ' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="Tên căn hộ" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name={['apartments', index, 'area_gross']}
                                initialValue={apartment.area_gross}
                                rules={[{ required: true, message: 'Vui lòng nhập diện tích tim tường' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="DT.TIM" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name={['apartments', index, 'area_net']}
                                initialValue={apartment.area_net}
                                rules={[{ required: true, message: 'Vui lòng nhập diện tích thông thủy' }]}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="DT.TT" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={['apartments', index, 'orientation']}
                                initialValue={apartment.orientation}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="H.BC" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={['apartments', index, 'type']}
                                initialValue={apartment.type}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="L.CAN" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                name={['apartments', index, 'view']}
                                initialValue={apartment.view}
                                style={{ marginBottom: 0 }}
                            >
                                <Input placeholder="View" />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                name={['apartments', index, 'color']}
                                initialValue={apartment.color}
                                style={{ marginBottom: 0 }}
                            >
                                <ColorPicker
                                    value={apartment.color}
                                    onChange={(value) => handleColorChange(value, index)}  // Gọi hàm handleColorChange để cập nhật mã màu
                                    showText
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
            </Form>
        </Modal>
    );
};

export default ModalSetApartments;
