import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Row, Col, Typography, Space, Upload } from 'antd';
import { addNewUser, getUserById, updateUserById } from 'api/admin/user.api';
import { ADMIN_PATH, ADMIN_USER_PATH } from 'constant/authPath';
import { useParams, useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

const AdminUserNew = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = !!id;

    useEffect(() => {
        if (isEditMode) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        try {
            const response = await getUserById(id);
            form.setFieldsValue({
                username: response.username,
                email: response.email,
                password: response.password,
                role: response.role,
                firstName: response.first_name,
                lastName: response.last_name,
                nickname: response.nickname,
                phoneNumber: response.phone_number,
                address: response.address,
                avatar: response.avatar,
            });
        } catch (error) {
            console.error('Lỗi khi lấy dữ liệu mục chỉnh sửa:', error);
        }
    };

    const handleSubmit = async (values) => {
        try {
            if (isEditMode) {
                await updateUserById(id, values);
            } else {
                await addNewUser(values);
            }
            navigate(`${ADMIN_PATH}/${ADMIN_USER_PATH}`);
        } catch (error) {
            console.error('Thao tác thất bại và có lỗi: ' + error.message);
        }
    };

    return (
        <div style={{ padding: 24 }}>
            <Row justify="center">
                <Col span={20}>
                    <Title level={2}>{isEditMode ? 'Chỉnh sửa người dùng' : 'Thêm mới người dùng'}</Title>
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name="username"
                                    label="Tên đăng nhập"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true, type: 'email', message: 'Vui lòng nhập email hợp lệ!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Mật khẩu"
                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="role"
                                    label="Vai trò"
                                    rules={[{ required: true, message: 'Vui lòng chọn vai trò!' }]}
                                >
                                    <Select placeholder="Chọn vai trò">
                                        <Option value="admin">Quản trị viên</Option>
                                        <Option value="customer">Khách hàng</Option>
                                        <Option value="collaborator">Cộng tác viên</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="firstName"
                                    label="Họ"
                                    rules={[{ required: true, message: 'Vui lòng nhập họ!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    label="Tên"
                                    rules={[{ required: true, message: 'Vui lòng nhập tên!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="nickname"
                                    label="Nickname"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="phoneNumber"
                                    label="Số điện thoại"
                                    rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    label="Địa chỉ"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="avatar"
                                    label="Avatar"
                                    valuePropName="fileList"
                                    getValueFromEvent={e => Array.isArray(e) ? e : e && e.fileList}
                                >
                                    <Upload name="avatar" listType="picture">
                                        <Button icon={<UploadOutlined />}>Tải lên Avatar</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {isEditMode ? 'Cập nhật' : 'Thêm mới'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default AdminUserNew;
