import React, { useState, useEffect } from 'react';
import { Layout, Spin } from 'antd';
import FilterForm from 'components/FilterForm';
import DataTable from 'components/DataTable';
import { searchApartment } from 'api/admin/search.api';
import classNames from "classnames/bind";
import styles from "page/Application/Tool/Tool.module.scss";
const cx = classNames.bind(styles);

const { Content } = Layout;

const Tool = () => {
    const [filters, setFilters] = useState({
        block: '',
        building: '',
        bedrooms: '',
        floorRange: '',
        balconyDirection: '',
        type: '',
        axis: '',
        priceRange: 10,
    });

    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(filters);
    }, [filters]);

    useEffect(() => {
        // Initial data fetch without filters
        fetchData({});
    }, []);

    const fetchData = async (appliedFilters) => {
        setLoading(true);
        const response = await searchApartment(appliedFilters);
        console.log(">>>> check response search: ", response);
        setData(response);
        setTotalCount(response.length);
        setLoading(false);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target || e;
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    return (
        <>
            <div className={cx("tool-title")}>Công cụ tra cứu căn hộ cao tầng</div>
            <div className={cx("tool-text-total")}>Hiển thị {totalCount} căn hộ cao tầng</div>
            <Layout className={cx("tool-content")}>
                <Content style={{ padding: '20px' }}>
                    <div className={cx("tool-bloc")}>
                        <FilterForm filters={filters} handleFilterChange={handleFilterChange} />
                    </div>
                    <div className={cx("tool-bloc")}>
                        {loading ? (
                            <Spin />
                        ) : (
                            <DataTable data={data} />
                        )}
                    </div>
                </Content>
            </Layout>
        </>
    );
}

export default Tool;
