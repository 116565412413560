/**
 * Hàm chuyển đổi giá từ VND sang tỷ và thêm chữ "tỷ" vào sau.
 *
 * @author DungPT
 * @param {number} price - Giá trị cần chuyển đổi (đơn vị VND).
 * @return {string} Giá trị đã chuyển sang đơn vị tỷ.
 */
export const convertToBillion = (price) => {
  const billion = price / 1000000000; // Chuyển từ VND sang tỷ
  return `${billion.toFixed(2)} tỷ`; // Giới hạn 2 chữ số thập phân
};
