import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Row,
  Col,
  message,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getApartmentDetailById } from "api/admin/building.api";
import MyCustomUploadAdapter from "common/MyCustomUploadAdapter";
import { uploadFileImage } from "api/common/upload.api";
import { getListAllStatusColor } from "api/admin/master.api";

const { Option } = Select;

const ApartmentDetailModal = ({ visible, onCancel, onOk, initialValues }) => {
  console.log("CHeck initialValues: ", initialValues);
  const [form] = Form.useForm();
  const [apartmentName, setApartmentName] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [floorName, setFloorName] = useState("");
  const [areaNet, setAreaNet] = useState(0);
  const [description, setDescription] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [layoutImage, setLayoutImage] = useState(null); // State for layout image
  const [statusOptions, setStatusOptions] = useState([]); // State for status options

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const response = await getListAllStatusColor();
        const options = response.map((status) => ({
          value: status.id,
          label: status.name,
        }));
        setStatusOptions(options);
      } catch (error) {
        console.error("Failed to fetch status options:", error);
        message.error("Lỗi khi tải trạng thái.");
      }
    };

    fetchStatusOptions();
  }, []);

  useEffect(() => {
    if (visible) {
      setEditorLoaded(true); // Trì hoãn khởi tạo CKEditor
    } else {
      setEditorLoaded(false);
    }

    if (initialValues?.detail_id) {
      const fetchApartmentDetails = async (detailId) => {
        try {
          const response = await getApartmentDetailById(detailId);
          console.log("chi tiết căn hộ: ", response);
          if (response) {
            setApartmentName(response.apartment.apartment_number);
            setBuildingName(response.building.name);
            setFloorName(response.apartment_detail.floor_id);
            setAreaNet(response.apartment.area_net);
            setDescription(response.apartment_detail.description || "");
            setLayoutImage(response.apartment_detail.layout_image || null); // Set initial layout image
            form.setFieldsValue({
              apartment_number: response.apartment_detail.apartment_name,
              price: response.apartment_detail.price,
              price_per_sqm: response.apartment_detail.price_per_sqm,
              price_tts: response.apartment_detail.price_tts,
              price_tttd: response.apartment_detail.price_tttd,
              price_loan_80: response.apartment_detail.price_loan_80,
              price_loan_50: response.apartment_detail.price_loan_50,
              status: response.apartment_detail.status,
              owner: response.apartment_detail.owner,
              description: response.apartment_detail.description || "",
              documents: response.documents || [], // Load tài liệu đính kèm
              sales_policy_applied_monthly:
                response.apartment_detail.sales_policy_applied_monthly || "", // Thêm trường chính sách bán hàng
              sales_policy_applied_monthly_url:
                response.apartment_detail.sales_policy_applied_monthly_url ||
                "", // Thêm trường URL chính sách bán hàng
              lock_apartment: response.apartment_detail.lock_apartment || "",
              layout_image: response.apartment_detail.layout_image || null, // Layout image
            });
          }
        } catch (error) {
          console.error("Failed to fetch apartment details:", error);
          message.error("Lỗi khi tải chi tiết căn hộ.");
        }
      };

      form.resetFields();
      fetchApartmentDetails(initialValues.detail_id);
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, visible]);

  const onFinish = (values) => {
    const valuesWithId = {
      ...values,
      detail_id: initialValues.detail_id,
      description, // Thêm mô tả chi tiết vào giá trị gửi về
      layout_image: layoutImage, // Add layout image to submitted values
    };
    onOk(valuesWithId);
  };

  const priceFormatter = (value) => {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const priceParser = (value) => {
    return value.replace(/\$\s?|(,*)/g, "");
  };

  const handlePriceChange = (price) => {
    const pricePerSqm = price && areaNet ? (price / areaNet).toFixed(2) : 0;
    form.setFieldsValue({ price_per_sqm: pricePerSqm });
  };

  const handleImageUpload = async ({ file }) => {
    try {
      const response = await uploadFileImage(file);
      if (response && response.url) {
        setLayoutImage(response.url); // Set the layout image with the returned URL
        message.success("Ảnh đã được tải lên thành công.");
      } else {
        message.error("Tải ảnh lên không thành công.");
      }
    } catch (error) {
      message.error("Lỗi khi tải ảnh lên.");
    }
  };

  const handleEditorInit = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyCustomUploadAdapter(loader); // Use 'new' keyword here
    };
  };

  return (
    <Modal
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Row gutter={16} style={{ marginBottom: "10px" }}>
        <Col span={24} style={{ fontSize: 16 }}>
          Cập nhật thông tin căn {apartmentName} - Tầng {floorName} - Tòa{" "}
          {buildingName} - Có diện tích TT: {areaNet} m2
        </Col>
      </Row>
      <Divider />
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="price"
              label="Giá căn hộ (VNĐ)"
              rules={[{ required: true, message: "Vui lòng nhập giá căn hộ!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
                onChange={handlePriceChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="price_per_sqm"
              label="Giá trên mỗi mét vuông (VNĐ)"
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="price_tts" label="Giá TTS (VNĐ)">
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="price_tttd" label="Giá TTTĐ (VNĐ)">
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="price_loan_80" label="Giá vay 80% (VNĐ)">
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="price_loan_50" label="Giá vay 50% (VNĐ)">
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                step={0.01}
                formatter={priceFormatter}
                parser={priceParser}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{ required: true, message: "Vui lòng chọn trạng thái!" }]}
            >
              <Select>
                {statusOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="owner" label="Chủ sở hữu">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="sales_policy_applied_monthly"
              label="Chính sách bán hàng"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="sales_policy_applied_monthly_url" label="Link">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Thêm trường Cơ chế lock căn hộ */}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="lock_apartment" label="Cơ chế lock căn hộ">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* Document Fields */}
        <Divider />
        <Form.List name="documents">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={16} key={field.key} align="middle">
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, "document_name"]}
                      fieldKey={[field.fieldKey, "document_name"]}
                      label="Tên tài liệu"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập tên tài liệu",
                        },
                      ]}
                    >
                      <Input placeholder="Tên tài liệu" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, "document_url"]}
                      fieldKey={[field.fieldKey, "document_url"]}
                      label="URL tài liệu"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập URL tài liệu",
                        },
                      ]}
                    >
                      <Input placeholder="URL tài liệu" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="link"
                      onClick={() => remove(field.name)}
                      danger
                    >
                      Xóa
                    </Button>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Thêm tài liệu
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider />
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Chọn ảnh layout">
              <Upload
                customRequest={handleImageUpload}
                listType="picture"
                maxCount={1}
                accept="image/*"
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
              </Upload>
              {layoutImage && (
                <img
                  src={layoutImage}
                  alt="Layout Image"
                  style={{ marginTop: "10px", maxWidth: "100%" }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Mô tả chi tiết căn hộ">
              {editorLoaded ? (
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={handleEditorInit}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              ) : (
                <Input.TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Hủy
          </Button>
          <Button type="primary" htmlType="submit">
            Cập nhật
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ApartmentDetailModal;
