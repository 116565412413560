import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Row, Col, Select } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getProvinces,
  getDistricts,
  getWards,
} from "api/common/commonMaster.api";
import MyCustomUploadAdapter from "common/MyCustomUploadAdapter";

const { Option } = Select;

const ModalAddProject = ({ visible, onCreate, onCancel, project }) => {
  const [form] = Form.useForm();

  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (visible) {
      setEditorLoaded(true); // Trì hoãn khởi tạo CKEditor
    } else {
      setEditorLoaded(false);
    }
    const fetchProvinces = async () => {
      try {
        const data = await getProvinces();
        setProvinces(data);
      } catch (error) {
        console.error("Failed to fetch provinces", error);
      }
    };
    fetchProvinces();
  }, [visible]);

  const fetchDistricts = async (provinceId) => {
    try {
      const data = await getDistricts(provinceId);
      setDistricts(data);
    } catch (error) {
      console.error("Failed to fetch districts", error);
    }
  };

  const fetchWards = async (districtId) => {
    try {
      const data = await getWards(districtId);
      setWards(data);
    } catch (error) {
      console.error("Failed to fetch wards", error);
    }
  };

  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        ...project,
      });

      // Cập nhật mô tả nếu có project và description
      if (project.description) {
        setDescription(project.description);
      }

      if (project.province) {
        fetchDistricts(project.province);
      }
      if (project.district) {
        fetchWards(project.district);
      }
    } else {
      form.resetFields();
    }
  }, [project, form]);

  const handleEditorInit = (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyCustomUploadAdapter(loader); // Use 'new' keyword here
    };
  };

  return (
    <Modal
      title={project ? "Chỉnh sửa dự án" : "Thêm mới dự án"}
      visible={visible}
      width={800}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            // Manually add the description field to the form values
            onCreate({
              ...values,
              description,
              id: project ? project.id : null,
            });
            onCancel();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={onCancel}
      okText={project ? "Lưu" : "Thêm"}
      cancelText="Hủy"
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item
              name="project_code"
              label="Mã dự án"
              rules={[{ required: true, message: "Vui lòng nhập mã dự án" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="project_name"
              label="Tên dự án"
              rules={[{ required: true, message: "Vui lòng nhập tên dự án" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="project_type"
              label="Loại hình"
              rules={[{ required: true, message: "Vui lòng chọn loại hình" }]}
            >
              <Select>
                <Option value="thap_tang">Thấp tầng</Option>
                <Option value="cao_tang">Cao tầng</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="province"
              label="Tỉnh thành"
              rules={[{ required: true, message: "Vui lòng chọn tỉnh thành" }]}
            >
              <Select
                showSearch
                placeholder="Chọn tỉnh thành"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  form.setFieldsValue({ district: null, ward: null });
                  fetchDistricts(value);
                }}
              >
                {provinces.map((province) => (
                  <Option key={province.code} value={province.code}>
                    {province.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="district"
              label="Quận huyện"
              rules={[{ required: true, message: "Vui lòng chọn quận huyện" }]}
            >
              <Select
                showSearch
                placeholder="Chọn quận huyện"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  form.setFieldsValue({ ward: null });
                  fetchWards(value);
                }}
              >
                {districts.map((district) => (
                  <Option key={district.code} value={district.code}>
                    {district.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="ward"
              label="Phường xã"
              rules={[{ required: true, message: "Vui lòng chọn phường xã" }]}
            >
              <Select
                showSearch
                placeholder="Chọn phường xã"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {wards.map((ward) => (
                  <Option key={ward.code} value={ward.code}>
                    {ward.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="location" label="Địa chỉ chi tiết">
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="area" label="Diện tích (ha)">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="investor" label="Chủ đầu tư">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="construction_density" label="Mật độ xây dựng">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="scale" label="Quy mô">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="total_buildings" label="Tổng số tòa">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="total_apartments" label="Tổng số căn hộ">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="total_villas" label="Số lượng căn biệt thự">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="start_time" label="Thời gian khởi công">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Giới thiệu dự án">
              {editorLoaded ? (
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={handleEditorInit}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                  }}
                />
              ) : (
                <Input.TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddProject;
