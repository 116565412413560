import axiosAdmin from "api/config/axiosAdmin";

// API Master: 1. status_color
// 1.1: list
const getListAllStatusColor = () => {
  const url = "/admin/status-colors";
  return axiosAdmin.get(url);
};

// 1.2: Add new
const addNewStatusColor = (name, color) => {
  const url = "admin/status-colors";
  return axiosAdmin.post(url, {
    name, color
  });
};

// 1.3: Delete
const deleteStatusColor = (id) => {
  const url = `/admin/status-colors/${id}`;
  return axiosAdmin.delete(url);
};

// 1.4: get Detail status color
const getStatusColorById = (id) => {
  const url = `/admin/status-colors/${id}`;
  return axiosAdmin.get(url);
};

// 1.5: Update
const updateStatusColorById = (id, name, color) => {
  const url = `/admin/status-colors/${id}`;
  return axiosAdmin.put(url, { name, color });
};



export {
  getListAllStatusColor,
  addNewStatusColor,
  deleteStatusColor,
  updateStatusColorById,
  getStatusColorById
};
