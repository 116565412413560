import React, { useState, useEffect } from "react";
import { Table, Button, Popconfirm, message, Layout, Row, Col, Spin, Form, Input, Select } from "antd";
import { getListAllCategory, deleteCategory, updateCategoryById, setHomeCategory } from "api/admin/post.api";
import ModalAddCategory from "components/Modal/ModalAddCategory";
import moment from "moment";

const { Content } = Layout;
const { Option } = Select;

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form form={form} style={{ margin: 0 }}>
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          {dataIndex === 'category_type' ? (
            <Select onBlur={save} onChange={save}>
              <Option value={1}>Tin tức</Option>
              <Option value={2}>Rao vặt</Option>
            </Select>
          ) : (
            <Input onPressEnter={save} onBlur={save} />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div onClick={toggleEdit} style={{ paddingRight: 24 }}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const AdminCategory = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await getListAllCategory(100, 1);
      setCategories(response);
    } catch (error) {
      console.error("Lỗi khi lấy danh sách danh mục:", error);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      message.success('Xóa danh mục thành công');
      fetchCategories();
    } catch (error) {
      message.error('Lỗi khi xóa danh mục: ' + error.message);
    }
  };

  const handleSave = async (row) => {
    try {
      console.log("Check row update: ", row);
      await updateCategoryById(row.id, row.name, row.description, row.status, row.category_type);
      message.success('Cập nhật danh mục thành công');
      fetchCategories();
    } catch (error) {
      message.error('Lỗi khi cập nhật danh mục: ' + error.message);
    }
  };

  const handleSetHomeCategory = async (id) => {
    try {
      await setHomeCategory(id);
      message.success('Danh mục đã được đặt làm trang chủ');
      fetchCategories();
    } catch (error) {
      message.error('Lỗi khi đặt danh mục làm trang chủ: ' + error.message);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      editable: false,
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      editable: true,
    },
    {
      title: 'Loại danh mục',
      dataIndex: 'category_type',
      key: 'category_type',
      editable: true,
      render: (text) => (text === 1 ? 'Tin tức' : 'Rao vặt'),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => moment(text).format('DD/MM/YYYY H:s'),
    },
    {
      title: 'Option',
      key: 'option',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleSetHomeCategory(record.id)}>
            Set Home Category
          </Button>
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa danh mục này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Xóa</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <h2>Danh sách danh mục</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              size="large"
              onClick={showModal}
              style={{ marginTop: 15 }}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>

        <Spin spinning={isLoading}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergedColumns}
            dataSource={categories}
            rowKey="id"
          />
        </Spin>

        <ModalAddCategory
          isModalVisible={isModalVisible}
          handleModalCancel={handleModalCancel}
          fetchCategories={fetchCategories}
        />
      </Content>
    </Layout>
  );
};

export default AdminCategory;
