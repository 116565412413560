import React, { createContext, useState, useEffect } from "react";

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      const adminData = localStorage.getItem("adminData");
      setAdmin(JSON.parse(adminData));
    }
  }, []);

  const login = (adminData, token) => {
    setAdmin(adminData);
    localStorage.setItem("adminToken", token);
    localStorage.setItem("adminData", JSON.stringify(adminData));
  };

  const logout = () => {
    setAdmin(null);
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminData");
  };

  return (
    <AdminContext.Provider value={{ admin, login, logout }}>
      {children}
    </AdminContext.Provider>
  );
};

export { AdminContext, AdminProvider };
