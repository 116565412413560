import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Layout,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  message,
  Select,
  Tooltip,
  Spin,
} from "antd";
import {
  addNewBuilding,
  getListAllBuilding,
  deleteBuilding,
  updateBuildingById,
  setBuildingFloors,
  setApartment,
  checkBuildingFloors,
  setHomeFlag,
} from "api/admin/building.api";
import { getProjects } from "api/admin/project.api";
import { PAGE_NUMBER, PAGE_SIZE } from "constant/common";
import moment from "moment";
import ModalAddEditBuilding from "components/Modal/ModalAddEditBuilding";
import ModalSetFloor from "components/Modal/ModalSetFloor";
import ModalSetApartment from "components/Modal/ModalSetApartment";
import ModalSetHomeFlag from "components/Modal/ModalSetHomeFlag";
import {
  EditOutlined,
  BankOutlined,
  DeleteOutlined,
  HomeOutlined,
  CheckCircleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

const AdminBuilding = () => {
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(PAGE_NUMBER);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSetFloorModalVisible, setIsSetFloorModalVisible] = useState(false);
  const [isSetApartmentsModalVisible, setIsSetApartmentsModalVisible] =
    useState(false);
  const [isSetHomeFlagModalVisible, setIsSetHomeFlagModalVisible] =
    useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [editingBuilding, setEditingBuilding] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getListAllBuilding(
        PAGE_SIZE,
        page,
        selectedProject
      );
      console.log("Kiểm tra danh sách dữ liệu: ", response);
      setListData(response);
    } catch (error) {
      message.error("Lấy dữ liệu tòa nhà thất bại: " + error.message);
    } finally {
      setLoading(false);
    }
  }, [page, PAGE_SIZE, selectedProject]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await getProjects({ project_type: "cao_tang" });
      setProjects(response);
    };

    fetchProjects();
  }, []);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getListData = () => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return listData.slice(startIndex, endIndex);
  };

  const handleDelete = (id) => {
    confirm({
      title: "Confirm deletion",
      content: "Are you sure you want to delete?",
      onOk: async () => {
        try {
          await deleteBuilding(id);
          message.success("Thực hiện thành công");
          setListData(listData.filter((item) => item.id !== id));
        } catch (error) {
          message.error("Thực hiện xóa thất bại và có lỗi: " + error.message);
        }
      },
      onCancel() {},
    });
  };

  const handleEdit = (building) => {
    setEditingBuilding(building);
    setIsModalVisible(true);
  };

  const handleAddNew = () => {
    setEditingBuilding(null);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSetFloorModalCancel = () => {
    setIsSetFloorModalVisible(false);
  };

  const handleSetApartmentsModalCancel = () => {
    setIsSetApartmentsModalVisible(false);
  };

  const handleSetHomeFlagModalCancel = () => {
    setIsSetHomeFlagModalVisible(false);
  };

  const handleCreate = async (values) => {
    console.log("check data form add/edit building: ", values);
    try {
      if (editingBuilding) {
        console.log("Check value update: ", editingBuilding);
        await updateBuildingById(editingBuilding.id, values);
        message.success(
          "Update thông tin tòa " + values.name + " nhà thành công"
        );

        // Cập nhật thông tin tòa nhà trong listData
        setListData(
          listData.map((item) =>
            item.id === editingBuilding.id ? { ...item, ...values } : item
          )
        );
      } else {
        const response = await addNewBuilding(values);
        message.success("Thêm tòa nhà thành công");
        setListData([...listData, response]);
      }
      setIsModalVisible(false);
    } catch (error) {
      message.error("Thêm tòa nhà thất bại: " + error.message);
      console.log("Thêm tòa nhà thất bại: ", error.message);
    }
  };

  const handleSetFloor = (building) => {
    setEditingBuilding(building);
    setIsSetFloorModalVisible(true);
  };

  const handleSetFloorSubmit = async (floors) => {
    console.log("Check floors: ", floors);
    try {
      await setBuildingFloors(editingBuilding.id, floors);
      message.success("Thiết định tầng thành công");
      setIsSetFloorModalVisible(false);
    } catch (error) {
      message.error("Thiết định tầng thất bại: " + error.message);
      console.log("Thiết định tầng thất bại: ", error.message);
    }
  };

  const handleSetApartments = async (building) => {
    try {
      const response = await checkBuildingFloors(building.id);
      if (!response.hasFloors) {
        message.warning("Tòa nhà chưa được thiết định tầng");
        return;
      }
      setEditingBuilding(building);
      setIsSetApartmentsModalVisible(true);
    } catch (error) {
      message.error("Lỗi kiểm tra tầng của tòa nhà: " + error.message);
      console.log("Lỗi kiểm tra tầng của tòa nhà: ", error.message);
    }
  };

  const handleSetApartmentsSubmit = async (apartment) => {
    console.log("Apartment: ", apartment);
    try {
      await setApartment(editingBuilding.id, apartment);
      message.success("Thiết lập căn hộ thành công");
      setIsSetApartmentsModalVisible(false);
    } catch (error) {
      message.error("Thiết lập căn hộ thất bại: " + error.message);
      console.log("Thiết lập căn hộ thất bại: ", error.message);
    }
  };

  const handleSetHomeFlag = (building) => {
    setEditingBuilding(building);
    setIsSetHomeFlagModalVisible(true);
  };

  const handleSetHomeFlagSubmit = async () => {
    console.log("Building: ", editingBuilding);
    try {
      await setHomeFlag(editingBuilding.id);
      message.success("Cập nhật cờ trang chủ thành công");
      setIsSetHomeFlagModalVisible(false);
      fetchData();
    } catch (error) {
      message.error("Cập nhật cờ trang chủ thất bại: " + error.message);
      console.log("Cập nhật cờ trang chủ thất bại: ", error.message);
    }
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value);
    setPage(PAGE_NUMBER); // Reset page về trang đầu tiên khi chọn dự án mới
  };

  const columns = [
    {
      title: "Mã tòa nhà",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Dự án",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Tên tòa nhà",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số tầng",
      dataIndex: "floors",
      key: "floors",
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: "Hiển thị trang chủ",
      dataIndex: "home_flag",
      key: "home_flag",
      render: (home_flag) =>
        home_flag ? <CheckCircleOutlined style={{ color: "green" }} /> : null,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Tooltip title="Xóa tòa nhà">
            <Button
              type="primary"
              danger
              onClick={() => handleDelete(record.id)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>

          <Tooltip title="Chỉnh sửa thông tin tòa nhà">
            <Button
              onClick={() => handleEdit(record)}
              icon={<EditOutlined />}
              style={{ margin: "0 10px" }}
            />
          </Tooltip>

          <Tooltip title="Thiết định tầng">
            <Button
              onClick={() => handleSetFloor(record)}
              icon={<DatabaseOutlined />}
            />
          </Tooltip>

          <Tooltip title="Thiết lập căn hộ">
            <Button
              onClick={() => handleSetApartments(record)}
              icon={<BankOutlined />}
              style={{ margin: "0 10px" }}
            />
          </Tooltip>

          <Tooltip title="Thiết lập cờ trang chủ">
            <Button
              onClick={() => handleSetHomeFlag(record)}
              icon={<HomeOutlined />}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <h2>Danh sách tòa nhà</h2>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Select
              placeholder="Chọn dự án"
              style={{ width: "50%", textAlign: "left", marginRight: 10 }}
              onChange={handleProjectChange}
              value={selectedProject}
            >
              {projects.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.project_name}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              size="large"
              onClick={handleAddNew}
              style={{ marginTop: 15 }}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={getListData()}
            pagination={false}
            rowKey="id"
          />
        </Spin>
        <Pagination
          current={page}
          pageSize={PAGE_SIZE}
          total={listData.length}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: "center" }}
        />
        <ModalAddEditBuilding
          visible={isModalVisible}
          onCreate={handleCreate}
          onCancel={handleModalCancel}
          building={editingBuilding}
          projects={projects}
        />
        <ModalSetFloor
          visible={isSetFloorModalVisible}
          onSetFloor={handleSetFloorSubmit}
          onCancel={handleSetFloorModalCancel}
          building={editingBuilding}
        />
        <ModalSetApartment
          visible={isSetApartmentsModalVisible}
          onSetApartments={handleSetApartmentsSubmit}
          onCancel={handleSetApartmentsModalCancel}
          building={editingBuilding}
        />
        <ModalSetHomeFlag
          visible={isSetHomeFlagModalVisible}
          onConfirm={handleSetHomeFlagSubmit}
          onCancel={handleSetHomeFlagModalCancel}
          building={editingBuilding}
        />
      </Content>
    </Layout>
  );
};

export default AdminBuilding;
