import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Image } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import styles from './BuildingInfo.module.scss';
import { getBuildingDetail } from 'api/app/project.api';  // Assume you have an API function to fetch building info

const cx = classNames.bind(styles);

const BuildingInfo = ({ building_id }) => {
    const [buildingInfo, setBuildingInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBuildingInfo = async () => {
            setLoading(true);
            try {
                const response = await getBuildingDetail(building_id);
                console.log("CHeck data building info: ", response);
                setBuildingInfo(response);
            } catch (error) {
                console.error('Error fetching building info:', error);
            } finally {
                setLoading(false);
            }
        };

        if (building_id) {
            fetchBuildingInfo();
        }
    }, [building_id]);

    if (loading) {
        return <Spin />;
    }

    if (!buildingInfo) {
        return <div>No building information available.</div>;
    }

    return (
        <div className={cx('project-info')}>
            <Row gutter={16}>
                <Col span={8}>
                    <ul className={cx('project-info-list')}>
                        <li><DownCircleOutlined /> Chiều cao tầng: <span className={cx('highlight')}>{buildingInfo.floors} tầng nổi và {buildingInfo.basement} tầng hầm</span></li>
                        <li><DownCircleOutlined /> Tổng số căn hộ: <span className={cx('highlight')}>{buildingInfo.total_apartments}</span></li>
                        <li><DownCircleOutlined /> Mật độ căn hộ: <span className={cx('highlight')}>{buildingInfo.apartment_density}</span></li>
                        <li><DownCircleOutlined /> Thang máy: <span className={cx('highlight')}>{buildingInfo.elevators} thang máy và {buildingInfo.cargo_elevators} thang chở hàng</span></li>
                    </ul>
                </Col>
                <Col span={8}>
                    <ul className={cx('project-info-list')}>

                        <li><DownCircleOutlined /> Thang bộ (thoát hiểm) : <span className={cx('highlight')}>Có {buildingInfo.stairs} thang bộ</span></li>
                        <li><DownCircleOutlined /> Layout: <span className={cx('highlight')}>{buildingInfo.layout}</span></li>
                        <li><DownCircleOutlined /> Tiện ích: <span className={cx('highlight')}>{buildingInfo.amenities}</span></li>
                        <li><DownCircleOutlined /> Thời điểm bàn giao: <span className={cx('highlight')}>{buildingInfo.delivery_time_1}</span></li>
                    </ul>
                </Col>
                <Col span={8} className={cx('project-image')}>
                    <Image src={buildingInfo.layout_image} alt="Project" className={cx('project-image-img')} height={200} />
                </Col>
            </Row>
        </div>
    );
};

export default BuildingInfo;
