import axiosAdmin from "api/config/axiosAdmin";
/**
 * Get list All building
 * 
 * @param {int} perPage 
 * @param {int} page 
 * @param {int} project_id 
 * @returns 
 */
const getListAllBuilding = (perPage, page, project_id) => {
  const url = "admin/buildings";
  return axiosAdmin.get(url, {
    params: {
      perPage,
      page,
      project_id,
    },
  });
};

const addNewBuilding = (payloadData) => {
  const url = "admin/buildings";
  return axiosAdmin.post(url, { payloadData });
};

const deleteBuilding = (id) => {
  const url = `/admin/buildings/${id}`;
  return axiosAdmin.delete(url);
};

const getBuildingById = (id) => {
  const url = `/admin/buildings/${id}`;
  return axiosAdmin.get(url);
};

const updateBuildingById = (id, payloadData) => {
  const url = `/admin/buildings/${id}`;
  return axiosAdmin.put(url, { payloadData });
};

const setBuildingFloors = (building_id, floors) => {
  const url = "/admin/building/apartment-floor";
  return axiosAdmin.post(url, { building_id, floors });
};

const getListAllApartmentByProjectId = (building_id) => {
  const url = "/admin/building/apartment-detail-all-for-floor";
  return axiosAdmin.get(url, {
    params: {
      building_id,
    },
  });
};

const setApartment = (building_id, apartments) => {
  const url = "/admin/building/apartment";
  return axiosAdmin.post(url, { building_id, apartments });
}

const getListAllFloorByBuildingId = (building_id) => {
  const url = "/admin/building/apartment-floor";
  return axiosAdmin.get(url, {
    params: {
      building_id: building_id
    },
  });
}

const getListAllApartmentByBuildingId = (building_id) => {
  const url = "/admin/building/apartment";
  return axiosAdmin.get(url, {
    params: {
      building_id: building_id
    },
  });
}

const checkBuildingFloors = (building_id) => {
  const url = `/admin/building/apartment-check-floors/${building_id}`;
  return axiosAdmin.get(url);
};

const getListAllBuildingByProjectId = (project_id) => {
  const url = `/admin/building/buildings/all/${project_id}`;
  return axiosAdmin.get(url);
}

const setApartmentDetail = (apartmentDetail) => {
  const url = `/admin/building/apartment-detail/${apartmentDetail.detail_id}`;
  return axiosAdmin.put(url, {
    apartment_number: apartmentDetail.apartment_number,
    owner: apartmentDetail.owner,
    price: apartmentDetail.price,
    price_loan_50: apartmentDetail.price_loan_50,
    price_loan_80: apartmentDetail.price_loan_80,
    price_per_sqm: apartmentDetail.price_per_sqm,
    price_tts: apartmentDetail.price_tts,
    price_tttd: apartmentDetail.price_tttd,
    status: apartmentDetail.status,
    description: apartmentDetail.description,
    documents: apartmentDetail.documents,
    sales_policy_applied_monthly: apartmentDetail.sales_policy_applied_monthly,
    sales_policy_applied_monthly_url: apartmentDetail.sales_policy_applied_monthly_url,
    lock_apartment: apartmentDetail.lock_apartment,
    layout_image: apartmentDetail.layout_image,
  });
}

const setHomeFlag = (id) => {
  const url = `/admin/set-home-building/${id}`;
  return axiosAdmin.put(url);
}

/**
 * get chi tiết căn hộ
 * 
 * @author: DungPT
 * @param {int} id 
 * @returns 
 */
const getApartmentDetailById = (id) => {
  const url = `/admin/building/apartment-detail/${id}`;
  return axiosAdmin.get(url);
};

export {
  getListAllBuilding,
  addNewBuilding,
  deleteBuilding,
  getBuildingById,
  updateBuildingById,
  setBuildingFloors,
  getListAllApartmentByProjectId,
  setApartment,
  getListAllFloorByBuildingId,
  getListAllApartmentByBuildingId,
  checkBuildingFloors,
  getListAllBuildingByProjectId,
  setApartmentDetail,
  setHomeFlag,
  getApartmentDetailById
};
