import axiosClient from "api/config/axiosClient";

const searchApartmentLowRise = (filters) => {
    const url = "app/search/apartments-low-rise";
    return axiosClient.get(url, { params: filters });
};


/**
 * Call API tìm kiếm mã căn hộ thuộc dự án thấp tầng
 * 
 * @author DungPT
 * @param {Object} filters (project_id, text)
 * @returns 
 */
const searchApartmentLowRiseCode = (filters) => {
    const url = "app/search/apartments-low-rise/list-code";
    return axiosClient.get(url, { params: filters });
};

/**
 * Call API tìm kiếm mã căn hộ thuộc dự án cao tầng
 * 
 * @author DungPT
 * @param {Object} filters (building_id, floor_id, text)
 * @returns 
 */
const searchApartmentCode = (filters) => {
    const url = "app/search/apartments/list-code";
    return axiosClient.get(url, { params: filters });
};

export {
    searchApartmentLowRise,
    searchApartmentLowRiseCode,
    searchApartmentCode,
};