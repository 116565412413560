import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Spin,
  Button,
  Select,
  Form,
  message,
  Tooltip,
  Space,
} from "antd";
import {
  getListAllBuildingByProjectId,
  getListAllApartmentByProjectId,
  setApartmentDetail,
} from "api/admin/building.api";
import { getProjects } from "api/admin/project.api";
import { getListAllStatusColor } from "api/admin/master.api";
import "./AdminApartment.module.scss";
import ApartmentDetailModal from "components/Modal/ApartmentDetailModal";
import LowRiseApartmentList from "./LowRiseApartmentList";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";

const { Option } = Select;

const AdminApartment = () => {
  const [projects, setProjects] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentApartment, setCurrentApartment] = useState(null);
  const [statusColors, setStatusColors] = useState({});
  const [statusNames, setStatusNames] = useState({});
  const [isThapTang, setIsThapTang] = useState(false); // State to track if the selected project is 'thap_tang'

  useEffect(() => {
    const fetchStatusColors = async () => {
      try {
        const response = await getListAllStatusColor();
        if (Array.isArray(response)) {
          const colors = {};
          const names = {};
          response.forEach((status) => {
            colors[status.id] = status.color;
            names[status.id] = status.name;
          });
          setStatusColors(colors);
          setStatusNames(names);
        } else {
          console.error("Unexpected response for status colors:", response);
        }
      } catch (error) {
        console.error("Error fetching status colors:", error);
      }
    };

    fetchStatusColors();
  }, []);

  const fetchData = useCallback(async () => {
    if (selectedBuilding) {
      setLoadingTable(true);
      try {
        const response = await getListAllApartmentByProjectId(selectedBuilding);
        if (Array.isArray(response)) {
          const floors = response.filter(
            (floor) => floor.apartments && floor.apartments.length > 0
          );
          setData(floors);
        } else {
          console.error("Unexpected response for apartments:", response);
        }
        setLoadingTable(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoadingTable(false);
      }
    } else {
      setData([]);
    }
  }, [selectedBuilding]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getProjects({ project_type: null });
        if (Array.isArray(response)) {
          setProjects(response);
        } else {
          console.error("Unexpected response for projects:", response);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchBuildings = async () => {
      if (selectedProject && !isThapTang) {
        setBuildings([]);
        setSelectedBuilding(null);
        try {
          const response = await getListAllBuildingByProjectId(selectedProject);
          if (Array.isArray(response)) {
            setBuildings(response);
          } else {
            console.error("Unexpected response for buildings:", response);
          }
        } catch (error) {
          console.error("Error fetching buildings:", error);
        }
      }
    };

    fetchBuildings();
  }, [selectedProject, isThapTang]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const showModal = (apartment) => {
    setCurrentApartment(apartment);
    setIsModalVisible(true);
  };

  const handleOk = async (values) => {
    try {
      await setApartmentDetail(values);
      message.success("Update thông tin căn hộ thành công");
      fetchData();
      setIsModalVisible(false);
    } catch (error) {
      message.error(
        "Update thông tin căn hộ không thành công: " + error.message
      );
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleProjectChange = async (value, option) => {
    console.log("check dự án được chọn: ", option);
    setSelectedProject(value);
    if (option.projected.project_type === "thap_tang") {
      setIsThapTang(true); // Set flag to true if 'thap_tang'
    } else {
      setIsThapTang(false); // Reset flag if not 'thap_tang'
      setSelectedBuilding(null);
      setData([]);
      setBuildings([]);
    }
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
  };

  const handleReloadData = () => {
    fetchData();
  };

  const columns = [
    {
      title: "",
      dataIndex: "floor",
      key: "floor",
      render: (text) => <strong>{text}</strong>,
      fixed: "left",
    },
    ...(data[0]?.apartments?.map((apartment) => ({
      title: `Trục ${apartment.apartment_number}`,
      dataIndex: `apartment_${apartment.apartment_number}`,
      key: `apartment_${apartment.apartment_number}`,
      render: (_, record) => {
        const apartmentData = record.apartments?.find(
          (a) => a.apartment_number === apartment.apartment_number
        );
        const status = apartmentData ? apartmentData.status : null;
        const color = statusColors[status] || "#ffffff";
        const statusText = statusNames[status] || "NA";
        return apartmentData ? (
          <Space wrap>
            <Tooltip
              title={
                <div style={{ color: "black" }}>
                  <p>{`Tên căn hộ: ${apartmentData.apartment_name}`}</p>
                  <p>{`Trạng thái: ${statusText}`}</p>
                  <p>{`Diện tích TT: ${apartmentData.area_net} (m2)`}</p>
                  <p>{`Giá căn hộ: ${apartmentData.price} (VNĐ)`}</p>
                  <p>{`Giá trên mỗi mét vuông: ${apartmentData.price_per_sqm} (VNĐ)`}</p>
                </div>
              }
              color={color}
            >
              <div
                style={{ width: "100%", height: "100%", position: "relative" }}
                onClick={() => showModal(apartmentData)}
              >
                <Button
                  onClick={() => showModal(apartmentData)}
                  style={{
                    background: color,
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                  icon={<EditOutlined />}
                >
                  {apartmentData.apartment_name}
                </Button>
              </div>
            </Tooltip>
          </Space>
        ) : null;
      },
      align: "center",
    })) || []),
  ];

  const tableData = data.map((floor) => {
    const row = {
      key: floor.floor_id,
      floor: `Tầng ${floor.floor_number}`,
      apartments: floor.apartments,
    };
    floor.apartments.forEach((apartment) => {
      row[`apartment_${apartment.apartment_number}`] = apartment;
    });
    return row;
  });

  return (
    <div style={{ padding: "20px" }}>
      <Form layout="inline" style={{ marginBottom: "5px" }}>
        <Form.Item label="Chọn dự án">
          <Select
            value={selectedProject}
            placeholder="Chọn dự án"
            onChange={handleProjectChange}
            style={{ width: 200 }}
          >
            {projects.map((project) => (
              <Option key={project.id} value={project.id} projected={project}>
                {project.project_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!isThapTang && (
          <Form.Item label="Chọn tòa">
            <Select
              value={selectedBuilding}
              placeholder="Chọn tòa"
              onChange={handleBuildingChange}
              style={{ width: 200 }}
              disabled={!selectedProject}
            >
              {buildings.map((building) => (
                <Option key={building.id} value={building.id}>
                  {building.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isThapTang && (
          <Button
            onClick={handleReloadData}
            icon={<ReloadOutlined />}
            disabled={!selectedBuilding}
          />
        )}
      </Form>
      {isThapTang ? (
        <LowRiseApartmentList projectId={selectedProject} />
      ) : (
        <Spin spinning={loadingTable}>
          <Table
            dataSource={tableData}
            columns={columns}
            bordered
            pagination={false}
            scroll={{ x: "max-content" }}
            sticky
          />
        </Spin>
      )}
      {isModalVisible && (
        <ApartmentDetailModal
          visible={isModalVisible}
          initialValues={currentApartment}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default AdminApartment;
