import axiosClient from "api/config/axiosClient";

/**
 * Call API đăng ký mới căn hộ cho thuê
 *
 * @author DungPT
 * @param {Object} formData
 */
const addRentPost = (formData) => {
    const url = "app/rent-posts";
    return axiosClient.post(url, {
        code: formData.rent_code,
        type: formData.type,
        price: formData.rent_price,
        title: formData.title,
        contact_name: formData.contact_name,
        contact_phone: formData.contact_phone,
        is_broker: formData.is_broker,
        owner_name: formData.owner_name,
        owner_phone: formData.owner_phone,
        highlight: formData.highlight,
        contract: formData.contract,
        payment_option: formData.payment,
        code_masking_option: formData.code_masking_option,
        service_fee: formData.service_fee,
        furniture: formData.furniture,
        basic_condition: formData.basic_condition,
        move_in_date: formData.move_in_date,
        contract_duration: formData.contract_duration,
        rent_images: formData.rent_images,
        url_youtube: formData.url_youtube,
    });
};

/**
 * Call API get list phương thức thanh toán
 *
 * @author DungPT
 * @returns Array
 */
const getListRentPayment = () => {
    const url = "app/master/rent-payments";
    return axiosClient.get(url);
};

/**
 * Call APi lấy danh sách căn hộ cho thuê theo điều kiện tìm kiếm
 * 
 * @author DungPT
 * @param {Object} filters ()
 * @returns 
 */
const getListRentPost = (filters) => {
    console.log("check điều kiện search: ", filters);
    const url = "app/rent-posts";
    return axiosClient.get(url, { params: filters });
};

export { addRentPost, getListRentPayment, getListRentPost };
